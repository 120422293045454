import { Spin, Row, Col, Collapse, Typography } from "antd";
import ClassCard from "./ClassCard";
import { responsibleMembers } from "./constants";


const ClassList = ({ data, isLoading, open }) => {
   if (isLoading) {
      return <Spin />;
   }
   return <>
      <Collapse expandIconPosition="end" defaultActiveKey={['0']} style={{ width: "100%" }}>
         <Collapse.Panel header={<Typography.Text style={{ fontSize: "20px" }}>{data[0]?.member_name}さん</Typography.Text>} style={{ width: "100%" }}>
            <Row gutter={[16, 16]} >
               {data.map((c, i) => <ClassCard key={i} c={c} open={() => open(c)} />)}
            </Row>
         </Collapse.Panel>
         <Collapse.Panel header={<Typography.Text style={{ fontSize: "20px" }}>{responsibleMembers[0]?.member_name}さん</Typography.Text>} style={{ width: "100%" }}>
            <Row gutter={[16, 16]} >
               {responsibleMembers.map((c, i) => <ClassCard key={i} c={c} open={() => open(c)} />)}
            </Row>
         </Collapse.Panel>
      </Collapse>
   </>;
};
export default ClassList;