import React from 'react';

const ActionButtonGroup = ({
  storeHandler,
  deleteClasseHandler,
  updateClasseHandler,
  AddBtnEnbl,
  DltBtnEnbl,
  UpdateBtnEnbl,
}) => {
  return (
    <>
      <button
        className="btn btn-sm btn-outline-success px-3 ml-auto"
        type="button"
        title="作成する"
        onClick={storeHandler}
        disabled={!AddBtnEnbl}
      >
        <i className="fas fa-plus fa-fw"></i>
      </button>

      <button
        className="btn btn-sm btn-outline-primary px-3 ml-auto mx-1"
        type="button"
        title="更新"
        onClick={updateClasseHandler}
        disabled={!UpdateBtnEnbl}
      >
        <i className="fas fa-save fa-fw"></i>
      </button>

      <button
        className="btn btn-sm btn-outline-danger px-3 ml-auto mx-1"
        type="button"
        title="削除"
        onClick={deleteClasseHandler}
        disabled={!DltBtnEnbl}
      >
        <i className="fas fa-trash-alt fa-fw"></i>
      </button>
      
    </>
  );
};

export default ActionButtonGroup;
