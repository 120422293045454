import React from "react";
import { Button, Form } from "react-bootstrap";
import "./Page47.css";
import "./Page48.css";
import { useEffect } from "react";
import { useState } from "react";
import privateApi from "../../services/privateApi";
import swal from "sweetalert";
import {
  Card,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Spin,
  Switch,
  Table,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const seasons = {
  1: "春",
  2: "夏",
  3: "冬",
  4: "秋",
};
const initialForm = {
  opening_season: "",
  opening_year: "",
  short_term_class_id: "",
  class_name: "",
  short_term_course_id: "",
  course_name: "",
  bus_flag: false,
  member_id: "",
};

const Page48UpdatePage = ({ studentInfo, editable, readOnlyValue }) => {
  const [classes, setClasses] = useState([]);
  const [allClasses, setAllClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [dataInput, setDataInput] = useState(initialForm);
  const [schedules, setSchedules] = useState([]);
  const [updating, setUpdating] = useState(false);
  const getClasses = async (year, season) => {
    let response;
    if (!year) {
      response = await privateApi.get(`admin/short-term-classes?per_page=1000`);
    } else {
      response = await privateApi.get(
        `admin/short-term-classes?opening_year=${year}&?opening_season=${season}&?per_page=1000`
      );
    }
    if (response.status === 200) {
      if (!year && !season) {
        setAllClasses(response.data.data);
      }
      setClasses(response.data.data);
    } else {
      console.log("サーバーの不具合");
    }
  };
  const getCourses = async short_term_class_id => {
    try {
      const response = await privateApi.get(
        `admin/get-short-term-courses/${short_term_class_id}`
      );
      if (response && response.status === 200) {
        setCourses(response?.data?.data?.short_term_courses);
      } else {
        console.log("サーバーの不具合");
      }
    } catch (error) {
      console.error("An error occurred while fetching courses:", error);
    }
  };
  const fetchSchedules = async () => {
    try {
      const res = await privateApi.get(
        `admin/short-term-class-schedules?member_id=${studentInfo?.id}`
      );
      setSchedules(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteSchedule = async id => {
    try {
      const res = await privateApi.delete(
        `admin/short-term-class-schedules/${id}`
      );
      swal("正常に削除されました。", {
        icon: "success",
      });
      setSchedules(schedules => schedules.filter(s => s.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  const changeDataInput = change => {
    if (change.opening_year) {
      getClasses(change.opening_year);
      setDataInput({
        opening_season: "",
        opening_year: change.opening_year,
        short_term_class_id: "",
        class_name: "",
        short_term_course_id: "",
        course_name: "",
        bus_flag: false,
        member_id: "",
      });
    }
    if (change.opening_season) {
      getClasses(dataInput.opening_year, change.opening_season);
      setDataInput(prev => ({
        opening_year: prev.opening_year,
        ...change,
        short_term_class_id: "",
        class_name: "",
        short_term_course_id: "",
        course_name: "",
        bus_flag: false,
        member_id: "",
      }));
    }
    if (change.short_term_class_id) {
      const selectedClass = classes.find(
        c => c.id === parseInt(change.short_term_class_id)
      );
      if (selectedClass) {
        setDataInput({
          ...dataInput,
          short_term_class_id: selectedClass.id,
          opening_year: selectedClass.opening_year,
          class_code: selectedClass.class_code,
          class_name: selectedClass.class_name,
          opening_season: selectedClass.opening_season,
          short_term_course_id: "",
        });
        getCourses(change.short_term_class_id);
      }
    }
    if (change.bus_flag === true || change.bus_flag === false) {
      setDataInput(prev => ({ ...prev, ...change }));
    }
    if (change.short_term_course_id) {
      setDataInput(prev => ({
        ...prev,
        ...change,
        course_name: courses.find(c => c.id == change.short_term_course_id)
          .course_name,
      }));
    }
    if (
      change.rank_after_course !== undefined &&
      change.rank_after_course !== null
    ) {
      console.log(change);
      setDataInput(prev => ({ ...prev, ...change }));
    }
  };
  // post short term data info and if success give a swl in Japanese that short term student added successfully
  const handleSubmit = async () => {
    let response;
    if (updating) {
      response = await privateApi.update(
        `admin/short-term-class-schedules/${updating}`,
        {
          ...dataInput,
          member_id: studentInfo.id,
          rank: dataInput.rank_after_course != undefined ? String(dataInput.rank_after_course) : String(dataInput.rank_before_course),
        }
      );
    } else {
      response = await privateApi.post(`admin/short-term-class-schedules`, {
        ...dataInput,
        member_id: studentInfo.id,
        rank: dataInput.rank_after_course ? String(dataInput.rank_after_course) : "0",
      });
    }
    if (response.status === 200) {
      swal(updating ? "正常に変更されました。" : "正常に追加されました。", {
        icon: "success",
      });
      setSchedules(p => {
        if (updating) {
          return p.map(s => {
            if (s.id == response.data.data.id) {
              return response.data.data;
            } else {
              return s;
            }
          });
        } else {
          return [response.data.data, ...p];
        }
      });
      setDataInput(initialForm);
      setUpdating(false);
    } else {
      swal("問題です", response.data.message, {
        icon: "warning",
      });
    }
  };
  useEffect(() => {
    if (studentInfo) {
      fetchSchedules();
      getClasses();
    }
  }, [studentInfo]);

  useEffect(() => {
    if (schedules.length) {
      const c = schedules[0];
      setUpdating(c.id);
      console.log(c);
      setDataInput({
        opening_season: c.class?.opening_season,
        opening_year: c.class?.opening_year,
        short_term_class_id: c.short_term_class_id,
        class_name: c.class?.class_name,
        short_term_course_id: c.short_term_course_id,
        course_name: "",
        bus_flag: Boolean(c.bus_flag),
        member_id: c.member_id,
        rank_before_course: c?.rank,
      });
    }
  }, [schedules]);
  if (!studentInfo) {
    return <Spin />;
  }

  return (
    <>
      <div
        className="p-4"
        style={{ pointerEvents: readOnlyValue ? "none" : "" }}
      >
        <div className="row align-items-center">
          <div className="col-md-3 inputs_bg py-1">
            <div className="row align-items-center">
              <div className="">
                <strong>会員番号:</strong> &nbsp; &nbsp;
                {studentInfo?.member_code}
              </div>
            </div>
          </div>
          <div className="col-md-9 ">
            <div className="row  my-2 inputs_bg align-items-center py-1">
              <div className="col-md-3">
                <strong>名前:</strong> {studentInfo?.name}
              </div>
              <div className="col-md-3">
                <strong>性別:</strong> {studentInfo?.gender}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <Row style={{ marginBottom: "10px" }}>
            <Col span={8} style={{ display: "flex" }}>
              <span style={{ fontSize: "19px" }}>級(受講前):</span>
              <div style={{ paddingLeft: "5px", pointerEvents: "none" }}>
                <Input value={dataInput.rank_before_course} style={{ width: "120px" }} />
                <Checkbox
                  checked={dataInput.rank_before_course == 0}
                  disabled
                  style={{ margin: "0 10px" }}
                />
                <span>特集</span>
              </div>
            </Col>
            <Col span={10} style={{ display: "flex" }}>
              <span style={{ flex: 1, fontSize: "19px" }}>級(受講後):</span>
              <div style={{ flex: 3, paddingLeft: "5px" }}>
                <InputNumber
                  value={dataInput.rank_after_course}
                  onChange={rank_after_course =>
                    changeDataInput({ rank_after_course })
                  }
                  style={{ width: "120px" }}
                />
                <Checkbox
                  checked={dataInput.rank_after_course == 0}
                  style={{ margin: "0 10px" }}
                  onChange={e => {
                    if (e.target.checked) {
                      changeDataInput({ rank_after_course: 0 });
                    }
                  }}
                />
                <span>特集</span>
              </div>
            </Col>
          </Row>
          <div className="row mt-1">
            <div className="col-md-2">
              <Form.Label>開校年:</Form.Label>
            </div>
            <Form.Group className="col-md-2" controlId="opening_year">
              <Form.Select
                size="sm"
                name="opening_year"
                onChange={e =>
                  changeDataInput({ opening_year: e.target.value })
                }
                className="shadow-sm"
                value={dataInput.opening_year}
              >
                <option value="">開校年</option>
                {Array.from(
                  new Set(allClasses.map(st_class => st_class.opening_year))
                ).map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}{" "}
              </Form.Select>
            </Form.Group>
            <div className="col-md-2 d-flex justify-content-end">
              <Form.Label>時期:</Form.Label>
            </div>
            <Form.Group className="col-md-2" controlId="family_member_id">
              <Form.Select
                size="sm"
                name="opening_season"
                onChange={e =>
                  changeDataInput({ opening_season: e.target.value })
                }
                className="shadow-sm"
                value={dataInput.opening_season}
              >
                <option value="">時期</option>
                {Array.from(
                  new Set(
                    allClasses
                      .filter(
                        st_class =>
                          st_class.opening_year == dataInput.opening_year
                      )
                      .map(st_class => st_class?.opening_season)
                  )
                ).map(season => (
                  <option key={season} value={season}>
                    {seasons[season]}
                  </option>
                ))}{" "}
              </Form.Select>
            </Form.Group>
          </div>

          <div className="row mt-1">
            <div className="col-md-2">
              <Form.Label>クラス名:</Form.Label>
            </div>
            <Form.Group className="col-md-4" controlId="family_member_id">
              <Form.Select
                size="sm"
                name="short_term_class_id"
                onChange={e =>
                  changeDataInput({ short_term_class_id: e.target.value })
                }
                className="shadow-sm"
                value={dataInput.short_term_class_id}
              // Set the selected value to the state value
              >
                <option value="">クラスの選択</option>
                {classes
                  .filter(
                    c =>
                      c.opening_season == dataInput.opening_season &&
                      c.opening_year == dataInput.opening_year
                  )
                  .map(st_class => (
                    <option key={st_class.id} value={st_class.id}>
                      {st_class.class_name}
                    </option>
                  ))}{" "}
              </Form.Select>
            </Form.Group>
          </div>
          <div className="row mt-1">
            <div className="col-md-2">
              <Form.Label className="main-body-inner__id-label">
                短期コース:
              </Form.Label>
            </div>
            <Form.Group className="mb-3 main-body-inner__id justify-content-between col-md-6">
              <Form.Select
                size="sm"
                className="shadow-sm w-50"
                name={"short_term_course_id"}
                value={dataInput.short_term_course_id}
                onChange={e =>
                  changeDataInput({ short_term_course_id: e.target.value })
                }
              >
                <>
                  <option value="">コースの選択</option>
                  {courses
                    .filter(
                      c =>
                        c.short_term_class_id == dataInput.short_term_class_id
                    )
                    .map(item => (
                      <option value={item.id}>{item.course_name}</option>
                    ))}
                </>
              </Form.Select>
              <Form.Check
                type="checkbox"
                name={"bus_flag"}
                checked={dataInput.bus_flag}
                onChange={() =>
                  changeDataInput({ bus_flag: !dataInput.bus_flag })
                }
                label="送迎バス"
              />
            </Form.Group>
          </div>
        </div>

        <Button
          className="mx-1"
          onClick={handleSubmit}
          variant="primary"
          disabled={!editable}
        >
          {updating ? "変更" : "追加"}
        </Button>
        {updating && (
          <Button
            variant="secondary"
            className="mx-1"
            onClick={() => setUpdating(false)}
          >
            キャンセル
          </Button>
        )}
        <Card style={{ marginTop: "5px" }}>
          <span style={{ fontSize: "20px", fontWeight: 700 }}>
            短期コース一覧
          </span>
          <Table
            pagination={{
              hideOnSinglePage: true,
            }}
            dataSource={schedules}
            columns={[
              { key: "id", dataIndex: "id", title: "ID" },
              {
                key: "class_name",
                dataIndex: "class",
                title: "クラス名",
                render: c => c?.class_name,
              },
              {
                key: "opening_year",
                dataIndex: "class",
                title: "開校年",
                render: c => c?.opening_year,
              },
              {
                key: "opening_season",
                dataIndex: "class",
                title: "開校時期",
                render: c => seasons[c?.opening_season],
              },
              {
                key: "course_name",
                dataIndex: "course",
                title: "短期コース",
                render: c => c?.course_name,
              },
              {
                key: "rank",
                dataIndex: "rank",
                title: "級",
                render: r => r == 0 ? "特集" : r
              },
              {
                key: "bus_flag",
                dataIndex: "bus_flag",
                title: "送迎バス",
                render: f => <Switch disabled checked={f} />,
              },
              {
                key: "delete",
                dataIndex: "id",
                title: "削除",
                render: (f, r) => (
                  <>
                    <button
                      className="btn btn-sm btn-primary mr-2"
                      onClick={() => {
                        setUpdating(r.id);
                        getCourses(r.class.id);
                        setDataInput({
                          ...r,
                          ...r?.class,
                          ...r?.course,
                          rank_before_course: r?.rank,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => deleteSchedule(r.id)}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </button>
                  </>
                ),
              },
            ]}
          />
        </Card>
      </div>
    </>
  );
};

export default Page48UpdatePage;
