import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import ReactSelect from "react-select";
import BackButton from "../common/Button/BackButton";
import privateApi from "../../services/privateApi";
import Nvh from "../common/Nvh";
import swal from "sweetalert";
import { useMediaQuery } from "react-responsive";
import DateForm from "../common/DateForm";
import moment from "moment";

const StudentPaymentHistory = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1366 });
  const buttonPosition = {
    position: "unset",
  };
  const [studentslist, setStudentslist] = useState([]);
  const [depositeList, setDepositeList] = useState([]);
  const [Student, setStudent] = useState({});
  const getStudentInfo = async id => {
    const response = await privateApi.get("admin/members/" + id);

    if (response.status === 200) {
      setStudent(response.data.data);
      //console.log(Student);
    } else {
      console.log("Server Error");
    }
  };

  const handleChange = selectedOption => {
    getStudentInfo(selectedOption.value);
    depositList(selectedOption.value);
  };

  const depositList = async id => {
    const response = await privateApi.get(`admin/member-deposite/${id}`);
    if (response.status === 200) {
      //console.log("deposit list", response.data.data);
      setDepositeList(response.data.data);
    } else {
      console.log("Server Error");
    }
  };
  const getStudentslist = async () => {
    const response = await privateApi.get(
      `admin/get-members-list?type=Regular`
    );
    if (response.status === 200) {
      setStudentslist(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const students_Id_Name = studentslist.map(student => ({
    value: student.id,
    label: student.member_code + "-" + student.name + "-" + student.furigana,
  }));

  useEffect(() => {
    getStudentslist();
  }, []);

  // CSV

  async function download_table_as_csv(
    table_id,
    classDataCSVExport,
    separator = ","
  ) {
    var csv = [];
    var header = [];
    header.push('"' + "会員番号" + '"');
    header.push('"' + "氏名" + '"');
    header.push('"' + "処理年月" + '"');
    header.push('"' + "休校" + '"');
    header.push('"' + "請求額" + '"');
    header.push('"' + "振替請求額" + '"');
    header.push('"' + "振替入金額" + '"');
    header.push('"' + "振替入金日" + '"');
    header.push('"' + "現金入金額" + '"');
    header.push('"' + "現金入金日" + '"');
    header.push('"' + "現金入金額(2)" + '"');
    header.push('"' + "現金入金日(2)" + '"');
    header.push('"' + "入金額合計" + '"');
    header.push('"' + "過不足額累計" + '"');
    header.push('"' + "備考" + '"');
    header.push('"' + "チェック" + '"');
    csv.push(header.join(separator));
    classDataCSVExport &&
      classDataCSVExport.forEach(data => {
        var row = [];
        row.push('"' + Nvh({ value: Student.member_code }) + '"');
        row.push('"' + Nvh({ value: Student.name }) + '"');
        row.push('"' + Nvh({ value: data.processed_date }) + '"');
        row.push('"' + Nvh({ value: data.closed_class }) + '"');
        row.push('"' + Nvh({ value: data.billing_amount }) + '"');
        row.push('"' + Nvh({ value: data.transfer_invoice_amount }) + '"');
        row.push('"' + Nvh({ value: data.transfer_amount }) + '"');
        row.push('"' + Nvh({ value: data.transfer_deposit_date }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_amount }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_date }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_amount2 }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_date2 }) + '"');
        row.push(
          '"' +
            Nvh({ value: data.transfer_amount + data.cash_deposit_amount }) +
            '"'
        );
        row.push('"' + Nvh({ value: data.cumulative_excess_deficiency }) + '"');
        row.push('"' + Nvh({ value: data.billing_check }) + '"');
        csv.push(row.join(separator));
      });
    var csv_string = csv.join("\n");
    // Download it
    var filename = table_id + "_" + moment().format("YYYYMMDD") + ".csv";

    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async table_id => {
    try {
      //console.log(Student);
      if (Student.id) {
        await download_table_as_csv(table_id, depositeList); // Pass the data to the download function
      } else {
        swal("まず会員番号／氏名／フリガナで検索して", "", "error");
      }
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        className="row p-2 col-lg-7 col-md-17 col-sm-12"
        style={{
          width: isMobile ? "100%" : isTablet || isLaptop ? "90%" : "65%",
        }}
      >
        <div
          style={{ paddingLeft: "2px" }}
          className="search-container py-1 d-flex w-50"
        >
          <ReactSelect
            className="w-75 shadow-sm custom_select"
            placeholder="会員番号／氏名で検索"
            options={students_Id_Name}
            onChange={handleChange}
          />
          <button
            type="button"
            className=" p-1 mx-1 btn btn-sm btn-primary"
            onClick={() => {
              handleDownloadCSV(
                "会員支払歴_" + Student.member_code + "_" + Student.furigana
              );
            }}
          >
            <i className="fas fa-download fa-fw"></i>
            CSV
          </button>
        </div>
      </div>
      {Student.name ? (
        <div
          className="card px-2 py-3 border g-0"
          style={{
            width: isMobile ? "100%" : isTablet || isLaptop ? "60%" : "37%",
          }}
        >
          <div className="row col-7">
            <h4>会員情報</h4>
          </div>
          <div className="row col-12 px-2">
            <div className="col-md-6 inputs_bg ">
              <strong>会員番号: </strong> {Student?.member_code}
            </div>
            <div className="col-md-6  inputs_bg ">
              <strong>名前:</strong> {Student?.name}
            </div>
          </div>
          <div className="row col-12 px-2">
            <div className="col-md-6  inputs_bg">
              <strong>性別:</strong> {Student?.gender}
            </div>
            <div className="col-md-6  inputs_bg">
              <strong>電話番号:</strong> {Student?.phone_no_1}
            </div>
          </div>

          <div
            style={{
              maxHeight: "350px",
            }}
            className="table-responsive overflow-scroll"
          >
            <Table striped bordered hover className="mt-4 text-left">
              <thead>
                <tr>
                  <th>処理年月</th>
                  <th>請求額</th>
                  <th>現金・振込入金日</th>
                  <th>現金入金額</th>
                  <th>現金入金額(2)</th>
                  <th>過不足額累計</th>
                </tr>
              </thead>
              <tbody>
                {depositeList.map((dep, i) => (
                  <tr key={i}>
                    <td>{DateForm({ value: dep.processed_date })}</td>
                    <td>{dep.billing_amount}</td>
                    <td>{DateForm({ value: dep.cash_deposit_date })}</td>
                    <td>{dep.transfer_amount + dep.cash_deposit_amount}</td>
                    <td>{dep.cash_deposit_amount2}</td>
                    <td>{dep.cumulative_excess_deficiency}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <h5
          className="text-center bg-light py-5"
          style={{
            width: isMobile ? "100%" : isTablet || isLaptop ? "70%" : "35%",
          }}
        >
          会員名を選択
        </h5>
      )}
      <div className="">
        <BackButton
          redirectTo="/admin/dashboard"
          className="btn-lg"
          position={buttonPosition}
        />
      </div>
    </div>
  );
};
export default StudentPaymentHistory;
