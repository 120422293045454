import React from "react";

export default function ButtonLoader() {
  return (
    <>
      <span className="ml-2">
        <span className="spinner-grow text-light spinner-grow-sm"></span>
        <span className="spinner-grow text-light spinner-grow-sm"></span>
        <span className="spinner-grow text-light spinner-grow-sm"></span>
      </span>
    </>
  );
}
