import React, { useState, useEffect } from 'react';
import "./TreeView.css";

const TreeNode = ({ node, onCheck, showCheckbox }) => {

  const handleCheck = (isChecked) => {
    onCheck(node.id, isChecked);
  };

  return (
    <div className={`tree ${showCheckbox ? 'form-check' : ''}`}>
      {showCheckbox && (
        <input
          type="checkbox"
          className="form-check-input"
          checked={node.checked || false}
          onChange={(e) => handleCheck(e.target.checked)}
          id={node.id}
          disabled={node.status === "0"}
        />
      )}
      <label className="form-check-label" htmlFor={node.id}>{node.name}</label>
      {node.children && (
        <ul className="list-unstyled pl-3">
          {node.children.map((childNode) => (
            <li key={childNode.id} className={childNode.status === "0" ? "disabled" : ""}>
              <TreeNode node={childNode} onCheck={onCheck} showCheckbox={showCheckbox} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const TreeView = ({ data, onCheck, showCheckbox }) => {
  return (
    <ul className="list-unstyled">
      {data.map((node) => (
        <li key={node.id} className={node.status === "0" ? "disabled" : ""}>
          <TreeNode node={node} showCheckbox={showCheckbox} onCheck={onCheck} />
        </li>
      ))}
    </ul>
  );
};

const DataTreeView = ({ treeData, showCheckbox = false, onUpdateData = [] }) => {
  const [data, setData] = useState([]);
  const handleCheck = (nodeId, isChecked) => {
    const updatedTreeData = updateNodeState(data, nodeId, isChecked);
    setData(updatedTreeData);
    updateParentNodes(updatedTreeData, nodeId, isChecked);
    onUpdateData(convertToFlatArray(updatedTreeData));
  };

  const updateNodeState = (data, nodeId, isChecked) => {
    return data.map((node) => {
      if (node.id === nodeId) {
        node = { ...node, checked: isChecked };
        if (node.children) {
          node.children = node.children.map((child) => ({
            ...child,
            checked: isChecked,
          }));
        }
        return node;
      } else if (node.children) {
        return {
          ...node,
          children: updateNodeState(node.children, nodeId, isChecked),
        };
      }
      return node;
    });
  };

  const updateParentNodes = (data, nodeId, isChecked) => {
    const parentNode = findParentNode(data, nodeId);
    if (parentNode) {
      const checkIfAntChildChecked = parentNode.children.some(
        (child) => child.checked
      );
      parentNode.checked = checkIfAntChildChecked;
      return updateParentNodes(data, parentNode.id, isChecked);
    }
    return data;
  };

  const findParentNode = (data, nodeId) => {
    let parentNode = null;
    data.forEach((node) => {
      if (node.children) {
        node.children.forEach((child) => {
          if (child.id === nodeId) {
            parentNode = node;
          }
        });
      }
    });
    return parentNode;
  };

  const convertToFlatArray = (treeData) => {
    let result = [];
  
    const traverse = (node, parent = null) => {
      if (node.checked) {
        const newNode = { ...node };
        newNode.parent = parent;
        result.push(newNode);
      }
  
      if (node.children) {
        node.children.forEach((child) => {
          traverse(child, node.id);
        });
      }
    };
  
    treeData.forEach((node) => {
      traverse(node);
    });
  
    return result;
  };
  
  
  

  useEffect(() => {
    setData(treeData);
  }, [treeData]);

  return <TreeView data={data} showCheckbox={showCheckbox} onCheck={handleCheck} />;
};

export default DataTreeView;
