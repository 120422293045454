import React, { useEffect, useState } from 'react';
import { Tabs, Typography, Col, Row } from "antd";
import styled from "styled-components";
import useModal from "../../../hooks/useModal";
import ClassList from './ClassList';
import ScheduleModal from './ScheduleModal';
import { classes, responsibleMembers } from './constants';
import useLoading from '../../../hooks/useLoading';
import Select from "react-select";
import privateApi from '../../../services/privateApi';

const Container = styled.div`
   padding:10px;
	width: 100%;
	justify-content: center;
   background: #fff;
	min-height: 100vh;
   height: 100%;
	@media screen and (max-width: 896px) {
		padding: 0 10px;
	}
`;

const Furikae = () => {
   const [data, setData] = useState(classes);
   const [currentSchedule, setCurrentSchedule] = useState();
   const [tab, setTab] = useState("all");
   const { open, close, isOpen } = useModal();
   const { startLoading, stopLoading, isLoading } = useLoading();
   const [students, setStudents] = useState([]);
   const [selectedStudent, setSelectedStudent] = useState();

   const openModal = (schedule) => {
      setCurrentSchedule(schedule);
      open();
   };
   const itemsTab = [
      {
         key: "all",
         label: "すべて",
         children: (
            <ClassList
               open={openModal}
               isLoading={isLoading}
               data={data}
            />
         ),
      },
      {
         key: "shusseki",
         label: "出席",
         children: (
            <ClassList
               open={openModal}
               isLoading={isLoading}
               data={data}
            />
         ),
      },
      {
         key: "kesseki",
         label: "欠席",
         children: (
            <ClassList
               open={openModal}
               isLoading={isLoading}
               data={data}
            />
         ),
      },
      {
         key: "furikae",
         label: "振替",
         children: (
            <ClassList
               open={openModal}
               isLoading={isLoading}
               data={data}
               selectedStudent={selectedStudent}
            />
         ),
      },

   ];
   const getStudents = async () => {
      startLoading();
      const response = await privateApi.get(
         `admin/get-members-list?type=Regular`
      );
      if (response.status === 200) {
         setStudents(response.data.data);
      } else {
         console.log("Server Error");
      }
      stopLoading();
   };
   useEffect(() => {
      getStudents();
   }, []);

   return (
      <Container>
         <Typography.Title>振替処理</Typography.Title>
         <Row style={{ marginBottom: "20px" }} gutter={[16, 16]} >
            <Col span={6} >
               <Select
                  placeholder="学生を選択"

                  options={students.map(std => ({
                     value: std.id,
                     label: std.member_code + " - " + std.name + "-" + std.furigana,
                  }))}
                  onChange={setSelectedStudent}
               />
            </Col>
         </Row>
         {selectedStudent ? <Tabs
            activeKey={tab}
            defaultActiveKey="1"
            items={itemsTab}
            onChange={(key) => setTab(key)}
         /> : <></>}

         {isOpen && <ScheduleModal isOpen={isOpen} close={close} currentSchedule={currentSchedule} data={[...classes.filter(c => (c.status === null || c.status == "furikae-required")), ...responsibleMembers.filter(c => (c.status === null || c.status == "furikae-required"))]} currentClassId={currentSchedule.id} />}
      </Container>
   );
};

export default Furikae;
