import Home from '../components/frontend/Home';
import About from '../components/frontend/About';
import Contact from '../components/frontend/Contact';
import Page403 from '../components/errors/Page403';
import Page404 from '../components/errors/Page404';
import Thankyou from '../components/frontend/Thankyou';

import Schools from '../components/frontend/auth/Schools';
import coachLogin from '../components/frontend/auth/coach/Login';
// import studentLogin from '../components/frontend/auth/student/Login';
import adminLogin from '../components/frontend/auth/admin/Login';
import superAdminLogin from '../components/frontend/auth/superadmin/Login';
import StudentLoginNext from '../components/frontend/auth/student/LoginNext';



const publicRoutesList = [
    { path: '/', exact: true, name: 'Home', component: Schools },
    { path: '/about', exact: true, name: 'About', component: About },
    { path: '/contact', exact: true, name: 'Contact', component: Contact },
    { path: '/403', exact: true, name: 'Page403', component: Page403 },
    { path: '/404', exact: true, name: 'Page404', component: Page404 },
    { path: '/thank-you', exact: true, name: 'Thankyou', component: Thankyou },

    
    { path: '/:schoolSlug', exact: true, name: 'Home', component: Home },
    { path: '/:schoolSlug/login/student', exact: true, name: 'Login', component: StudentLoginNext },
    // { path: '/:schoolSlug/login/student/:id', exact: true, name: 'LoginNext', component: StudentLoginNext },
    { path: '/:schoolSlug/login/coach', exact: true, name: 'Login', component: coachLogin },
    { path: '/:schoolSlug/login/admin', exact: true, name: 'Login', component: adminLogin},
    { path: '/login/super-admin', exact: true, name: 'Login', component: superAdminLogin},
    

    
];

export default publicRoutesList;