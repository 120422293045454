import React from "react";

export default function CoachFunction() {
  return (
    <div className="row">
      <div className="col-10">
        <p className="headline">コーチ機能</p>
      </div>
    </div>
  );
}
