export const classes = [
   {
      id: 0,
      member_name: "A",
      member_code: 1,
      class: "A",
      rank: "2級",
      period: "2024/4/1",
      time: { day: "月", time: "16:00" },
      status: "shusseki",
      furikae_day: null,
      furikae_reason: null,
   },
   {
      id: 1,
      member_name: "A",
      member_code: 1,
      class: "A",
      rank: "2級",
      period: "2024/4/8",
      time: { day: "月", time: "16:00" },
      furikae_day: "2024/4/9 15:00",
      furikae_reason: "台風のためスケジュールを変更",
      status: "furikae-shusseki"
   },
   {
      id: 2,
      member_name: "A",
      member_code: 1,
      class: "F",
      rank: "3級",
      period: "2024/4/10",
      time: { day: "水", time: "16:00" },
      furikae_day: null,
      furikae_reason: null,
      status: "shusseki"
   },
   {
      id: 3,
      member_name: "A",
      member_code: 1,
      class: "A",
      rank: "2級",
      period: "2024/4/15",
      time: { day: "月", time: "16:00" },
      furikae_day: null,
      furikae_reason: null,
      status: "kesseki"
   },
   {
      id: 4,
      member_name: "A",
      member_code: 1,
      class: "A",
      rank: "2級",
      period: "2024/4/22",
      time: { day: "月", time: "16:00" },
      furikae_day: null,
      furikae_reason: "台風のためスケジュールを変更",
      status: "furikae-required"
   },
   {
      id: 5,
      member_name: "A",
      member_code: 1,
      class: "F",
      rank: "3級",
      period: "2024/4/24",
      time: { day: "水", time: "16:00" },
      furikae_day: null,
      furikae_reason: null,
      status: null
   },
   {
      id: 6,
      member_name: "A",
      member_code: 1,
      class: "A",
      rank: "2級",
      period: "2024/4/29",
      time: { day: "月", time: "16:00" },
      furikae_day: null,
      furikae_reason: null,
      status: null
   },
];
export const responsibleMembers = [
   {
      id: 7,
      member_name: "B",
      member_code: 2,
      class: "A",
      rank: "2級",
      period: "2024/4/1",
      time: { day: "月", time: "16:00" },
      status: "shusseki",
      furikae_day: null,
      furikae_reason: null,
   },
   {
      id: 8,
      member_name: "B",
      member_code: 2,
      class: "A",
      rank: "2級",
      period: "2024/4/8",
      time: { day: "月", time: "16:00" },
      furikae_day: "2024/4/9 15:00",
      furikae_reason: "台風のためスケジュールを変更",
      status: "furikae-shusseki"
   },
   {
      id: 9,
      member_name: "B",
      member_code: 2,
      class: "F",
      rank: "3級",
      period: "2024/4/10",
      time: { day: "水", time: "16:00" },
      furikae_day: null,
      furikae_reason: null,
      status: "shusseki"
   },
   {
      id: 10,
      member_name: "B",
      member_code: 2,
      class: "A",
      rank: "2級",
      period: "2024/4/15",
      time: { day: "月", time: "16:00" },
      furikae_day: null,
      furikae_reason: null,
      status: "kesseki"
   },
   {
      id: 11,
      member_name: "B",
      member_code: 2,
      class: "A",
      rank: "2級",
      period: "2024/4/22",
      time: { day: "月", time: "16:00" },
      furikae_day: null,
      furikae_reason: "台風のためスケジュールを変更",
      status: "furikae-required"
   },
   {
      id: 12,
      member_name: "B",
      member_code: 2,
      class: "F",
      rank: "3級",
      period: "2024/4/24",
      time: { day: "水", time: "16:00" },
      furikae_day: null,
      furikae_reason: null,
      status: null
   },
   {
      id: 13,
      member_name: "B",
      member_code: 2,
      class: "A",
      rank: "2級",
      period: "2024/4/29",
      time: { day: "月", time: "16:00" },
      furikae_day: null,
      furikae_reason: null,
      status: null
   },
];