import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthService from "../../services/auth/AuthService";
import { getData, getSchool } from "../../services/auth/token";
import "../../assets/common/css/navbar.css";

const Navbar = () => {
  const history = useHistory();
  const currentUser = getData()?.username;
  const [isSidebarToggled, setIsSidebarToggled] = useState(false);

  const toggleSidebar = () => {
    document.body.classList.toggle("sb-sidenav-toggled");
    localStorage.setItem(
      "sb|sidebar-toggle",
      document.body.classList.contains("sb-sidenav-toggled")
    );
    setIsSidebarToggled(!isSidebarToggled);
  };

  const logoutController = async () => {
    const response = await AuthService.logout("member");

    if (response.status === 200) {
      const schoolSlug = getSchool() ? "/" + getSchool() : "";
      history.push(schoolSlug + "/login/student");
    }
  };

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-primary" >
      <Link className="navbar-brand ps-3" to="/student/dashboard" style={{ width: "unset", marginRight: "10px" }}>
        富田林イトマンスイミングスクール
      </Link>
      <button
        className="btn btn-toggle btn-sm order-1 order-lg-0 me-4 me-lg-0 text-white"
        onClick={toggleSidebar}
      >
        {isSidebarToggled ? "開く" : "閉じる"}
      </button>
      <ul className="navbar-nav ms-auto me-md-3 my-2 my-md-0">
        <li className="nav-item dropdown">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-user fa-fw"></i>
            {currentUser}
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown"
          >
            <li onClick={logoutController}>
              <span className="dropdown-item link" to="/">
                ログアウト
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
