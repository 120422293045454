import React from "react";
import { getData } from "../../services/auth/token";
import NewAdvancedCertificate from "./NewAdvancedCertificate";
import AbikoCertificate from "./AbikoCertificate";

export default function Certificate() {
  const school_id = getData()?.school_id;

  console.log({ school_id });

  return (
    <>{school_id === 2 ? <AbikoCertificate /> : <NewAdvancedCertificate />}</>
  );
}
