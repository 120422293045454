import React from 'react';

function Contact() {
    return  (
        <div>
            <h1>I am Contact Page</h1>
        </div>
    )
}

export default Contact;
