import React from 'react';
import { Furikae, Kesseki, Shusseki, Yotei } from "../../../assets/icons/icons";
import { Col, Typography } from "antd";


const ClassCard = ({ c, open }) => {
   const getIcon = (status) => {
      const icons = {
         shusseki: <Shusseki style={{ position: "absolute", bottom: "30", right: 10 }} size="50px" color="green" />,
         kesseki: <Kesseki style={{ position: "absolute", bottom: "30", right: 10 }} size="50px" color="red" />,
         "furikae-shusseki": <Furikae style={{ position: "absolute", bottom: "30", right: 10 }} size="50px" color="green" />,
         "furikae-required": <Furikae style={{ position: "absolute", bottom: "30", right: 10 }} size="50px" color="orange" />,
         yotei: <Yotei style={{ position: "absolute", bottom: "30", right: 10 }} size="50px" color="gray" />,
      };
      return icons[status];
   };
   const getBorder = (status) => {
      const icons = {
         shusseki: "2px solid green",
         kesseki: "2px solid red",
         "furikae-shusseki": "2px solid green",
         "furikae-required": "2px solid orange",
         yotei: "2px solid gray",
      };
      return icons[status];
   };
   return (
      <Col xs={24} md={12} lg={8}  >
         <div onClick={() => {
            if (!c.status || c.status === "furikae-required") {
               open(c);
            }
         }} style={{ height: "100%", padding: "20px", position: "relative", display: "flex", flexDirection: "column", borderRadius: "10px", boxShadow: "0px 0px 19px -10px rgba(0,0,0,0.72)", cursor: (!c.status || c.status === "furikae-required") ? "pointer" : "", fontSize: "18px", border: getBorder(c.status || "yotei") }}>
            {c.status ? getIcon(c.status) : getIcon("yotei")}
            <div style={{ display: "flex", gap: "20%", }}>
               <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography.Text style={{ fontSize: "18px" }}>{c.period}</Typography.Text>
                  <Typography.Text style={{ fontSize: "18px" }}>{c.class} クラス</Typography.Text>
               </div>
               <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography.Text style={{ fontSize: "18px" }}>{c.time.day} - {c.time.time}</Typography.Text>
                  <Typography.Text style={{ fontSize: "18px" }}>{c.rank}</Typography.Text>
               </div>
            </div>
            {c.furikae_day && <Typography.Text style={{ fontSize: "18px" }}>振替日：{c.furikae_day}</Typography.Text>}
            {c.furikae_reason && <Typography.Text style={{ fontSize: "18px", color: c.status === "furikae-required" ? "orange" : "" }}>振替理由：{c.furikae_reason}</Typography.Text>}
         </div>
      </Col>
   );
};

export default ClassCard;