import SuperAdminDashboard from "../components/superadmin/Dashboard";
import School from "../components/superadmin/School";

import SuperAdmin from "../components/superadmin/SuperAdmin";
import ModulesAndPermissions from "../components/superadmin/ModulesAndPermissions";
import IpAddress from "../components/superadmin/IpAddress";
import LoginLogs from "../components/superadmin/LoginLogs";
import WhiteList from "../components/superadmin/WhiteList";

const routes = [
  { path: "/super-admin", exact: true, name: "SuperAdmin" },
  {
    path: "/super-admin/dashboard",
    exact: true,
    name: "SuperAdminDashboard",
    component: SuperAdminDashboard,
  },
  // {
  //   path: "/super-admin/school/search",
  //   exact: true,
  //   name: "School",
  //   component: SearchSchool,
  // },
  {
    path: "/super-admin/school",
    exact: true,
    name: "School",
    component: School,
  },
  // {
  //   path: "/super-admin/payments",
  //   exact: true,
  //   name: "School",
  //   component: Payments,
  // },
  {
    path: "/super-admin/super-admin-list",
    exact: true,
    name: "School",
    component: SuperAdmin,
  },
  {
    path: "/super-admin/modules-and-permissions",
    exact: true,
    name: "School",
    component: ModulesAndPermissions,
  },
  {
    path: "/super-admin/ip",
    exact: true,
    name: "IP-Address",
    component: IpAddress,
  },
  {
    path: "/super-admin/login-logs",
    exact: true,
    name: "Login Logs",
    component: LoginLogs,
  },
  {
    path: "/super-admin/white-list",
    exact: true,
    name: "white list",
    component: WhiteList,
  },
];

export default routes;
