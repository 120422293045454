import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { Table } from "react-bootstrap";
import moment from "moment/moment";
import swal from "sweetalert";
import ja from "date-fns/locale/ja";
import privateApi from "../../../services/privateApi";
import DateForm from "../../common/DateForm";
import Nvh from "../../common/Nvh";
import { Table as AntTable, Checkbox } from "antd";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { ConfigProvider } from "antd";
import { getCurrentMonth } from "../../../services/auth/token";
import { setDate } from "date-fns";
const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8", // Adjust the value to your desired background color
    },
  },
};
const Option3 = ({ studentInfo, pdeposite, readOnlyValue, trigger }) => {
  const [startDate, setStartDate] = useState(getCurrentMonth());
  const [student, setStudent] = useState(studentInfo);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [readOnly, setReadOnly] = useState(true);

  const [startFrom, setStartFrom] = useState();
  const [endTo, setEndTo] = useState();

  const [deposite, setDeposite] = useState({
    id: "",
    member_id: "",
    account_number: "",
    processed_date: "",
    billing_amount: "",
    transfer_invoice_amount: "",
    transfer_amount: "",
    transfer_deposit_date: "",
    cash_deposit_amount: "",
    cash_deposit_date: "",
    cash_deposit_amount2: "",
    cash_deposit_date2: "",
    excess_deficiency: "",
    cumulative_excess_deficiency: "",
    billing_check: 0,
    note: ""
  });

  const [depositeList, setDepositeList] = useState([]);
  const [nameSlug, setNameSlug] = useState("");

  const getDataSource = () => {
    // if (!startFrom && !endTo && nameSlug === "abiko") {
    //   return depositeList.slice(0, 6); // Limit to maximum 6 rows
    // }
    return depositeList;
  };

  const year = startDate.getFullYear();
  const month = startDate.getMonth() + 1;

  const setBlankHandler = () => {
    setDeposite({
      id: "",
      member_id: "",
      account_number: "",
      processed_date: "",
      billing_amount: "",
      transfer_invoice_amount: "",
      transfer_amount: "",
      transfer_deposit_date: "",
      cash_deposit_amount: "",
      cash_deposit_date: getCurrentMonth().toISOString().split("T")[0],
      cash_deposit_amount2: "",
      cash_deposit_date2: getCurrentMonth().toISOString().split("T")[0],
      excess_deficiency: "",
      cumulative_excess_deficiency: "",
      billing_check: 0,
    });
  };

  const getDeposite = async (id, date) => {
    setBlankHandler();

    const formData = {
      member_id: id,
      date: date,
    };

    const response = await privateApi.post("admin/search-deposite", formData);
    if (response.status === 200) {
      setDeposite(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const depositList = async id => {
    const response = await privateApi.get(`admin/member-deposite/${id}`);
    if (response.status === 200) {
      setDepositeList(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  useEffect(() => {
    setStudent(studentInfo);
    getDeposite(studentInfo.id, moment(startDate).format("YYYY-MM"));
    setDeposite(pdeposite);
    depositList(studentInfo.id);
    setReadOnly(readOnlyValue);
  }, [studentInfo, startDate, pdeposite, readOnlyValue, trigger]);

  useEffect(() => {
    const userData = localStorage.getItem("school_slug");
    if (userData) {
      setNameSlug(userData);
    }
  }, []);

  useEffect(() => {
    filterHandler();
  }, [startFrom, endTo]);

  // submit
  const saveDeposit = async bma => {
    let formData = {};

    let newData = deposite?.processed_date;

    if (!deposite?.id && nameSlug == "abiko") {
      const frmData = {
        member_id: studentInfo.id,
        processed_date: moment(startDate).format("YYYY-MM"),
      };
      const response = await privateApi.post(
        "admin/get-member-classes",
        frmData
      );
      if (response.status === 200) {
        const originalArray = response.data.data.classes;

        if (originalArray.length > 0) {
          newData = moment(startDate).format("YYYY-MM");
        }
      }
    }

    if (!newData) {
      swal("今月はクラスがありません。", "", "warning");
    } else {
      if (bma === 0) {
        formData = {
          id: deposite?.id,
          member_id: studentInfo.id,
          processed_date: newData,
          billing_amount: 0,
        };
      } else {
        formData = {
          id: deposite?.id,
          member_id: studentInfo.id,
          processed_date: deposite?.processed_date,
          billing_amount: deposite?.billing_amount,
          billing_check: deposite?.billing_check,
          cash_deposit_amount: deposite?.cash_deposit_amount,
          cash_deposit_date: deposite?.cash_deposit_date,
          cash_deposit_amount2: deposite?.cash_deposit_amount2,
          cash_deposit_date2: deposite?.cash_deposit_date2,
          note: deposite?.note
        };
      }
    }

    const response = await privateApi.post("admin/save-deposite", formData);
    if (response.status === 200) {
      swal(response.data.message, "", "success");
      depositList(studentInfo.id);
      getDeposite(studentInfo.id, deposite?.processed_date);
    } else {
      console.log("Server Error");
    }
  };

  let start, end;

  const filterHandler = async () => {
    if (startFrom) start = moment(startFrom).format("YYYY-MM");
    if (endTo) end = moment(endTo).format("YYYY-MM");

    const response = await privateApi.get(
      `admin/member-deposite/${studentInfo.id}?start_date=${
        start ? start : ""
      }&end_date=${end ? end : ""}`
    );
    if (response.status === 200) {
      setDepositeList(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const selectHandler = (index, id, date) => {
    getDeposite(id, date);

    if (selectedRowIndex == index) {
      setSelectedRowIndex("");
    } else {
      setSelectedRowIndex(index);
    }
  };

  // CSV
  async function download_table_as_csv(
    table_id,
    classDataCSVExport,
    separator = ","
  ) {
    console.log(student);
    console.log(depositeList);
    var csv = [];
    var header = [];
    header.push('"' + "会員番号" + '"');
    header.push('"' + "氏名" + '"');
    header.push('"' + "処理年月" + '"');
    header.push('"' + "休校" + '"');
    header.push('"' + "請求額" + '"');
    header.push('"' + "振替請求額" + '"');
    header.push('"' + "振替入金額" + '"');
    header.push('"' + "振替入金日" + '"');
    header.push('"' + "現金入金額" + '"');
    header.push('"' + "現金入金日" + '"');
    header.push('"' + "現金入金額(2)" + '"');
    header.push('"' + "現金入金日(2)" + '"');
    header.push('"' + "入金額合計" + '"');
    header.push('"' + "過不足額累計" + '"');
    header.push('"' + "備考" + '"');
    header.push('"' + "チェック" + '"');
    csv.push(header.join(separator));
    classDataCSVExport &&
      classDataCSVExport.forEach(data => {
        var row = [];
        row.push('"' + Nvh({ value: student.member_code }) + '"');
        row.push('"' + Nvh({ value: student.name }) + '"');
        row.push('"' + Nvh({ value: data.processed_date }) + '"');
        row.push('"' + Nvh({ value: data.closed_class }) + '"');
        row.push('"' + Nvh({ value: data.billing_amount }) + '"');
        row.push('"' + Nvh({ value: data.transfer_invoice_amount }) + '"');
        row.push('"' + Nvh({ value: data.transfer_amount }) + '"');
        row.push('"' + Nvh({ value: data.transfer_deposit_date }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_amount }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_date }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_amount2 }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_date2 }) + '"');
        row.push(
          '"' +
            Nvh({ value: data.transfer_amount + data.cash_deposit_amount }) +
            '"'
        );
        row.push('"' + Nvh({ value: data.cumulative_excess_deficiency }) + '"');
        row.push('"' + Nvh({ value: data.billing_check }) + '"');
        csv.push(row.join(separator));
      });
    var csv_string = csv.join("\n");
    // Download it
    var filename = table_id + "_" + moment().format("YYYYMMDD") + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async table_id => {
    try {
      await download_table_as_csv(table_id, depositeList); // Pass the data to the download function
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
  };

  // useEffect(() => {
  //   const handleKeyPress = event => {
  //     switch (event.key) {
  //       case "F8":
  //         saveDeposit();
  //         break;

  //       default:
  //         break;
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyPress);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, [student]);

  const [columns, setColumns] = useState([
    {
      title: "処理年月",
      dataIndex: "processed_date",
      key: "processed_date",
      width: 100,
      render: processed_date =>
        DateForm({ value: processed_date, type: "short" }),
      sorter: (a, b) => new Date(a.processed_date) - new Date(b.processed_date),
      defaultSortOrder: nameSlug == "abiko" ? "descend" : "ascend",
    },
    {
      title: "休校",
      dataIndex: "closed_class",
      key: "closed_class",
      width: 100,
      render: closed_class => <Checkbox checked={closed_class} disabled />,
      sorter: (a, b) => a.closed_class - b.closed_class,
    },
    {
      title: "請求額",
      dataIndex: "billing_amount",
      key: "billing_amount",
      width: 100,
      sorter: (a, b) => a.billing_amount - b.billing_amount,
    },
    {
      title: "振替請求額",
      dataIndex: "transfer_invoice_amount",
      key: "transfer_invoice_amount",
      width: 100,
      sorter: (a, b) => a.transfer_invoice_amount - b.transfer_invoice_amount,
    },
    {
      title: "振替入金額",
      dataIndex: "transfer_amount",
      key: "transfer_amount",
      width: 100,
      sorter: (a, b) => a.transfer_amount - b.transfer_amount,
    },
    {
      title: "振替入金日",
      dataIndex: "transfer_deposit_date",
      key: "transfer_deposit_date",
      width: 100,
      render: transfer_deposit_date =>
        DateForm({ value: transfer_deposit_date }),
      sorter: (a, b) =>
        new Date(a.transfer_deposit_date) - new Date(b.transfer_deposit_date),
    },
    {
      title: "現金入金額",
      dataIndex: "cash_deposit_amount",
      key: "cash_deposit_amount",
      width: 100,
      sorter: (a, b) => a.cash_deposit_amount - b.cash_deposit_amount,
    },
    {
      title: "現金入金日",
      dataIndex: "cash_deposit_date",
      key: "cash_deposit_date",
      width: 100,
      render: cash_deposit_date => DateForm({ value: cash_deposit_date }),
      sorter: (a, b) =>
        new Date(a.cash_deposit_date) - new Date(b.cash_deposit_date),
    },
    {
      title: "現金入金額(2)",
      dataIndex: "cash_deposit_amount2",
      key: "cash_deposit_amount2",
      width: 100,
      sorter: (a, b) => a.cash_deposit_amount2 - b.cash_deposit_amount2,
    },
    {
      title: "現金入金日(2)",
      dataIndex: "cash_deposit_date2",
      key: "cash_deposit_date2",
      width: 100,
      render: cash_deposit_date2 => DateForm({ value: cash_deposit_date2 }),
      sorter: (a, b) =>
        new Date(a.cash_deposit_date2) - new Date(b.cash_deposit_date2),
    },
    {
      title: "入金額合計",
      dataIndex: "total_deposit_amount",
      key: "total_deposit_amount",
      width: 100,
      sorter: (a, b) => a.total_deposit_amount - b.total_deposit_amount,
    },
    {
      title: "過不足額",
      dataIndex: "excess_deficiency",
      key: "excess_deficiency",
      width: 100,
      sorter: (a, b) => a.excess_deficiency - b.excess_deficiency,
    },
    {
      title: "過不足額累計",
      dataIndex: "cumulative_excess_deficiency",
      key: "cumulative_excess_deficiency",
      width: 100,
      sorter: (a, b) =>
        a.cumulative_excess_deficiency - b.cumulative_excess_deficiency,
    },
    {
      title: "備考",
      dataIndex: "note",
      width: 100,
      key: "note",
    },
    {
      title: "チェック",
      dataIndex: "billing_check",
      key: "billing_check",
      width: 100,
      render: billing_check => (
        <Checkbox checked={billing_check === 1} disabled />
      ),
      sorter: (a, b) => a.billing_check - b.billing_check,
    },
  ]);

  const handleResize =
    index =>
    (e, { size }) => {
      setColumns(columns => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = record => {
    setStudent(record?.id);
    const key = record.id;
    setSelectedRowKey(prevSelectedRowKey =>
      prevSelectedRowKey === key ? null : key
    );
  };

  const rowClassName = record => {
    return selectedRowKey === record.id ? "table-selected-row" : "";
  };

  return (
    <>
      <div className="row m-auto ">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center py-1 inputs_bg">
              <div className="col-md-2 h-100">
                <strong>会員番号:</strong> &nbsp; &nbsp; {student?.member_code}
              </div>
              <div className="col-md-3 h-100">
                <strong>名前:</strong> {student?.name} &nbsp; [
                {student?.furigana}]
              </div>
              <div className="col-md-1 h-100">
                <strong>性別:</strong> {student?.gender}
              </div>
              <div className="col-md-6 h-100">
                <div className="row">
                  <div className="col-2 text-md-start text-lg-end pt-1">
                    <strong>備考:</strong>
                  </div>
                  <div className="col-10">
                    <input
                      type="text"
                      value={Nvh({ value: student?.note })}
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row  my-2">
              <div className="col-md-5 row">
                <div className="col-3 text-md-end pt-1">年月:</div>
                <div className="col-6">
                  <ReactDatePicker
                    className="form-control w-100"
                    selected={startDate}
                    onChange={date => {
                      if (date) {
                        setStartDate(date);
                        getDeposite(
                          studentInfo.id,
                          moment(date).format("YYYY-MM")
                        );
                      }
                    }}
                    dateFormat="yyyy/MM"
                    showMonthYearPicker
                    locale={ja}
                  />
                </div>
                <div className="col-md-3">
                  {nameSlug == "abiko" && (
                    <button
                      onClick={() => saveDeposit(0)}
                      className="btn btn-primary w-100"
                    >
                      追加
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-5"></div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={e => {
                      setDeposite({
                        ...deposite,
                        billing_check: e.target.checked ? 1 : 0,
                      });
                      // alert(e.target.checked)
                    }}
                    checked={deposite?.billing_check == 1}
                    readOnly={readOnly}
                  />
                  <label className="form-check-label">
                    <b>チェック</b>
                  </label>
                </div>
              </div>
            </div>

            <div className="row my-1 p-1 ">
              <div className="border border-secondary border-1">
                <div className="">
                  <div>
                    <div className="row my-3 justify-content-between">
                      <div className="col-md-6">
                        <div className="row  g-1 align-items-center">
                          <div className="col-md-2 pt-1">処理年月:</div>
                          <div className="col-md-4">
                            <input
                              className="form-control inputs_bg"
                              value={year + "年 " + month + "月"}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row g-1 align-items-center">
                      <div className="col-1 me-2 btn inputs_bg border d-flex justify-content-center align-items-center mw-100">
                        請求額
                      </div>

                      <div className="col border">
                        <div className="col text-center border-bottom">
                          振替
                        </div>
                        <div className="row text-center">
                          <div className="col border-end mw-100">請求額</div>
                          <div className="col border-end mw-100">入金額</div>
                          <div className="col mw-100">入金日</div>
                        </div>
                      </div>

                      <div className="col border ms-2">
                        <div className="col text-center border-bottom">
                          現金
                        </div>

                        <div className="row text-center">
                          <div className="col border-end">入金額</div>
                          <div className="col border-end">入金日</div>
                          <div className="col border-end">入金額(2)</div>
                          <div className="col ">入金日(2)</div>
                        </div>
                      </div>

                      <div className="col-2 ms-2">
                        <div className="col ms-2">
                          <div className="row text-center">
                            <div className="col-6">
                              <input
                                type="text"
                                value="入金合計"
                                className="form-control"
                                readOnly
                              />
                            </div>

                            <div className="col-6">
                              <input
                                type="text"
                                value="過不足累計"
                                className="form-control"
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row  mt-2 g-1 align-items-center">
                      <div className="col-md-1 me-2">
                        <input
                          type="text"
                          value={deposite?.billing_amount}
                          className="form-control"
                          onChange={e => {
                            setDeposite({
                              ...deposite,
                              billing_amount: e.target.value,
                            });
                          }}
                          readOnly={readOnly}
                        />
                      </div>

                      <div className="col  ">
                        <div className="row text-center">
                          <div className="col">
                            <input
                              type="text"
                              value={deposite?.transfer_invoice_amount}
                              className="form-control"
                              readOnly
                            />
                          </div>
                          <div className="col ">
                            <input
                              type="text"
                              value={deposite?.transfer_amount}
                              className="form-control"
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <ReactDatePicker
                              className="form-control"
                              selected={
                                deposite?.transfer_deposit_date
                                  ? new Date(deposite?.transfer_deposit_date)
                                  : null
                              }
                              onChange={date => {
                                setDeposite({
                                  ...deposite,
                                  transfer_deposit_date: date
                                    ? moment(date).format("YYYY-MM-DD")
                                    : null,
                                });
                              }}
                              locale={ja}
                              dateFormat="yyyy/MM/dd"
                              readOnly={readOnly}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col  ms-2">
                        <div className="row text-center">
                          <div className="col-3">
                            <input
                              type="text"
                              value={deposite?.cash_deposit_amount}
                              className="form-control"
                              onChange={e => {
                                setDeposite({
                                  ...deposite,
                                  cash_deposit_amount: e.target.value,
                                });
                              }}
                              readOnly={readOnly}
                            />
                          </div>

                          <div className="col-3">
                            <ReactDatePicker
                              className="form-control"
                              selected={
                                deposite?.cash_deposit_date
                                  ? new Date(deposite?.cash_deposit_date)
                                  : null
                              }
                              onChange={date => {
                                setDeposite({
                                  ...deposite,
                                  cash_deposit_date: date
                                    ? moment(date).format("YYYY-MM-DD")
                                    : null,
                                });
                              }}
                              locale={ja}
                              dateFormat="yyyy/MM/dd"
                              readOnly={readOnly}
                            />
                          </div>
                          <div className="col-3">
                            <input
                              type="text"
                              value={deposite?.cash_deposit_amount2}
                              className="form-control"
                              onChange={e => {
                                setDeposite({
                                  ...deposite,
                                  cash_deposit_amount2: e.target.value,
                                });
                              }}
                              readOnly={readOnly}
                            />
                          </div>

                          <div className="col-3">
                            <ReactDatePicker
                              className="form-control"
                              selected={
                                deposite?.cash_deposit_date2
                                  ? new Date(deposite?.cash_deposit_date2)
                                  : null
                              }
                              onChange={date => {
                                setDeposite({
                                  ...deposite,
                                  cash_deposit_date2: date
                                    ? moment(date).format("YYYY-MM-DD")
                                    : null,
                                });
                              }}
                              dateFormat="yyyy/MM/dd"
                              locale={ja}
                              readOnly={readOnly}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-2  ms-2">
                        <div className="col ms-2">
                          <div className="row text-center">
                            <div className="col-6">
                              <input
                                type="text"
                                value={
                                  (parseInt(deposite?.cash_deposit_amount) ||
                                    0) +
                                  (parseInt(deposite?.cash_deposit_amount2) ||
                                    0) +
                                  (parseInt(deposite?.transfer_amount) || 0)
                                }
                                className="form-control"
                                readOnly
                              />
                            </div>
                            {/* <div className="col-3">
                          <input
                            type="text"
                            value={deposite?.excess_deficiency}
                            className="form-control"
                            readOnly
                          />
                        </div> */}
                            <div className="col-6">
                              <input
                                type="text"
                                value={deposite?.cumulative_excess_deficiency}
                                className="form-control"
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2 mb-2 g-1 align-items-center">
                      <div className="col-md-1">
                        <input
                          type="text"
                          placeholder="備考"
                          className="form-control inputs_bg text-center"
                          readOnly
                        />
                      </div>

                      <div className="col-10">
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          readOnly={readOnly}
                          value={deposite?.note}
                          onChange={e => {
                            setDeposite({
                              ...deposite,
                              note: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="col-1">
                        <button
                          className="btn btn-primary w-100"
                          onClick={() => saveDeposit(1)}
                        >
                          保存
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-3">
        <div className="card-header row">
          <div className="col-md-6">
            <div className="row align-items-center">
              <div className="col-md-2">処理年月: </div>
              <div className="col-md-2">
                <ReactDatePicker
                  className="form-control"
                  selected={startFrom}
                  onChange={date => {
                    if (date) {
                      setStartFrom(date);
                    } else setStartFrom();
                  }}
                  dateFormat="yyyy/MM"
                  showMonthYearPicker
                  locale={ja}
                />
              </div>
              <div className="col-md-1">
                <div className="text-center py-2">~</div>
              </div>
              <div className="col-md-2">
                <ReactDatePicker
                  className="form-control"
                  selected={endTo}
                  onChange={date => {
                    if (date) {
                      setEndTo(date);
                    } else {
                      setEndTo();
                    }
                  }}
                  dateFormat="yyyy/MM"
                  showMonthYearPicker
                  locale={ja}
                />
              </div>
              <div className="col-md-2">
                <button
                  className="btn btn-primary w-100"
                  onClick={filterHandler}
                >
                  絞り込み
                </button>
              </div>
              <div className="col-md-2">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    handleDownloadCSV(
                      "入金処理_" + student.member_code + "_" + student.furigana
                    );
                  }}
                >
                  <i className="fas fa-download fa-fw"></i>
                  CSV
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <ConfigProvider theme={customTheme}>
            <AntTable
              bordered
              dataSource={getDataSource()}
              columns={resizableColumns}
              components={components}
              rowClassName={rowClassName}
              onRow={(record, rowIndex) => ({
                onClick: () => {
                  handleRowClick(record);
                  selectHandler(
                    rowIndex,
                    studentInfo.id,
                    record.processed_date
                  );
                  setStartDate(new Date(record.processed_date));
                },
              })}
              className="mt-4 text-left"
              pagination={false}
            />
          </ConfigProvider>
        </div>
      </div>
    </>
  );
};
export default Option3;
