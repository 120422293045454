import React from "react";
import { Table } from "react-bootstrap";

function BusUserDetailsPreview() {
  return (
    <div>
      <div className="p-3">
        <p className="headline">乗車生徒一覧</p>
      </div>

      <div className=" table-responsive">
        <Table striped bordered hover className="text-left">
          <thead>
            <tr>
              <th className="text-center">所属名</th>
              <th className="text-center">開始時間</th>
              <th className="text-center">停留所名</th>
              <th className="text-center">氏名</th>
              <th className="text-center">会員番号</th>
              <th className="text-center">性別</th>
              <th className="text-center">休校</th>
              <th className="text-center">月</th>
              <th className="text-center">火</th>
              <th className="text-center">水</th>
              <th className="text-center">木</th>
              <th className="text-center">金</th>
              <th className="text-center">土</th>
              <th className="text-center">日</th>
              <th className="text-center">往路</th>
              <th className="text-center">復路</th>
              <th className="text-center">電話番号</th>
            </tr>
          </thead>
          <tbody className="text-center">
            <tr>
              <td>
                <input type="text" value="ジュニアクラス(初・中" readOnly />
              </td>
              <td>
                <input
                  type="text"
                  className="text-center"
                  value="15:00"
                  readOnly
                  style={{ maxWidth: "60px" }}
                />
              </td>
              <td>A</td>
              <td>桜井町かつら昼前 桜井町かつら昼前</td>
              <td>山田</td>
              <td>0000538</td>
              <td>男</td>
              <td>◯</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>1</td>
              <td>1</td>
              <td>24-9999</td>
            </tr>
            <tr>
              <td>
                <input type="text" value="高等学校" readOnly />
              </td>
              <td>
                <input
                  type="text"
                  className="text-center"
                  value="10:45"
                  readOnly
                  style={{ maxWidth: "60px" }}
                />
              </td>
              <td>A</td>
              <td>桜井町かつら昼前 桜井町かつら昼前</td>
              <td>静粛</td>
              <td>0000982</td>
              <td>女</td>
              <td>◯</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>2</td>
              <td>2</td>
              <td>24-7745</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default BusUserDetailsPreview;
