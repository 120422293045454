import React, { useState } from "react";
import "../../components/admin/adminDashboard.css";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import privateApi from "../../services/privateApi";
import swal from "sweetalert";
import BackButton from "../common/Button/BackButton";

const ExcelFileUploader = () => {
  const font = {
    fontSize: "14px",
  };
  const position = {
    bottom: "1%",
    right: "125px",
  };

  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  const [bankInfo, setBankInfo] = useState({
    headerRecord: "",
    typeCode: "",
    codeDivision: "",
    consignorCode: "",
    consignorName: "",
    dateForDebit: "",
    bankNumber: "",
    bankName: "",
    transactionBranchNo: "",
    branchName: "",
    typeOfDepositAccount: "",
    depositAccountNo: "",
    Dummy: "",
  });

  const handleFileSubmit = async () => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await privateApi.post("admin/bank-csv-save", formData); // Use the post method from privateApi.js

      console.log("object", response.data);

      if (response.status === 200) {
        swal("成功", "ファイルをアップロードしました。", "success");

        downloadFile(response.data.data.errorFile)

        setShow(false);
      } else {
        // Error uploading file
        swal("エラー", "ファイルをアップロードできませんでした。", "error");
        setShow(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const downloadFile = url => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "bank_error_file.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
  };

  const handleFileChange = async event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await privateApi.post("admin/bank-csv-import", formData); // Use the post method from privateApi.js

      if (response.status === 200) {
        setBankInfo({
          headerRecord: response.data.data[0],
          typeCode: response.data.data[1],
          codeDivision: response.data.data[2],
          consignorCode: response.data.data[3],
          consignorName: response.data.data[4],
          dateForDebit: response.data.data[5],
          bankNumber: response.data.data[6],
          bankName: response.data.data[7],
          transactionBranchNo: response.data.data[8],
          branchName: response.data.data[9],
          typeOfDepositAccount: response.data.data[10],
          depositAccountNo: response.data.data[11],
          Dummy: response.data.data[12],
        });
      } else {
        swal("エラー", "ファイルをアップロードできませんでした。", "error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  return (
    <>
      <div className="card col-sm-12 col-md-10 col-xl-8">
        <div className="row m-2 d-flex align-items-center">
          <div className="col-9">
            <Form.Group as={Row} controlId="formFileSm">
              <Form.Label className="justify-content-end d-flex" column sm={3}>
                ファイル名:
              </Form.Label>
              <Col className="align-items-center d-flex" sm="9">
                <Form.Control
                  type="file"
                  size="sm"
                  onChange={handleFileChange}
                />
              </Col>
            </Form.Group>
          </div>
        </div>
        <hr className="mt-1 mb-2" />
        <div className="container">
          <div className="row mb-2">
            <div className="col-2 rtl">
              <p className="mb-2">:引落日</p>
            </div>
            <div className="col-7">
              <input
                className="form-control form-control-sm inputs_bg"
                name="bank_name"
                defaultValue={bankInfo.dateForDebit}
              ></input>
            </div>

            <div className="col-2 rtl">
              <button
                className="btn btn-sm btn-primary px-3 ml-auto"
                variant="primary"
                disabled={!file}
                // open a a confirmation modal after clicking the button
                onClick={() => setShow(true)}
              >
                :実行
              </button>
            </div>
          </div>
          <>
            <div className="row mb-2">
              <div className="col-2 rtl">
                <p style={font} className="mb-2">
                  :取引銀行
                </p>
              </div>
              <div className="col-1">
                <input
                  className="form-control form-control-sm inputs_bg"
                  name="bank_name"
                  defaultValue={bankInfo.bankNumber}
                ></input>
              </div>
              <div className="col-6">
                <input
                  className="form-control form-control-sm inputs_bg"
                  name="bank_name"
                  defaultValue={bankInfo.bankName}
                ></input>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-2 rtl">
                <p style={font} className="mb-2">
                  :取引支店
                </p>
              </div>
              <div className="col-1">
                <input
                  className="form-control form-control-sm inputs_bg"
                  name="bank_name"
                  defaultValue={bankInfo.transactionBranchNo}
                ></input>
              </div>
              <div className="col-6">
                <input
                  className="form-control form-control-sm inputs_bg"
                  name="bank_name"
                  defaultValue={bankInfo.branchName}
                  disabled
                ></input>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-2 rtl">
                <p style={font} className="mb-2">
                  :預金種目
                </p>
              </div>
              <div className="col-1">
                <input
                  className="form-control form-control-sm inputs_bg"
                  name="bank_name"
                  defaultValue=""
                ></input>
              </div>
              <div className="col-4">
                <input
                  className="form-control form-control-sm inputs_bg"
                  name="bank_name"
                  defaultValue=""
                ></input>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-2 rtl">
                <p style={font} className="mb-2">
                  :口座番号
                </p>
              </div>
              <div className="col-2">
                <input
                  className="form-control form-control-sm inputs_bg"
                  name="bank_name"
                  defaultValue={bankInfo.typeOfDepositAccount}
                ></input>
              </div>
              <div className="col-5">
                <input
                  className="form-control form-control-sm inputs_bg"
                  name="bank_name"
                  defaultValue={bankInfo.depositAccountNo}
                ></input>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-2 rtl">
                <p style={font} className="mb-2">
                  :委託者コード
                </p>
              </div>
              <div className="col-3">
                <input
                  className="form-control form-control-sm inputs_bg"
                  name="bank_name"
                  defaultValue={bankInfo.consignorCode}
                ></input>
              </div>
              <div className="col-2 ">
                <BackButton
                  position={position}
                  redirectTo="/admin/bank/dashboard"
                />
              </div>
            </div>
          </>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>実行</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          預金口座振替(処理結果明細) の取込&自動消込処理を開始します
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleFileSubmit}>
            Ok
          </Button>
          <Button variant="secondary" onClick={() => setShow(false)}>
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExcelFileUploader;
