import React, { useState, useEffect } from "react";
import { Button, Badge, Form } from "react-bootstrap";
import privateApi from "../../../services/privateApi";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import "./MasterBelonging.css";
import BackButton from "../../common/Button/BackButton";
import ActionButtonGroup from "../../common/Button/ActionButtonGroup";
import { useMediaQuery } from "react-responsive";
import { Resizable } from "react-resizable";
import { Table } from "antd";
import { Checkbox } from "antd";
import { ConfigProvider } from "antd";
import "react-resizable/css/styles.css";
import RouteLeavingGuard from "../../Prompt/RouterLeavingGuard";
import { getCurrentMonth } from "../../../services/auth/token";
import moment from "moment";

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8", // Adjust the value to your desired background color
    },
  },
};

const MasterBelonging = ({ history }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1366 });
  const [sortBy, setSortBy] = useState("display_order");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [paginationBtns, setPaginationBtns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState();
  const [AddBtnEnbl, SetAddBtnEnbl] = useState(true);
  const [UpdateBtnEnbl, SetUpdateBtnEnbl] = useState(false);
  const [DltBtnEnbl, SetDltBtnEnbl] = useState(false);
  const [classes, setClasses] = useState([]);
  const [classLength, setClassLength] = useState(0);
  const [whenState, updateWhenState] = useState(false);

  const getClasses = async (pageNumber, perPage, sortBy, sortOrder) => {
    const response = await privateApi.get(
      `admin/classes?page=${pageNumber}&per_page=${100}`
    );
    if (response.status === 200) {
      setClasses(response.data.data);
      setPaginationBtns(response.data?.meta);
    } else {
      console.log("サーバーの不具合");
    }
  };

  const position = {
    bottom: "-7%",
  };

  const getGenerelRes = async e => {
    const month = getCurrentMonth();

    const response = await privateApi.get(
      `admin/general-resources?type=bus,bank,member_code,class_codeprocessed_date=${moment(month).format("YYYY-MM")}`
    );
    if (response.status === 200) {
      console.log(response);
      setClassLength(response.data.class_code);
    } else {
      console.log("Server Error");
    }
  };

  const blankHandler = () => {
    setData({
      affiliation_code: "",
      affiliation_name: "",
      day_selection_flag: "",
      reschedule_flag: "",
      display_order: "",
      id: "",
    });
    setSelectedClass(null);
    SetAddBtnEnbl(true);
    SetUpdateBtnEnbl(false);
    SetDltBtnEnbl(false);
  };

  useEffect(() => {
    getClasses(1, 10, sortBy, sortOrder);
  }, [sortBy, sortOrder]);

  useEffect(() => {
    getGenerelRes();
  }, [classes]);

  const [dataInput, setData] = useState({
    affiliation_code: "",
    affiliation_name: "",
    day_selection_flag: "",
    reschedule_flag: "",
    display_order: "",
    id: "",
  });

  const handleInput = e => {
    e.persist();
    updateWhenState(true);
    const { name, value, type, checked } = e.target;
  
    if (type === "checkbox") {
      setData({
        ...dataInput,
        [name]: checked ? 1 : 0, // Update the clicked checkbox
      });
    } else {
      setData({ ...dataInput, [name]: value });
    }
  };
  

  const formSubmit = async e => {
    e.preventDefault();
    const response = await privateApi.post("admin/classes", dataInput);

    if (response.status === 200) {
      swal("新しいクラスが追加されました", "", "success");
      setData({
        affiliation_code: "",
        affiliation_name: "",
        day_selection_flag: "",
        display_order: "",
        reschedule_flag: "",
      });
      getClasses(1, 10, sortBy, sortOrder);
    } else {
      swal("何かエラーが発生しました", response.data.message, "error");
    }
  };

  const getClassInfo = async id => {
    const response = await privateApi.get("admin/classes/" + id);

    if (response.status === 200) {
      setData(response.data.data);
    } else {
      console.log("サーバーの不具合");
    }
  };

  const updateHandler = async () => {
    const updatedData = {
      affiliation_name: dataInput.affiliation_name,
      day_selection_flag: dataInput.day_selection_flag ? 1 : 0,
      reschedule_flag: dataInput.reschedule_flag ? 1 : 0,
      display_order: dataInput.display_order,
    };

    if (selectedClass) {
      const response = await privateApi.update(
        `admin/classes/${selectedClass}`,
        updatedData
      );

      if (response.status === 200) {
        updateWhenState(false);
        swal("クラスが更新されました", "", "success");
        setData({
          affiliation_code: "",
          affiliation_name: "",
          day_selection_flag: "",
          reschedule_flag: "",
          display_order: "",
          id: "",
        });
        getClasses(1, 10, sortBy, sortOrder);
      } else {
        swal("何か問題が発生しました", "ご連絡ください", "error");
      }
    } else {
      swal("更新するクラスを選択してください", "", "warning");
    }
  };

  const handleClick = (index, id) => {
    if (selectedRowIndex === index) {
      blankHandler();
    } else {
      const selectedClass = classes.find(cls => cls.id === id);
      if (selectedClass) {
        setSelectedClass(id);
        setData({
          ...dataInput,
          affiliation_code: selectedClass.affiliation_code || "",
          affiliation_name: selectedClass.affiliation_name || "",
          day_selection_flag: selectedClass.day_selection_flag || false,
          reschedule_flag: selectedClass.reschedule_flag || false,
          display_order: selectedClass.display_order || "",
          id: selectedClass.id || "",
        });
      }
      setSelectedRowIndex(index);
      getClassInfo(id);
      SetAddBtnEnbl(false);
      SetUpdateBtnEnbl(true);
      SetDltBtnEnbl(true);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const response = await privateApi.delete(`admin/classes/${selectedClass}`);

    if (response.status === 200) {
      swal("クラスが削除されました", "", "success");
      getClasses(1, 10, sortBy, sortOrder);
      blankHandler();
    } else {
      swal("何かエラーが発生しました", "お問い合わせください", "error");
    }
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [columns, setColumns] = useState([
    {
      title: "所属コード",
      dataIndex: "id",
      key: "id",
      width: 90,
      sorter: (a, b) => {
        const idA = String(a.affiliation_code);
        const idB = String(b.affiliation_code);
        return idA.localeCompare(idB);
      },
      render: (text, record) => <span>{record.affiliation_code}</span>,
    },
    {
      title: "所属名",
      dataIndex: "affiliation_name",
      key: "affiliation_name",
      sorter: (a, b) => a.affiliation_name.localeCompare(b.affiliation_name),
      width: 90,
      render: (text, record) => <span>{record.affiliation_name}</span>,
    },
    {
      title: "曜日必須",
      dataIndex: "day_selection_flag",
      key: "day_selection_flag",
      width: 65,
      render: (text, record) => (
        <Checkbox
          className="d-flex justify-content-center"
          checked={record.day_selection_flag === 1}
          onChange={() => {}}
        />
      ),
    },
    {
      title: "振替",
      dataIndex: "reschedule_flag",
      key: "reschedule_flag",
      width: 50,
      render: (text, record) => (
        <Checkbox
          className="d-flex justify-content-center"
          checked={record.reschedule_flag === 1}
          onChange={() => {}}
        />
      ),
    },
    {
      title: "表示順",
      dataIndex: "display_order",
      key: "display_order",
      sorter: (a, b) => {
        const idA = String(a.display_order);
        const idB = String(b.display_order);
        return idA.localeCompare(idB);
      },
      width: 90,
      render: (text, record) => <span>{record.display_order}</span>,
    },
  ]);

  const handleResize =
    index =>
    (e, { size }) => {
      setColumns(columns => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = (record, index) => {
    const key = record.id;
    setSelectedRowKey(prevSelectedRowKey =>
      prevSelectedRowKey === key ? null : key
    );

    handleClick(index, key);
  };

  const rowClassName = record => {
    return selectedRowKey === record.id ? "table-selected-row" : "";
  };

  const content = (
    <div
      className="card"
      style={{
        width: isMobile ? "100%" : isTablet || isLaptop ? "50%" : "28%",
      }}
    >
      <div className="card-header m-1 p-1">
        <div className="d-flex align-items-center">
          <p className="headline mb-0">クラスマスタ</p>
          <div className="ml-auto">
            <ActionButtonGroup
              storeHandler={formSubmit}
              deleteClasseHandler={handleDeleteClick}
              updateClasseHandler={updateHandler}
              AddBtnEnbl={AddBtnEnbl}
              DltBtnEnbl={DltBtnEnbl}
              UpdateBtnEnbl={UpdateBtnEnbl}
            />
          </div>
        </div>
      </div>
      <div className="card-body py-3">
        <div className="container g-0">
          <div className="row border d-flex justify-content-start">
            <div className="row d-flex justify-content-start align-items-center">
              <div className="col-md-3">
                <div className="font d-flex align-items-center">
                  <p className="m-0">所属コード:</p>
                </div>
              </div>
              <div className="col-md-1 p-0 mr-0">
                <input
                  type="number"
                  className="form-control form-control-sm"
                  name="affiliation_code"
                  onChange={handleInput}
                  value={
                    dataInput.affiliation_code !== ""
                      ? dataInput.affiliation_code
                      : classLength
                  }
                  readOnly
                />
              </div>

              <div className="col-md-3 pt-3 col-3 font d-flex justify-content-end align-items-center">
                <p>所属名:</p>
              </div>
              <div className="col-md-3 p-0">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="affiliation_name"
                  onChange={handleInput}
                  value={dataInput.affiliation_name}
                  placeholder="入力して下さい"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 font d-flex justify-content-center align-items-center">
                <input
                  type="checkbox"
                  onClick={handleInput}
                  checked={dataInput.day_selection_flag === 1}
                  name="day_selection_flag"
                />
                <div className="font ms-1 d-flex justify-content-center align-items-end">
                  曜日必須
                </div>
              </div>

              <div className="col-md-2 d-flex align-items-center">
                <input
                  type="checkbox"
                  onClick={handleInput}
                  checked={dataInput.reschedule_flag === 1}
                  name="reschedule_flag"
                />
                <div className="font ms-1 d-flex justify-content-center align-items-end">
                  振替
                </div>
              </div>

              <div className="col-md-3 d-flex justify-content-center pt-2">
                <p className="mb-2">表示順:</p>
              </div>
              <div className="col-md-2 ms-0 ps-0">
                <input
                  type="number"
                  className="form-control form-control-sm m-0 p-1"
                  onChange={handleInput}
                  value={dataInput.display_order}
                  name="display_order"
                  min={11}
                  style={{
                    WebkitAppearance: "none",
                    appearance: "none",
                    MozAppearance: "textfield",
                  }}
                />{" "}
              </div>
            </div>
          </div>
          <div className="row mt-1 mb-1">
            <ConfigProvider theme={customTheme}>
              <Table
                bordered
                className="table-striped-rows px-0"
                dataSource={classes}
                columns={resizableColumns}
                pagination={false}
                components={components}
                rowKey="id"
                scroll={{ x: 400, y: "60vh" }}
                rowClassName={rowClassName}
                onRow={(record, index) => ({
                  onClick: () => handleRowClick(record, index),
                })}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      <div className="row mt-2 d-flex justify-content-end">
        <div className="col-md-10">
          <div className="d-flex mx-auto neomp"></div>
        </div>
        <div className="col-md-2 ">
          <BackButton
            redirectTo="/admin/master-maintenance/dashboard"
            position={position}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ position: "relative" }}>
      {content}

      <Modal show={showModal} onHide={handleCloseModal}>
        {selectedClass ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>削除の確認</Modal.Title>
            </Modal.Header>
            <Modal.Body>消去してもよろしいですか？</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                キャンセル
              </Button>
              <Button variant="danger" onClick={handleConfirmDelete}>
                消去
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <Modal.Body>
            <h4 className="text-center text-danger py-3">任意のクラスを選択</h4>
          </Modal.Body>
        )}
      </Modal>
      <RouteLeavingGuard
        when={whenState}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={location => {
          if (whenState) {
            return true;
          }
          return false;
        }}
        yes="yes"
        no="no"
      />
    </div>
  );
};

export default MasterBelonging;
