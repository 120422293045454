import React, { memo } from "react";
import "./extraPageStyles.css";
import { Table } from "react-bootstrap";

export const EnvironMentalMaster = memo(() => {
  const data = [
    {
      label: "会員管理",
      name: "会員一覧_年月日.csv",
      example: "会員一覧_20240118.csv",
      url: "/admin/student",
      csv: "員一覧_2024_01_04",
    },
    {
      label: "個人情報_クラス",
      name: "クラス_会員番号_ふりがな_年月日.CSV",
      example: "クラス _0010182_ｵｵﾄﾘ ﾕｳｼ_20240104.csv",
      url: "/admin/student/details/201?action=view&key=tab2",
      csv: "会員管理_会員一覧_クラス_0010182_2024_01_04",
    },
    {
      label: "入金処理",
      name: "入金処理_会員番号_ふりがな_年月日.CSV",
      example: "入金処理 _0010182_ｵｵﾄﾘ ﾕｳｼ_20240104.csv",
      url: "/admin/student/details/201?action=view&key=tab3",
      csv: "会員管理_会員一覧_請求／入金 _0010182_2024_01_04",
    },
    {
      label: "請求処理",
      name: "会員支払歴_会員番号_ふりがな_年月日.CSV",
      example: "会員支払歴 _0010182_ｵｵﾄﾘ ﾕｳｼ_20240104.csv",
      url: "/admin/student/payment-history",
      csv: "会員管理_会員支払歴_10010_2024_01_04",
    },
    {
      label: "収納者未納者",
      name: "収納者_未納者リスト_年月日.csv",
      example: "収納者_未納者リスト_20240104.csv",
      url: "/admin/payer-delinquent-list",
      csv: "収納者／未納者リスト_2024_01_04",
    },
    {
      label: "備考",
      name: "備考_年月日.csv",
      example: "備考_20240104.csv",
      url: "/admin/check-student-history",
      csv: "備考_会員一覧 (備考)_2024_01_04",
    },
  ];

  return (
    <>
      <div className="resp-card">
        <div className="card">
          <div className="card-header">
            それぞれのファイルの出力先は、「ダウンロード」
            フォルダに固定となります <br></br>
            画面により、出力される際のファイル名は、以下のように設定されます。
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <Table
                striped
                bordered
                hover
                className="table text-left mb-0"
                responsive="sm"
              >
                <thead>
                  <tr>
                    <th>画面</th>
                    <th>ファイル名(日本語)</th>
                    <th>ファイル名 (ex)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td style={{ minWidth: "120px" }}>{row.label}</td>
                      <td style={{ minWidth: "200px" }}>{row.name}</td>
                      <td style={{ minWidth: "200px" }}>{row.example}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
