export const checkboxes = [
  { value: 'member_code', label: '会員番号' },
  { value: 'name', label: '氏名' },
  { value: 'furigana', label: 'フリガナ' },
  { value: 'gender', label: '性別' },
  { value: 'birthday', label: '生年月日' },
  // class name
  { value: 'class_name_1', label: '1_所属名' },
  { value: 'class_times_1', label: '1_回数' },
  { value: 'class_grade_1', label: '1_級' },
  { value: 'class_schedule_1', label: '2_曜日時間' },
  { value: 'class_name_2', label: '2_所属名' },
  { value: 'class_times_2', label: '2_回数' },
  { value: 'class_grade_2', label: '2_級' },
  { value: 'class_schedule_2', label: '2_曜日時間' },
  { value: 'class_name_3', label: '3_所属名' },
  { value: 'class_times_3', label: '3_回数' },
  { value: 'class_grade_3', label: '3_級' },
  { value: 'class_schedule_3', label: '3_曜日時間' },
  // No translation provided for 'district_code', translating to '地区コード'
  //{ value: 'district_code', label: '地区コード' },
  { value: 'zip_code', label: '郵便番号' },
  { value: 'address_1', label: '住所1' },
  { value: 'address_2', label: '住所2' },
  { value: 'phone_no_1', label: '電話番号1' },
  { value: 'phone_note_1', label: '電話備考1' },
  { value: 'phone_no_2', label: '電話番号2' },
  { value: 'phone_note_2', label: '電話備考2' },
  // No translation provided for 'fax_number', translating to 'FAX番号'
  //{ value: 'fax_number', label: 'FAX番号' },
  { value: 'mail_address_1', label: 'メールアドレス1' },
  //{ value: 'mail_category_1', label: 'メール区分1' },
  { value: 'mail_note_1', label: 'メール備考1' },
  { value: 'mail_address_2', label: 'メールアドレス2' },
 // { value: 'mail_category_2', label: 'メール区分2' },
  { value: 'mail_note_2', label: 'メール備考2' },
  { value: 'family_member_id', label: '家族会員番号' },
  //family name
  { value: 'em_last_name', label: '緊急連絡氏名' },
  { value: 'em_last_name_furigana', label: '緊急連絡：フリガナ' },
  { value: 'em_phone_no', label: '電話番号（緊急連絡）' },
  { value: 'bus.bus_course_number', label: 'バスコース番号' },
  { value: 'outbound_bus', label: 'バス往路' },
  { value: 'return_bus', label: 'バス復路' },
  { value: 'charge_type_code', label: '料金種別コード' },
  { value: 'payment_type_code', label: '納付区分コード' },
  { value: 'bank_code', label: '銀行コード' },
  { value: 'branch_code', label: '支店コード' },
  { value: 'deposit_item', label: '預金種目' },
  { value: 'account_no', label: '口座番号' },
  { value: 'account_holder', label: '口座名義人' },
  { value: 'joining_date', label: '入会日' },
  { value: 'leave_date', label: '退校日' },
  { value: 'note', label: '備考' },
  { value: 'created_at', label: '作成日時' },
  { value: 'updated_at', label: '更新日時' }
];

