import React, { useEffect, useState } from "react";
import "./extraPageStyles.css";

const Page53 = ({ selectedDays, busDetails }) => {
  // Function to merge arrays based on the common first letter of bus_course_number
  const mergeBusDetails = (details) => {
    const mergedDetails = {};

    details.forEach((item) => {
      const firstLetter = item.bus_course_number.charAt(0);

      if (!mergedDetails[firstLetter]) {
        mergedDetails[firstLetter] = {
          bus_course_number: firstLetter,
          stop_name: [],
          members: [],
        };
      }

      mergedDetails[firstLetter].stop_name.push(item.stop_name);
      mergedDetails[firstLetter].members.push(item.members);
    });

    return Object.values(mergedDetails);
  };

  const mergedBusDetails = mergeBusDetails(busDetails);

  // Function to calculate the sum of outbound values
  const handleSumOutbound = (members) => {
    if (!members) return 0;

    let sumOutbound = 0;
    for (const memberList of members) {
      for (const mbr of memberList) {
        if (mbr.outbound_bus !== "null") {
          sumOutbound += parseInt(mbr.outbound_bus, 10);
        }
      }
    }
    return sumOutbound;
  };

  // Function to calculate the sum of return values
  const handleSumReturn = (members) => {
    if (!members) return 0;

    let sumReturn = 0;
    for (const memberList of members) {
      for (const mbr of memberList) {
        if (mbr.return_bus !== "null") {
          sumReturn += parseInt(mbr.return_bus, 10);
        }
      }
    }
    return sumReturn;
  };
  

  useEffect(() => {
    console.log('bus details', mergeBusDetails);
  }, [mergeBusDetails]);

  return (
    <>
      <div className="container">
        <div className="col-md-auto">乗車生徒一覧</div>
        <hr />
        {mergedBusDetails.map((bus, busIndex) => (
          <div key={busIndex}>
            <div className="border text-center">バスコース: {bus.bus_course_number}</div>
            {bus.stop_name.map((stopName, stopIndex) => (
              <div
                key={stopIndex}
                className="ms-4 p-2"
              >
                <div className="col-md-auto mt-2 mb-2">停留所名: {stopName}</div>

                <div className="ms-4">
                  <div className="col-8">
                    <div className="col-md-auto border-bottom">
                      <div className="row mt-2 g-1 align-items-center">
                        <div className="col-md-3">氏名</div>
                        <div className="col-md-2">性別</div>
                        <div className="col-md-2">往路</div>
                        <div className="col-md-2">復路</div>
                        <div className="col-md-3">電話番号</div>
                      </div>
                    </div>
                    {bus.members[stopIndex].map((mbr, mbrIndex) => (
                      <div
                        key={mbrIndex}
                        className="col-md-auto"
                      >
                        <div className="row g-1 align-items-center">
                          <div className="col-md-3">{mbr.name}</div>
                          <div className="col-md-2">{mbr.gender}</div>
                          <div className="col-md-2">
                            {mbr.outbound_bus === "null" ? "-" : mbr.outbound_bus}
                          </div>
                          <div className="col-md-2">
                            {mbr.return_bus === "null" ? "-" : mbr.return_bus}
                          </div>
                          <div className="col-md-3">{mbr.phone_no_1}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <div className="ms-4 p-2 col-md-8 d-flex justify-content-center">
              <div className="mt-3 border-top w-50 ms-4">
                <div className="custom-total">
                  <div className="">{bus.bus_course_number} コース合計</div>
                  <div className="">{handleSumOutbound(bus.members)}</div>
                  <div className="">{handleSumReturn(bus.members)}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Page53;
