import React, { useEffect, useState, useRef } from "react";
import privateApi from "../../../services/privateApi";
import swal from "sweetalert";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import moment from "moment";
import CSVDownload from "./CSVDownload";
import ReactSelect from "react-select";
import ActualValue from "../../common/ActualValue";

function RidingListDetails({ setShowDetails, id }) {
  const [show, setShow] = useState(false);
  const [ridingData, setRidingData] = useState();
  const [ridingReports, setRidingReports] = useState();

  const [idView, setIdView] = useState(true);

  const [telView, setTelView] = useState(true);

  const [studentList, setStudentList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [loading, setLoading] = useState(true);

  const handleShow = () => {
    setShow(true);
  };

  const handleAddRow = stopageIndex => {
    const newData = [...ridingReports];
    const newRider = {
      no: "",
      name: "",
      name_furigana: "",
      start: false,
      return: false,
      tel: "",
      note: "",
    };
    newData[stopageIndex].riders.push(newRider);
    setRidingReports(newData);
  };

  const modifyRiderArray = async (stopageIndex, riderIndex, field, value) => {
    console.log("object", stopageIndex, riderIndex, field, value);

    const newData = [...ridingReports];
    if (
      stopageIndex >= 0 &&
      stopageIndex < newData.length &&
      riderIndex >= 0 &&
      riderIndex < newData[stopageIndex].riders.length
    ) {
      newData[stopageIndex].riders[riderIndex][field] = value;

      if (field === "no") {
        console.log("working");

        const data = await getStudentsData(value);

        console.log("working 2 data", data);

        if (data) {
          newData[stopageIndex].riders[riderIndex]["name"] = data.name;
          newData[stopageIndex].riders[riderIndex]["name_furigana"] =
            data.name_furigana;
          newData[stopageIndex].riders[riderIndex]["tel"] = data.tel;
          newData[stopageIndex].riders[riderIndex]["note"] = data.note;
          newData[stopageIndex].riders[riderIndex]["member_id"] = data.id;
        } else {
          newData[stopageIndex].riders[riderIndex]["no"] = value;
        }
      }

      setRidingReports(newData);
    } else {
      console.error("Invalid stopageIndex or riderIndex");
    }
  };

  const getRidingData = async id => {
    const response = await privateApi.get(`/admin/bus-times/${id}`);

    if (response.status === 200) {
      setRidingData(response.data.data);
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const getStudentsData = async id => {
    const response = await privateApi.get(`admin/members?search=${id}`);

    if (response.status === 200) {
      const data = response.data.data[0];

      if (response.data.data.length > 0) {
        return {
          id: data.id,
          name: data.name,
          name_furigana: data.furigana,
          tel: data.phone_no_1,
          note: data.note,
        };
      }
    } else {
      console.log("Server Error");
    }
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const getRidingReports2 = async id => {
    const response = await privateApi.get(`/admin/bus-reports/${id}`);

    if (response.status === 200) {
      setRidingReports(response.data.data);
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const getRidingReports = async () => {
    const response = await privateApi.get(`/admin/bus-reports/${id}`);
    if (response.status === 200) {
      const data = response.data.data;
      const selectedOptions = data
        .map(stopage =>
          stopage.riders.map(rider => ({ value: rider.no, label: rider.no }))
        )
        .flat();
      setSelectedOptions(selectedOptions);
      setRidingReports(data);
    } else {
      swal(response.data.message, "", "error");
    }
  };

  function deleteRider(stopageIndex, riderIndex) {
    const newData = [...ridingReports];
    if (
      stopageIndex >= 0 &&
      stopageIndex < newData.length &&
      riderIndex >= 0 &&
      riderIndex < newData[stopageIndex].riders.length
    ) {
      newData[stopageIndex].riders.splice(riderIndex, 1);
      setRidingReports(newData);
    } else {
      console.error("Invalid stopageIndex or riderIndex");
    }
  }

  const printReport = () => {
    const input = document.getElementById("ridingTable");

    html2canvas(input, { scrollY: -window.scrollY }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      const nowDate = moment(new Date()).format("YYYY-MM-DD");

      const pdfName =
        "バス" +
        ridingData?.bus_name +
        "コース" +
        ridingData?.class_name +
        ridingData?.time +
        ridingData?.day_week +
        "曜日" +
        nowDate +
        ".pdf";

      pdf.save(pdfName);
    });
  };

  const submitData = async () => {
    const response = await privateApi.post(`/admin/bus-reports/${id}`, {
      data: ridingReports,
    });

    if (response.status === 200) {
      swal("データが保存されました。", "", "success");
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const getStudentsList = async () => {
    try {
      const response = await privateApi.get(
        "admin/get-members-list?type=Regular"
      );
      if (response.status === 200) {
        const studentsOption = response.data.data?.map(student => ({
          value: student.member_code,
          label: student.member_code,
        }));
        setStudentList(studentsOption);
        setLoading(false);
      } else {
        console.log("Server Error!");
      }
    } catch (error) {
      console.log("Error fetching student list:", error);
    }
  };

  useEffect(() => {
    getStudentsList();
    getRidingData(id);
    getRidingReports(id);
  }, [id]);

  const tableRef = useRef(null);

  const dataTable = (
    <div className="table-responsive" id="ridingTable">
      <div className="p-3 mt-5 mb-3 light-border">
        <div className="bg-light p-2">
          <div className="row ride_headline">
            <h5 className="col-4">
              {ridingData?.class_name} - {ridingData?.time}
            </h5>
            <h5 className="col-4 text-center">{ridingData?.day_week} 曜日</h5>
            <h5 className="col-4 text-end">{ridingData?.bus_name} コース</h5>
          </div>
        </div>

        <div className="table-responsive">
          <table
            className="mb-0 table table-bordered mt-2 custom-tbl2 align-middle"
            ref={tableRef}
          >
            <thead>
              <tr>
                <th>バス停</th>
                {idView && <th>No</th>}
                <th>名前</th>
                <th>フリガナ</th>
                <th>往</th>
                <th>復</th>
                {telView && <th>TEL</th>}
                <th className="truncated-text">備考</th>
              </tr>
            </thead>
            <tbody>
              {ridingReports &&
                ridingReports.map((stopage, stopageIndex) => (
                  <React.Fragment key={stopageIndex}>
                    {stopage.riders.length > 0 && (
                      <>
                        {stopage.riders.map((rider, riderIndex) => (
                          <React.Fragment key={`${stopageIndex}-${riderIndex}`}>
                            <tr>
                              {riderIndex === 0 && (
                                <td style={{ verticalAlign: "baseline" }} rowSpan={stopage.riders.length}>
                                  <strong style={{ fontSize: "16px" }}>
                                    {stopage.stopage_name}
                                  </strong>
                                  <br />
                                  {stopage.time}
                                </td>
                              )}

                              {idView && <td>{ActualValue(rider.no)}</td>}

                              <td>{rider.name}</td>
                              <td>{rider.name_furigana}</td>
                              <td className="text-center">
                                {rider.start ? "⭕️" : "❌"}
                              </td>
                              <td className="text-center">
                                {rider.return ? "⭕️" : "❌"}
                              </td>

                              {telView && <td>{rider.tel}</td>}

                              <td className="truncated-text">
                                {rider.note?.length > 8
                                  ? rider.note?.substring(0, 15) + "..."
                                  : rider.note}{" "}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}

                        <tr>
                          <td>&nbsp;</td>

                          {idView && <td>&nbsp;</td>}

                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td className="text-center">
                            <strong>
                              {stopage.riders.reduce(
                                (total, rider) => total + (rider.start ? 1 : 0),
                                0
                              )}
                            </strong>
                          </td>
                          <td className="text-center">
                            <strong>
                              {stopage.riders.reduce(
                                (total, rider) =>
                                  total + (rider.return ? 1 : 0),
                                0
                              )}
                            </strong>
                          </td>
                          {telView && <td>&nbsp;</td>}
                          <td>&nbsp;</td>
                        </tr>
                      </>
                    )}
                  </React.Fragment>
                ))}

              {Array.from({
                length: Math.max(
                  32 -
                    ridingReports?.reduce(
                      (total, stopage) =>
                        total +
                        (stopage.riders.length > 0
                          ? stopage.riders.length + 1
                          : 0),
                      0
                    ),
                  0
                ),
              }).map((_, index) => (
                <tr key={`extraRow-${index}`}>
                  {[
                    ...Array(
                      telView && idView ? 8 : telView || idView ? 7 : 6
                    ).keys(),
                  ].map((i, j) => (
                    <td>&nbsp;</td>
                  ))}
                </tr>
              ))}

              <tr>
                <td colSpan={idView ? 4 : 3}></td>
                <td className="text-center">
                  <strong>往</strong>
                </td>
                <td className="text-center">
                  <strong>復</strong>
                </td>
                <td colSpan={telView ? 2 : 1}></td>
              </tr>

              <tr>
                <td colSpan={idView ? 4 : 3}></td>
                <td className="text-center">
                  <strong>
                    {ridingReports?.reduce(
                      (total, stopage) =>
                        total +
                        stopage.riders.reduce(
                          (riderTotal, rider) =>
                            riderTotal + (rider.start ? 1 : 0),
                          0
                        ),
                      0
                    )}
                  </strong>
                </td>
                <td className="text-center">
                  <strong>
                    {ridingReports?.reduce(
                      (total, stopage) =>
                        total +
                        stopage.riders.reduce(
                          (riderTotal, rider) =>
                            riderTotal + (rider.return ? 1 : 0),
                          0
                        ),
                      0
                    )}
                  </strong>
                </td>
                <td colSpan={telView ? 2 : 1}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="mx-2">
        印刷日: {moment(new Date()).format("YYYY-MM-DD")}
      </div>
    </div>
  );

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
  };

  return (
    <div className="row">
      <div className="col-md-7">
        <div className="card">
          <div className="card-header">
            <div className="row ride_headline">
              <h5 className="col-4">
                {ridingData?.class_name} - {ridingData?.time}
              </h5>
              <h5 className="col-2 text-center">
                {moment(ridingData?.processed_date).format("YYYY")}年{" "}
                {moment(ridingData?.processed_date).format("MM")}月
              </h5>
              <h5 className="col-2 text-center">{ridingData?.day_week} 曜日</h5>
              <h5 className="col-4 text-end">{ridingData?.bus_name} コース</h5>
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="">
                <button
                  className="btn btn-sm btn-primary float-left mr-2"
                  onClick={() => printReport()}
                >
                  印刷
                  <i className="fas fa-download ml-3"></i>
                </button>

                <button
                  className="btn btn-sm btn-primary float-left mr-2"
                  onClick={() => handleShow()}
                >
                  見せる
                  <i className="fas fa-eye ml-3"></i>
                </button>

                <CSVDownload
                  ridingData={ridingData}
                  ridingReports={ridingReports}
                  idView={idView}
                  telView={telView}
                />
              </div>

              <div>
                <button
                  className="btn btn-sm btn-primary float-right"
                  style={{ marginLeft: "3px" }}
                  onClick={() => submitData()}
                >
                  保存
                </button>
                <button
                  className="btn btn-sm btn-primary float-right"
                  onClick={() => setShowDetails(false)}
                >
                  戻る
                </button>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-striped table-bordered table-hover mt-2 custom-tbl align-middle">
                <thead>
                  <tr>
                    <th style={{ minWidth: "120px" }}>バス停</th>
                    <th style={{ minWidth: "150px" }}>No</th>
                    <th>名前</th>
                    <th>フリガナ</th>
                    <th>往</th>
                    <th>復</th>
                    <th>TEL</th>
                    <th style={{ minWidth: "140px" }}>備考</th>
                    <th>Add</th>
                  </tr>
                </thead>
                <tbody>
                  {ridingReports &&
                    ridingReports.map((stopage, stopageIndex) => (
                      <React.Fragment key={stopageIndex}>
                        {stopage.riders.map((rider, riderIndex) => (
                          <tr key={riderIndex}>
                            {riderIndex === 0 && (
                              <td
                                style={{ minWidth: "120px" }}
                                rowSpan={stopage.riders.length}
                              >
                                <strong style={{ fontSize: "16px" }}>
                                  {stopage.stopage_name}
                                </strong>
                                <br />
                                {stopage.time}
                              </td>
                            )}
                            <td>
                              <ReactSelect
                                style={{ minWidth: "150px" }}
                                placeholder="生徒を選択"
                                options={studentList}
                                onChange={e => {
                                  handleChange();
                                  modifyRiderArray(
                                    stopageIndex,
                                    riderIndex,
                                    "no",
                                    e.value
                                  );
                                }}
                                value={selectedOptions.find(
                                  option => option.value === rider.no
                                )}
                                isLoading={loading}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="name"
                                value={rider.name}
                                style={{ width: "100px" }}
                                onChange={e =>
                                  modifyRiderArray(
                                    stopageIndex,
                                    riderIndex,
                                    "name",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="name_furigana"
                                value={rider.name_furigana}
                                style={{ width: "100px" }}
                                onChange={e =>
                                  modifyRiderArray(
                                    stopageIndex,
                                    riderIndex,
                                    "name_furigana",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                checked={rider.start}
                                onChange={e =>
                                  modifyRiderArray(
                                    stopageIndex,
                                    riderIndex,
                                    "start",
                                    e.target.checked // pass the boolean value directly
                                  )
                                }
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                checked={rider.return}
                                onChange={e =>
                                  modifyRiderArray(
                                    stopageIndex,
                                    riderIndex,
                                    "return",
                                    e.target.checked // pass the boolean value directly
                                  )
                                }
                              />
                            </td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                name="tel"
                                value={rider.tel}
                                style={{ width: "100px" }}
                                onChange={e =>
                                  modifyRiderArray(
                                    stopageIndex,
                                    riderIndex,
                                    "tel",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td style={{ minWidth: "140px" }}>
                              {" "}
                              <input
                                type="text"
                                name="note"
                                value={rider.note}
                                style={{ width: "100px" }}
                                onChange={e =>
                                  modifyRiderArray(
                                    stopageIndex,
                                    riderIndex,
                                    "note",
                                    e.target.value
                                  )
                                }
                              />
                              <button
                                className="btn btn-sm btn-sm-custom btn-danger"
                                style={{ marginLeft: "8px" }}
                                onClick={() =>
                                  deleteRider(stopageIndex, riderIndex)
                                }
                              >
                                <FontAwesomeIcon icon="trash" />
                              </button>
                            </td>

                            {riderIndex === 0 && (
                              <td
                                rowSpan={stopage.riders.length}
                                className="text-center"
                              >
                                <button
                                  onClick={() => handleAddRow(stopageIndex)}
                                  className="btn btn-sm btn-sm-custom btn-success"
                                >
                                  +
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                        {stopage.riders.length === 0 && (
                          <tr>
                            <td>
                              <strong style={{ fontSize: "16px" }}>
                                {stopage.stopage_name}
                              </strong>
                              <br />
                              {stopage.time}
                            </td>
                            <td colSpan="7"></td>
                            <td className="text-center">
                              <button
                                onClick={() => handleAddRow(stopageIndex)}
                                className="btn btn-sm btn-sm-custom btn-success"
                              >
                                +
                              </button>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="mt-5">
              <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body className="m-0">{dataTable}</Modal.Body>
              </Modal>
            </div>

            <div>
              <div className="d-flex">
                <strong className="mt-1 mr-5">PDF/CSV を印刷する:</strong>

                <div class="form-check mr-5">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value={idView}
                    onChange={() => setIdView(!idView)}
                    checked={idView}
                  />
                  <label class="form-check-label mt-1">NO</label>
                </div>
                <div class="form-check ml-5">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value={telView}
                    onChange={() => setTelView(!telView)}
                    checked={telView}
                  />
                  <label class="form-check-label text-bold mt-1">TEL</label>
                </div>
              </div>

              {dataTable}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RidingListDetails;
