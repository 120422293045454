import React, { useState } from "react";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import "../../extrapage/Page52.css";
import "../../extrapage/extraPageStyles.css";
import BackButton from "../../common/Button/BackButton";
import Page53 from "../../extrapage/Page53";
import privateApi from "../../../services/privateApi";
import swal from "sweetalert";
import { useMediaQuery } from "react-responsive";

const OutputBus = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1366 });


  const [isPage53_2, setIsPage53_2] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [busDetails, setBusDetails] = useState([]);

  const getBusDetails = async () => {
    try {
      const response = await privateApi.get(
        `admin/bus-timetable?week_days=${selectedDays}`
      );
      if (response.status === 200) {
        setBusDetails(response?.data?.data);
        setIsPage53_2(true);
      } else {
        console.log("error");
      }
    } catch (error) {
      swal("Error", "クラスの再スケジュール中にエラーが発生しました", "error");
    }
  };

  const getDetails = () => {
    if (selectedDays.length) {
      getBusDetails();
    }
  };

  const handleDayClick = day => {
    const isSelected = selectedDays.includes(day);

    if (isSelected) {
      setSelectedDays(selectedDays.filter(selectedDay => selectedDay !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  return (
    <div
      style={{
        width: isMobile ? "100%" : isTablet || isLaptop ? "85%" : "40%",
      }}
    >
      {!isPage53_2 && (
        <div className="">
          <div className="card">
            <div className="card-body">
              <p className="m-0">所属クラス:</p>
              <div className="row mb-2 gx-2">
                <div className="col-md-auto ms-2">
                  <button
                    onClick={() => handleDayClick("月")}
                    className={`buttons_style  button3 btn btn-md btn-primary mb-1 ${selectedDays.includes("月") ? "selected" : ""
                      }`}
                    variant="primary"
                  >
                    {" "}
                    月曜日
                  </button>
                </div>

                <div className="col-md-auto">
                  <button
                    onClick={() => handleDayClick("火")}
                    className={`buttons_style  button3 btn btn-md btn-primary mb-1 ${selectedDays.includes("火") ? "selected" : ""
                      }`}
                    variant="primary"
                  >
                    {" "}
                    火曜日
                  </button>
                </div>

                <div className="col-md-auto">
                  <button
                    onClick={() => handleDayClick("水")}
                    className={`buttons_style  button3 btn btn-md btn-primary mb-1 ${selectedDays.includes("水") ? "selected" : ""
                      }`}
                    variant="primary"
                  >
                    {" "}
                    水曜日
                  </button>
                </div>

                <div className="col-md-auto">
                  <button
                    onClick={() => handleDayClick("大")}
                    className={`buttons_style  button3 btn btn-md btn-primary mb-1 ${selectedDays.includes("大") ? "selected" : ""
                      }`}
                    variant="primary"
                  >
                    {" "}
                    木曜日
                  </button>
                </div>

                <div className="col-md-auto">
                  <button
                    onClick={() => handleDayClick("金")}
                    className={`buttons_style  button3 btn btn-md btn-primary mb-1 ${selectedDays.includes("金") ? "selected" : ""
                      }`}
                    variant="primary"
                  >
                    {" "}
                    金曜日
                  </button>
                </div>

                <div className="col-md-auto">
                  <button
                    onClick={() => handleDayClick("土")}
                    className={`buttons_style  button3 btn btn-md btn-primary mb-1 ${selectedDays.includes("土") ? "selected" : ""
                      }`}
                    variant="primary"
                  >
                    {" "}
                    土曜日
                  </button>
                </div>

                <div className="col-md-auto">
                  <button
                    onClick={() => handleDayClick("日")}
                    className={`buttons_style  button3 btn btn-md btn-primary mb-1 ${selectedDays.includes("日") ? "selected" : ""
                      }`}
                    variant="primary"
                  >
                    {" "}
                    日曜日
                  </button>
                </div>
              </div>
              <div className="row d-flex justify-content-end m-0 p-0 gx-2">
                <div className="col-lg-4 px-3">
                  <button
                    onClick={() => getDetails()}
                    className="buttons_style button3 btn btn-md btn-primary mb-1 btn-success"
                    variant="primary"
                  >
                    {" "}
                    バス利用者明細
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <p className="mt-1 mb-0">短期クラス:</p>
            </div>
            <div className="row my-1">
              <div className="col-md-12">
                <div className="row  g-1 align-items-center">
                  <div className="col-2 rtl">:開校年</div>
                  <div className="col-md-2 p-2">
                    <input
                      type="text"
                      placeholder="年"
                      className="form-control"
                    />
                  </div>
                  <div className="col-auto p-2">時期:</div>
                  <div className="col-md-auto">
                    <ReactSelect
                      classNe="shadow-sm "
                      options={[
                        { value: "1", label: "春" },
                        { value: "2", label: "夏" },
                        { value: "3", label: "冬" },
                      ]}
                      placeholder="春"
                    />
                  </div>
                </div>
                <div className="row  g-1 align-items-center mt-0  ">
                  <div className="col-md-2 rtl">:短期クラス</div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder=""
                      className="form-control form-control-md"
                    />
                  </div>
                  <div className="col-1">
                    <button
                      onClick={() => setIsPage53_2(true)}
                      className="btn btn-md btn-primary"
                      variant="primary"
                    >
                      ...
                    </button>
                  </div>
                </div>
                <div className="row  g-1 align-items-center mt-0  ">
                  <div className="col-md-2 rtl">:短期コース</div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder=""
                      className="form-control form-control-md"
                    />
                  </div>
                  <div className="col-1">
                    <button className="btn btn-md btn-primary" variant="primary">
                      {" "}
                      ...
                    </button>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-10">
                    <Link
                      to="/admin/output-bus/details"
                      className="btn btn-sm btn-primary ms-1"
                      variant="primary"
                    >
                      {" "}
                      出力
                    </Link>
                  </div>
                  <div className="col-md-2">
                    <BackButton redirectTo="/admin/bus/output-bus" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPage53_2 && (
        <Page53 busDetails={busDetails} selectedDays={selectedDays} />
      )}

    </div>
  );
};

export default OutputBus;
