import React from "react";
import { Link } from "react-router-dom";

import "../masterMaintenance/extraPageStyles.css";

const STDashboardButtons = [
  {
    name: "短期クラスマスタ",
    path: "/admin/stc/short-term-class",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "短期コースマスタ",
    path: "/admin/stc/short-term-course",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "短期会員リスト",
    path: "/admin/stc/short-term-student-list",
    icon: "fas fa-tachometer-alt",
  },
];

function STDashboard() {
  return (
    <div className="p-3">
      <p className="headline">短期コース管理機能一覧</p>
      <div className="btn-container col-md-4 col-sm-12">
        {STDashboardButtons.map(button => (
          <Link to={button.path}>
            <button className="dash-btn">{button.name}</button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default STDashboard;
