import React, { Suspense, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../common/Loader/Loader";
import BackButton from "../common/Button/BackButton";
import Nvh from "../common/Nvh";
import privateApi from "../../services/privateApi";

import "./BankmasterStyle.css";
import { Skeleton } from "antd";
import RouteLeavingGuard from "../Prompt/RouterLeavingGuard";

const BankMaster = ({ history }) => {
  const [paginationBtns, setPaginationBtns] = useState([]);
  const [modalNewBank, setModalNewBank] = useState(false);
  const [modalEditBank, setModalEditBank] = useState(false);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState();
  const [errorList, setErrorList] = useState({});
  const [loading, setLoading] = useState("");
  const [whenState, updateWhenState] = useState(false);

  const [dataInput, setData] = useState({
    id: "",
    bank_code: "",
    branch_code: "",
    deposit_item: "",
    account_number: "",
    bank_name_kana: "",
    bank_name: "",
    branch_name_kana: "",
    branch_name: "",
    account_name_kana: "",
    account_name: "",
    deposit_item_name: " ",
    transfer_flag: 1,
    consignor_code: "",
    consignor_name: "",
    file_name: null,
  });

  const handleInput = e => {
    e.persist();
    updateWhenState(true);
    const value =
      e.target.type === "checkbox"
        ? e.target.checked
          ? 1
          : 0
        : e.target.value;
    setData({ ...dataInput, [e.target.name]: value });
  };

  const getAllBanks = async pageNumber => {
    setLoading(true);
    const response = await privateApi.get(
      `admin/bank-informations?page=${pageNumber}`
    );

    if (response.status === 200) {
      setBanks(response.data.data);
      setPaginationBtns(response.data?.meta);
    } else {
      console.log("Server Error");
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllBanks();
  }, []);

  const deleteBank = async id => {
    const response = await privateApi.delete(`admin/bank-informations/${id}`);

    if (response.status === 200) {
      swal("削除しました！", "銀行が削除されました！", "success");
      getAllBanks();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const addNewBank = async () => {
    const response = await privateApi.post(
      "admin/bank-informations",
      dataInput
    );
    if (response.status === 200) {
      updateWhenState(false);
      swal("作成した！", "新しい銀行を作成しました！", "success");
      setModalNewBank(false);
      getAllBanks();
      setData({});
    } else {
      swal("内容に問題があります", response.data.message, "error");
    }
  };

  const updateModalShow = async id => {
    setSelectedBank(id);
    setModalEditBank(true);

    const response = await privateApi.get("admin/bank-informations/" + id);

    if (response.status === 200) {
      setData(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const updateFormSubmit = async id => {
    const updatedData = {
      bank_code: dataInput.id,
      bank_code: dataInput.bank_code,
      branch_code: dataInput.branch_code,
      deposit_item: dataInput.deposit_item,
      account_number: dataInput.account_number,
      bank_name_kana: dataInput.bank_name_kana,
      bank_name: dataInput.bank_name,
      branch_name_kana: dataInput.branch_name_kana,
      branch_name: dataInput.branch_name,
      account_name_kana: dataInput.account_name_kana,
      account_name: dataInput.account_name,
      deposit_item_name: dataInput.deposit_item_name,
      transfer_flag: dataInput.transfer_flag ? 1 : 0,
      consignor_code: dataInput.consignor_code,
      consignor_name: dataInput.consignor_name,
    };

    const response = await privateApi.update(
      `admin/bank-informations/${id}`,
      updatedData
    );

    if (response.status === 200) {
      updateWhenState(false);
      swal("銀行情報を更新しました", "", "success");
      setModalEditBank(false);
      setData({});
      setLoading(false);
      getAllBanks();
    } else {
      swal("内容に問題があります", response.data.message, "error");
      setErrorList(response.data.errors);
      setLoading(false);
    }
  };

  const handleClick = id => {
    setSelectedBank(id);
    //getBankInfo(id);
  };

  const getBankInfo = async id => {
    const response = await privateApi.get("admin/bank-informations/" + id);

    if (response.status === 200) {
      setData(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const position = {
    bottom: "5%",
  };

  return (
    <Suspense fallback={<Loader message="Loading.." />}>
      <div style={{ position: "relative" }}>
        <div className="card">
          <div className="card-header m-1 p-1">
            <div className="d-flex align-items-center">
              <p className="headline mb-0">銀行マスタ</p>
              <div className="ml-auto">
                <span
                  className="btn btn-sm btn-outline-success  px-3 ml-auto"
                  onClick={() => setModalNewBank(true)}
                >
                  {" "}
                  <i className="fas fa-plus fa-fw"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="card-body mx-1 p-1">
            <div className="table-responsive">
              {loading ? (
                <Skeleton paragraph={{ rows: 3 }} />
              ) : (
                <Table
                  striped
                  bordered
                  hover
                  className="table text-left"
                  responsive="sm"
                >
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "40px" }}>
                        番号
                      </th>
                      <th className="text-center" style={{ minWidth: "80px" }}>
                        銀行コード
                      </th>
                      <th className="text-center" style={{ minWidth: "80px" }}>
                        支店コード
                      </th>
                      <th className="text-center" style={{ minWidth: "65px" }}>
                        預金種目
                      </th>
                      <th className="text-center" style={{ minWidth: "70px" }}>
                        口座番号
                      </th>
                      <th className="text-center" style={{ minWidth: "110px" }}>
                        銀行名カナ
                      </th>
                      <th className="text-center" style={{ minWidth: "110px" }}>
                        銀行名
                      </th>
                      <th className="text-center" style={{ minWidth: "100px" }}>
                        支店名カナ
                      </th>
                      <th className="text-center" style={{ minWidth: "65px" }}>
                        支店名
                      </th>
                      <th className="text-center" style={{ minWidth: "170px" }}>
                        口座名カナ
                      </th>
                      <th className="text-center" style={{ minWidth: "170px" }}>
                        口座名
                      </th>
                      <th className="text-center" style={{ minWidth: "100px" }}>
                        預金種目名
                      </th>
                      <th className="text-center" style={{ minWidth: "100px" }}>
                        振替フラグ
                      </th>
                      <th className="text-center" style={{ minWidth: "120px" }}>
                        委託者コード
                      </th>
                      <th className="text-center" style={{ minWidth: "170px" }}>
                        受託者名
                      </th>
                      <th
                        className="text-center border action-column "
                        style={{
                          minWidth: "100px",
                          backgroundColor: "#D6EAF8",
                          position: "sticky",
                        }}
                      >
                        操作
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {banks.map((bank, index) => (
                      <tr
                        onClick={() => handleClick(bank.id)}
                        key={index}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedBank === bank.id ? "#eaf6ff" : "",
                        }}
                      >
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">
                          {Nvh({ value: bank.bank_code })}
                        </td>
                        <td className="text-center">
                          {Nvh({ value: bank.branch_code })}
                        </td>
                        <td className="text-center">
                          {Nvh({ value: bank.deposit_item })}
                        </td>
                        <td className="text-center">
                          {Nvh({ value: bank.account_number })}
                        </td>
                        <td className="text-center">
                          {Nvh({ value: bank.bank_name_kana })}
                        </td>
                        <td className="text-center">
                          {Nvh({ value: bank.bank_name })}
                        </td>
                        <td className="text-center">
                          {Nvh({ value: bank.branch_name_kana })}
                        </td>
                        <td className="text-center mx-2">
                          {Nvh({ value: bank.branch_name })}
                        </td>
                        <td className="text-center mx-2">
                          {Nvh({ value: bank.account_name_kana })}
                        </td>
                        <td className="text-center mx-2">
                          {Nvh({ value: bank.account_name })}
                        </td>
                        <td className="text-center mx-2">
                          {Nvh({ value: bank.deposit_item_name })}
                        </td>
                        <td className="text-center ">
                          <Form.Check
                            className="d-flex justify-content-center  mx-2"
                            type="checkbox"
                            id={`custom-switch-${index}`}
                            label=""
                            checked={bank.transfer_flag}
                            onChange={() => {}}
                          />
                        </td>
                        <td className="text-center">{bank.consignor_code}</td>
                        <td className="text-center">{bank.consignor_name}</td>
                        <td
                          className="text-center border action-column "
                          style={{
                            minWidth: "100px",
                            backgroundColor: "#D6EAF8",
                            position: "sticky",
                          }}
                        >
                          <button
                            className="btn btn-sm btn-primary me-2"
                            onClick={() => updateModalShow(bank.id)}
                          >
                            <FontAwesomeIcon icon="edit" />
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteBank(bank.id)}
                          >
                            <FontAwesomeIcon icon="trash" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>

            <div className="paginations d-flex mt-3">
              <div className="d-flex mx-auto neomp">
                <Pagination
                  activePage={
                    paginationBtns.current_page
                      ? paginationBtns.current_page
                      : 0
                  }
                  itemsCountPerPage={paginationBtns.per_page}
                  totalItemsCount={paginationBtns.total}
                  onChange={pageNumber => {
                    getAllBanks(pageNumber);
                  }}
                  itemclassName="page-item"
                  linkclassName="page-link"
                  firstPageText="First"
                  lastPageText="Last"
                />
              </div>
            </div>
            <BackButton
              redirectTo="/admin/master-maintenance/dashboard"
              position={position}
            />
          </div>{" "}
        </div>

        {/* modal for adding new bnk */}
        <Modal
          show={modalNewBank}
          onHide={() => setModalNewBank(false)}
          size="xl"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
          // onsubmit={() => addNewBank()}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              新しい銀行を追加する
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="">
                <div className="row">
                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>銀行コード</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="銀行コードを入力してください"
                      name="bank_code"
                      onChange={handleInput}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>支店コード</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="支店コードを入力してください"
                      name="branch_code"
                      onChange={handleInput}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>預金種目</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="預金種目を入力してください"
                      name="deposit_item"
                      onChange={handleInput}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>口座番号</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="口座番号を入力してください"
                      name="account_number"
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>銀行名カナ</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="銀行名カナを入力してください"
                      name="bank_name_kana"
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>銀行名</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="銀行名を入力してください"
                      name="bank_name"
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>支店名カナ</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="支店名カナを入力してください"
                      name="branch_name_kana"
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>支店名</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="支店名を入力してください"
                      name="branch_name"
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>口座名カナ</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="口座名カナを入力してください"
                      name="account_name_kana"
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>口座名</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="口座名を入力してください"
                      name="account_name"
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>預金種目名</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="預金種目名を入力してください"
                      name="deposit_item_name"
                      onChange={handleInput}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label></Form.Label>
                    <Form.Check
                      type="checkbox"
                      className="align-items-end"
                      label="振替フラグ"
                      onClick={handleInput}
                      checked={dataInput.transfer_flag === 1}
                      name="transfer_flag"
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>委託者コード</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="委託者コードを入力してください"
                      name="consignor_code"
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>受託者名</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="受託者名を入力してください"
                      name="consignor_name"
                      onChange={handleInput}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal for editing current bnk*/}
        <Modal
          show={modalEditBank}
          onHide={() => setModalEditBank(false)}
          size="xl"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              銀行情報を更新する
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="">
                <div className="row">
                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>銀行コード</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="銀行コードを入力してください"
                      name="bank_code"
                      value={dataInput.bank_code}
                      onChange={handleInput}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>支店コード</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="姓を入力してください"
                      name="branch_code"
                      value={dataInput.branch_code}
                      onChange={handleInput}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>預金種目</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="電話番号を入力"
                      name="deposit_item"
                      value={dataInput.deposit_item}
                      onChange={handleInput}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>口座番号</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="メールアドレスを入力"
                      name="account_number"
                      value={dataInput.account_number}
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>銀行名カナ</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="メールアドレスを入力"
                      name="bank_name_kana"
                      value={dataInput.bank_name_kana}
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>銀行名</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="メールアドレスを入力"
                      name="bank_name"
                      value={dataInput.bank_name}
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>支店名カナ</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="メールアドレスを入力"
                      name="branch_name_kana"
                      value={dataInput.branch_name_kana}
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>支店名</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="メールアドレスを入力"
                      name="branch_name"
                      value={dataInput.branch_name}
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>口座名カナ</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="メールアドレスを入力"
                      name="account_name_kana"
                      value={dataInput.account_name_kana}
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>口座名</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="メールアドレスを入力"
                      name="account_name"
                      value={dataInput.account_name}
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>預金種目名</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="メールアドレスを入力"
                      name="deposit_item_name"
                      value={dataInput.deposit_item_name}
                      onChange={handleInput}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label></Form.Label>
                    <Form.Check
                      type="checkbox"
                      className="align-items-end"
                      label="振替フラグ"
                      onClick={handleInput}
                      checked={dataInput.transfer_flag === 1}
                      name="transfer_flag"
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>委託者コード</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="メールアドレスを入力"
                      name="consignor_code"
                      value={dataInput.consignor_code}
                      onChange={handleInput}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-4 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>受託者名</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="メールアドレスを入力"
                      name="consignor_name"
                      value={dataInput.consignor_name}
                      onChange={handleInput}
                    />
                  </Form.Group>
                </div>
              </div>
              <Button
                className="float-right px-5"
                variant="primary"
                // type="submit"
                onClick={() => updateFormSubmit(selectedBank)}
                style={{ float: "right" }}
              >
                保存
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <RouteLeavingGuard
        when={whenState}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={location => {
          if (whenState) {
            return true;
          }
          return false;
        }}
        yes="yes"
        no="no"
      />
    </Suspense>
  );
};

export default BankMaster;
