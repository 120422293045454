import axios from "axios";
import { getToken, saveToken, saveData, destroyToken } from "./token";

// const apiUrl = 'http://127.0.0.1:8000/api/';
// const apiUrl = "http://52.68.248.212:4000/api/";

class AuthService {
  async login(user = "", data) {
    const headers = {
      Accept: `application/json`,
    };
    try {
      const response = await axios.post(user + "/login", data, { headers: headers });
      if (response.status === 200) {
        saveToken(response.data.api_token);
        saveData(JSON.stringify(response.data.data));
      }
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async register(data) {
    const headers = {
      Accept: `application/json`,
    };
    try {
      const response = await axios.post("candidate/auth/register", data, {
        headers: headers,
      });
      if (response.data.api_token) {
        saveToken(response.data.api_token);
        saveData(JSON.stringify(response.data.data));
      }

      return response;
    } catch (error) {
      return error;
    }
  }

  async otp(data) {
    const headers = {
      Accept: `application/json`,
    };
    try {
      const response = await axios.post("candidate/otp-verification", data, {
        headers: headers,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async resendOtp() {
    const headers = {
      Accept: `application/json`,
    };
    try {
      const response = await axios.post("candidate/resend-otp", { headers: headers });
      return response;
    } catch (error) {
      return error;
    }
  }

  async logout(user = "") {
    const headers = {
      Accept: `application/json`,
      Authorization: `Bearer ${getToken()}`,
    };
    try {
      const response = await axios.post(user + "/logout", { headers: headers });

      destroyToken();

      return response;
    } catch (error) {
      return error;
    }
  }
}

export default new AuthService();
