import moment from "moment";
import React from "react";

function CSVDownload({ ridingData, ridingReports, idView, telView }) {
  const convertToCSV = ridingReports => {
    let csv = "";
    // Header row
    csv += "バス停,";
    if (idView) csv += "No,";
    csv += "名前,フリガナ,往,復,";
    if (telView) csv += "TEL,";
    csv += "備考\n";

    // Data rows
    ridingReports.forEach((stopage, stopageIndex) => {
      stopage.riders.forEach((rider, riderIndex) => {
        const name = rider.name ?? "";
        const name_furigana = rider.name_furigana ?? "";
        const start = rider.start ? "⭕️" : "❌";
        const returnVal = rider.return ? "⭕️" : "❌";
        const tel = rider.tel ?? "";
        const note = rider.note ?? "";

        if (riderIndex === 0) {
          csv += `${stopage.stopage_name},${idView ? rider.no + "," : ""}${name},${name_furigana},${start},${returnVal},${telView ? tel + "," : ""}${note}\n`;
        } else {
          csv += `,${idView ? rider.no + "," : ""}${name},${name_furigana},${start},${returnVal},${telView ? tel + "," : ""}${note}\n`;
        }
      });

	  if(stopage.riders.length > 0)
	  {
		csv += `,,,,${stopage.riders.reduce((total, rider) => total + (rider.start ? 1 : 0), 0)},${stopage.riders.reduce((total, rider) => total + (rider.return ? 1 : 0), 0)},\n`;
	  }

      
    });

    return csv;
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(ridingReports);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;

    const nowDate = moment(new Date()).format("YYYY-MM-DD");

    const csvName =
      "バス" +
      ridingData?.bus_name +
      "コース" +
      ridingData?.class_name +
      ridingData?.time +
      ridingData?.day_week +
      "曜日" +
      nowDate +
      ".csv";

    a.download = csvName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <button className="btn btn-sm btn-primary float-left" onClick={downloadCSV}>
      CSV <i className="fas fa-print ml-3"></i>
    </button>
  );
}

export default CSVDownload;
