import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import Select from "react-select";

import ReactDatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";

import "../../../assets/coach/css/styles.css";
import privateApi from "../../../services/privateApi";

const RankReport = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateStr, setSelectedDateStr] = useState(null);

  const [isLastMonth, setIsLastMonth] = useState(false);
  const [classes, setClasses] = useState([]);

  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");

  const [selectedRange, setSelectedRange] = useState("");
  const [classSchedule, setClassSchedule] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedWeekday, setSelectedWeekday] = useState("");

  const rangeOptions = [...Array(25).keys()].map(i => i + 1);
  rangeOptions.unshift("特習");
  const weekDays = ["日", "月", "火", "水", "木", "金", "土"];

  const fetchStudents = async () => {
    try {
      const response = await privateApi.post("/coach/membersByRank", {
        date: selectedDateStr || null,
        regular_class_id: selectedClass?.value,
        class: selectedRange?.value,
        start_time: selectedClass?.start_time,
        end_time: selectedClass?.end_time,
      });
      if (response.status === 200) {
        setIsLastMonth(response.data?.data?.isLastMonth);
        const studentData = response.data?.data;

        setStudents(studentData);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching student attendance data:", error);
    }
  };

  const selectDateHandler = date => {
    if (date) {
      setSelectedDate(date);
      refactorSelectedDate();
    }
  };

  const handlePrint = async () => {
    const printWindow = window.open("", "_blank");
    await printWindow.document.write(
      '<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">'
    );
    const printContent = document.querySelector(".print-this");

    if (printWindow && printContent) {
      printWindow.document.write("<html><head><title>進級管理</title>");

      // Add custom print styles
      printWindow.document.write(`
      <style>
        body {
          margin: 20px;
        }
        table {
          border-collapse: collapse;
          width: 100%;
        }
        table, th, td {
          border: 1px solid black; 
        }
        @page {
          size: landscape;
        }
      </style>
    `);

      printWindow.document.write("</head><body>");
      printWindow.document.write(printContent.innerHTML);
      printWindow.document.write("</body></html>");

      printWindow.document.close();
      printWindow.print();
    } else {
      console.error("Print failed. Make sure there is content to print.");
    }
  };

  const refactorSelectedDate = () => {
    if (selectedDate) {
      const selectedDateCopy = new Date(selectedDate);
      selectedDateCopy.setDate(selectedDateCopy.getDate() + 1);
      setSelectedDateStr(
        selectedDateCopy?.toISOString().split("T")[0].slice(0, 7)
      );
    }
  };

  const getClasses = async e => {
    const response = await privateApi.get("coach/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const getScheduleInfo = async id => {
    const response = await privateApi.get(
      `/coach/class-schedules/${selectedClass?.value}`
    );

    if (response.status === 200) {
      if (response.data.data && response.data.data.classSchedule) {
        setClassSchedule(response.data.data.classSchedule);
        // setSelectedClass(response.data.data.regular_class_id);
      } else {
        setClassSchedule([]);
      }
    } else {
      console.log("Server Error");
    }
  };

  useEffect(() => {
    getScheduleInfo();
  }, [selectedClass]);

  useEffect(() => {
    refactorSelectedDate();
  }, [selectedDate]);

  useEffect(() => {
    getClasses();
  }, []);

  useEffect(() => {
    if (selectedClass) {
      fetchStudents();
    }
  }, [
    selectedDate,
    selectedClass,
    selectedRange,
    selectedSchedule,
    selectedWeekday,
  ]);

  const UpperPart = () => {
    return (
      <>
        <div className="row mb-4 upper-container">
          <div className="col-md-2">
            <Select
              defaultValue={selectedClass}
              onChange={setSelectedClass}
              options={classes.map(option => ({
                value: option.id,
                label: `${option.affiliation_name}`,
              }))}
              placeholder="クラスを選択"
            />
          </div>

          {/* filter */}

          <div className="col-md-2">
            <Select
              defaultValue={selectedSchedule}
              onChange={setSelectedSchedule}
              options={classSchedule.flatMap(option =>
                option.classes.flatMap(schedule => {
                  if (schedule.start_time !== "" && schedule.end_time !== "") {
                    return {
                      start_time: schedule?.start_time,
                      end_time: schedule?.end_time,
                      value: schedule?.id,
                      label: `${schedule?.start_time} -- ${schedule?.end_time}`,
                    };
                  }
                  return [];
                })
              )}
              placeholder="スケジュールを選択する"
              isDisabled={!selectedClass}
            />
          </div>

          <div className="col-md-1">
            <Select
              value={selectedWeekday}
              onChange={setSelectedWeekday}
              options={weekDays.map((option, index) => ({
                value: index,
                label: `${option}`,
              }))}
              placeholder="平日"
              isDisabled={!selectedClass}
            />
          </div>
          <div className="col-md-2">
            <Select
              defaultValue={selectedRange}
              onChange={setSelectedRange}
              options={rangeOptions.map(option => ({
                value: option === "特習" ? 0 : option,
                label: `${option} ${option === "特習" ? "" : "級"}`,
              }))}
              placeholder="クラスを選択"
              isDisabled={!selectedClass}
            />
          </div>
          <div className="col-md-3 w-auto col-sm-6 text-center">
            <ReactDatePicker
              className="w-100 shadow-sm custom_report_select"
              dateFormat="yyyy/MM"
              selected={selectedDate}
              onChange={selectDateHandler}
              todayButton={"Today"}
              locale={ja}
              placeholderText="日付を選択"
              readOnly={!selectedClass}
              showMonthYearPicker={true}
            />
          </div>
          <div className="col-md-2">
            {(selectedDate ||
              selectedRange ||
              selectedSchedule ||
              selectedWeekday) && (
              <button
                onClick={() => {
                  setSelectedDate("");
                  setSelectedRange("");
                  setSelectedSchedule("");
                  setSelectedWeekday("");
                }}
                className="btn btn-warning"
              >
                クリア
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="p-3">
      <h3 className="headline">進級管理</h3>
      <>
        <UpperPart />
        <div>
          {students.length > 0 ? (
            <div className="print-this">
              <div className="list-header-one">
                <div
                  style={{ display: "flex", width: "50%", textAlign: "center" }}
                  className="w-50 border d-flex text-center"
                >
                  <div
                    style={{ width: "35%", border: "1px solid gray" }}
                    className="col-md-4 border"
                  >
                    <p>{selectedRange.label}</p>
                  </div>
                  <div
                    style={{ width: "35%", border: "1px solid gray" }}
                    className="border"
                  >
                    <p>{selectedDateStr}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "35%",
                      border: "1px solid gray",
                      flexDirection: "column",
                    }}
                    className="d-flex flex-column border"
                  >
                    <div
                      style={{ borderBottom: "1px solid gray" }}
                      className="border-bottom"
                    >
                      監督
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div
                style={{ display: "flex" }}
                className="list-header-two d-flex"
              >
                <div
                  style={{ width: "15%", border: "1px solid gray" }}
                  className="col-md-2 border"
                >
                  テスト項目
                </div>
                <div
                  style={{
                    width: "85%",
                    border: "1px solid gray",
                  }}
                  className="col-md-10 border"
                >
                  フローティング
                </div>
              </div>
              <div
                style={{ display: "flex" }}
                className="list-header-three d-flex"
              >
                <div
                  style={{ width: "15%", border: "1px solid gray" }}
                  className="col-md-2 border"
                >
                  目安
                </div>
                <div
                  style={{
                    width: "85%",
                    border: "1px solid gray",
                  }}
                  className="col-md-10 border"
                >
                  <text>
                    -そして人で入選水できるシャワーにかかれるある
                    そして進むこと(2~3m)ができる能力株・車・コーテの話を聞く
                  </text>
                  <br />
                  <text>
                    -順序よく豆と仲良くするで入退水ができる・シャワーにかかわ
                  </text>
                </div>
              </div>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th style={{ width: "2%" }}>1</th>
                    <th style={{ width: "2%" }}>2</th>
                    <th style={{ width: "2%" }}>3</th>
                    <th style={{ width: "2%" }}>4</th>
                    <th style={{ width: "2%" }}>5</th>
                    <th style={{ width: "2%" }}>6</th>
                    <th style={{ width: "2%" }}>7</th>
                    <th style={{ width: "2%" }}>8</th>
                    <th style={{ width: "2%" }}>9</th>
                    <th style={{ width: "2%" }}>10</th>
                    <th style={{ width: "2%" }}>11</th>
                    <th style={{ width: "2%" }}>12</th>
                    <th style={{ width: "10%" }}>会員番号</th>
                    <th style={{ width: "10%" }}>氏名</th>
                    <th style={{ width: "5%" }}>学年</th>
                    <th style={{ width: "20%" }}>前回までの注意点</th>
                    <th style={{ width: "20%" }}>今回の注意点</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, index) => (
                    <tr key={index}>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "2%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>
                      <td
                        style={{ width: "10%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      >
                        {student?.member?.member_code}
                      </td>
                      <td
                        style={{ width: "10%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      >
                        {student?.member?.name}
                      </td>
                      <td
                        style={{ width: "5%" }}
                        // onClick={() => toggleAttendance(student.id)}
                      ></td>

                      <td style={{ width: "20%" }}></td>
                      <td style={{ width: "20%" }}></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p className="text-info text-center">
              会員が見つかりませんでした。
            </p>
          )}
        </div>
        {students?.length > 0 && (
          <div className="col-md-12 d-flex justify-content-end">
            <Button variant="primary" onClick={handlePrint}>
              印刷する
            </Button>
          </div>
        )}
      </>
    </div>
  );
};

export default RankReport;
