import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import "../../assets/coach/css/styles.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "../../assets/coach/js/scripts";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import routes from "../../routes/adminRoutes";
const MasterLayout = () => {
  const sidebarToggle = () => {
    document.body.classList.remove("sb-sidenav-toggled");
  };

  // const history = useHistory();

  // useEffect(() => {
  //   const handleKeyPress = event => {
  //     switch (event.key) {
  //       case "F8":
  //         history.push("/admin/student");
  //         break;
  //       case "F9":
  //         history.push("/admin/student/create");
  //         break;
  //       case "F10":
  //         history.push("/admin/monthly-update");
  //         break;

  //       default:
  //         break;
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyPress);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, [history]);

  return (
    <div className="sb-nav-fixed">
      <Navbar />
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <Sidebar />
        </div>

        <div id="layoutSidenav_content">
          <main className="p-3">
            <Switch>
              {routes.map((route, idx) => {
                return (
                  route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  )
                );
              })}
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </main>
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  );
};

export default MasterLayout;
