import React from "react";
import { Link } from "react-router-dom";

import "../masterMaintenance/extraPageStyles.css";

const RMDashboardButtons = [
  {
    name: "システムユーザ",
    path: "/admin/user/user-management",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "コーチユーザ",
    path: "/admin/user/coach-management",
    icon: "fas fa-tachometer-alt",
  },
];

function RMDashboard() {
  return (
    <div className="p-3">
      <p className="headline">管理者一覧</p>
      <div className="btn-container col-md-4 col-sm-12">
        {RMDashboardButtons.map(button => (
          <Link to={button.path}>
            <button className="dash-btn">{button.name}</button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default RMDashboard;
