import React, { createContext, useContext, useState } from 'react';

// Create the context
const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
   const [user, setUser] = useState(null);

   return (
      <UserContext.Provider value={{ user, setUser }}>
         {children}
      </UserContext.Provider>
   );
};

export default UserContext;

export const useUser = () => {
   const { user, setUser } = useContext(UserContext);
   return { user, setUser };
};

