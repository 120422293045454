import React, { useEffect, useState } from 'react';
import useLoading from '../../../hooks/useLoading';
import privateApi from '../../../services/privateApi';
import { Card, Col, Collapse, Empty, Flex, Row, Select, Space, Table, Typography } from 'antd';
import { ClockCircleOutlined, } from "@ant-design/icons";
import "./styles.css";
const ClassItem = ({ value }) => {
  if (!value) {
    return <></>;
  }
  return value.map((v, i) => <div key={i} style={{ width: "100%", marginBottom: "5px", borderBottom: "1px solid #ddd", padding: "5px" }}>
    <Flex justify='space-between'>
      <div style={{ fontWeight: 600, color: "#898585" }}><ClockCircleOutlined />{v.time}</div>
      <div style={{ fontWeight: 600, color: "#898585" }}>{v.level}</div>
    </Flex>
    <div style={{ fontSize: 16, fontStyle: "italic" }}>{v.coach}</div>
  </div>);
};
const days = {
  0: "月",
  1: "火",
  2: "水",
  3: "木",
  4: "金",
  5: "土",
  6: "日",
};

const prepareFinalData = (rawData) => {
  const finalData = rawData?.reduce((pv, currentClass) => {
    return [...pv, {
      name: currentClass.class_name,
      items: [Object.values(currentClass.class_week).reduce((pv, current_week) => {
        return {
          ...pv,
          [current_week]: currentClass.time[current_week]?.filter(time => time !== "-").map((time, idx) => {
            return {
              time,
              coach: currentClass.coach[current_week][idx],
              level: currentClass.class_level[current_week][idx],
            };
          })
        };
      }, {})]
    }];
  }, []);
  return finalData;
};

const CourseMaintenanceNew = () => {
  const [allClasses, setAllClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [filter, setFilter] = useState({ class: "", coach: "", days: [] });
  const [coaches, setCoaches] = useState([]);
  const { startLoading, stopLoading, isLoading } = useLoading();
  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>月</div>,
      dataIndex: '月',
      key: '月',
      render: (value) => <ClassItem value={value} />
    },
    {
      title: <div style={{ textAlign: "center" }}>火</div>,
      dataIndex: '火',
      key: '火',
      render: (value) => <ClassItem value={value} />
    },
    {
      title: <div style={{ textAlign: "center" }}>水</div>,
      dataIndex: '水',
      key: '水',
      render: (value) => <ClassItem value={value} />
    },
    {
      title: <div style={{ textAlign: "center" }}>木</div>,
      dataIndex: '木',
      key: '木',
      render: (value) => <ClassItem value={value} />
    },
    {
      title: <div style={{ textAlign: "center" }}>金</div>,
      dataIndex: '金',
      key: '金',
      render: (value) => <ClassItem value={value} />
    },
    {
      title: <div style={{ textAlign: "center" }}>土</div>,
      dataIndex: '土',
      key: '土',
      render: (value) => <ClassItem value={value} />
    },
    {
      title: <div style={{ textAlign: "center" }}>日</div>,
      dataIndex: '日',
      key: '日',
      render: (value) => <ClassItem value={value} />
    },
  ];
  const handleFilter = (filter, rawData = allClasses) => {
    const data = rawData.filter(c => {
      if (filter.class !== "") {
        return c.class_name === filter.class;
      } else {
        return true;
      }
    }).map(c => {
      if (filter.days.length) {
        return { ...c, class_week: filter.days };
      } else {
        return c;
      }
    });
    setFilteredClasses(prepareFinalData(data));
  };
  const fetchClasses = async () => {
    startLoading();
    const response = await privateApi.get("/admin/class-routines?coach_id=" + filter.coach);
    const rawData = response.data.data;
    setAllClasses(rawData);
    handleFilter(filter, rawData);
    stopLoading();
  };
  const getCoaches = async () => {
    const response = await privateApi.get(`/admin/coaches`);
    if (response.status === 200) {
      setCoaches(response.data.data);
    } else {
      console.log("Server Error");
    }
  };
  useEffect(() => {
    getCoaches();
  }, []);
  useEffect(() => {
    fetchClasses();
  }, [filter.coach]);
  useEffect(() => {
    handleFilter(filter);
  }, [filter]);
  return (
    <div>
      <Typography.Title level={2}>クラス情報</Typography.Title>
      <Card>
        <Row gutter={[16]}>
          <Col span={8}>
            <Space style={{ width: "100%" }} direction='vertical'>
              <Typography.Text>クラス選択</Typography.Text>
              <Select defaultValue={""} style={{ width: "100%" }} options={[{ value: "", label: "すべて" }, ...allClasses.map(c => ({ value: c.class_name }))]}
                onChange={e => {
                  setFilter(f => ({ ...f, class: e }));
                }}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space style={{ width: "100%" }} direction='vertical'>
              <Typography.Text>コーチ選択</Typography.Text>
              <Select
                placeholder="すべて"
                style={{ width: "100%" }}
                options={[{ value: "", label: "すべて" }, ...coaches.map(c => ({ value: c.id, label: c.name }))]}
                onChange={e => setFilter(f => ({ ...f, coach: e }))}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col span={8}>
            <Space style={{ width: "100%", marginTop: "10px" }} direction='vertical'>
              <Typography.Text>日曜選択</Typography.Text>
              <Select mode='multiple' placeholder="すべて" style={{ width: "100%" }} options={Object.values(days).map(d => ({ value: d }))}
                onChange={e => {
                  setFilter(f => ({ ...f, days: e }));
                }}
              />
            </Space>
          </Col>
        </Row>
      </Card>
      {filteredClasses?.length ? <Collapse defaultActiveKey={['月']} expandIconPosition='end' style={{ marginTop: "10px" }} >
        {filteredClasses.map(clas => {
          return <Collapse.Panel header={<Typography.Title level={5}>{clas.name} クラス</Typography.Title>} key={clas.name}>
            {clas.items.length && Object.values(clas.items[0]).filter(Boolean).length ? <Table
              columns={columns.filter(c => {
                if (filter.days.length) {
                  return filter.days.includes(c.key);
                } else {
                  return true;
                }
              }).filter(column => {
                return clas.items[0][column.key]?.length;
              })}
              dataSource={clas.items}
              pagination={{
                hideOnSinglePage: true
              }}
              className='custom-table'
            /> : <Empty />}
          </Collapse.Panel>;
        })}
      </Collapse> : <Empty style={{ marginTop: "30px" }} />}

    </div>
  );
};

export default CourseMaintenanceNew;