import React, { useState, useEffect } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import MasterLayout from "../../layouts/student/MasterLayout";
import privateApi from "../../services/privateApi";
import { getSchool, getToken } from "../../services/auth/token";
import { UserProvider, useUser } from "../../context/userContext";

const StudentPrivateRoute = ({ ...rest }) => {
  const history = useHistory();

  const [Authenticated, setAuthenticated] = useState(false);
  const [loading, setloading] = useState(true);
  const location = useLocation();
  const { setUser } = useUser();

  const authChecker = async () => {

    const schoolSlug = getSchool() ? '/' + getSchool() : '';
    if (!getToken()) {
      setUser();
      history.push(schoolSlug + "/login/coach");
      return;
    }

    const response = await privateApi.get("member/me");

    if (response.status === 200) {
      setUser(response.data.data);
      setloading(false);
      setAuthenticated(true);
    } else {
      setUser();
      history.push(schoolSlug + "/login/student");
    }
  };

  useEffect(() => {
    localStorage.setItem("previousPathname", location.pathname);
  }, [location]);

  useEffect(() => {
    authChecker();
  }, []);

  if (loading) {
    return <></>;
  }
  return (
    <Route
      {...rest}
      render={({ props, location }) => (Authenticated ? <MasterLayout {...props} /> : <></>)}
    />
  );
};

export default StudentPrivateRoute;
