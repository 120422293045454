import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import privateApi from "../../services/privateApi";

import "../../assets/common/css/modal.css";
import swal from "sweetalert";

const Guardian = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [selectedGuardian, setSelectedGuardian] = useState();
  const [loading, setLoading] = useState("");

  const [errorList, setErrorList] = useState({});
  const [showGuardianDetails, setShowGuardianDetails] = useState(false);
  const filePro = useRef();
  const [file, setFile] = useState("");
  const [imagedata, setImagedata] = useState("");

  const [guardians, setGuardians] = useState([]);
  const [guardianInfo, setGuardianInfo] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    address: "",
    photo: "",
  });

  const handleInput = (e) => {
    e.persist();
    setGuardianInfo({ ...guardianInfo, [e.target.name]: e.target.value });
  };

  const handleChange = (file) => {
    setFile(URL.createObjectURL(file[0]));
    setImagedata(file[0]);
  };

  const updateModalShow = async (id) => {
    setSelectedGuardian(id);
    setShow2(true);
    const response = await privateApi.get(`admin/guardian/${id}`);
    if (response.status === 200) {
      setGuardianInfo(response.data.data);
      setFile(response.data.data.logo);
    } else {
      console.log("Server Error");
    }
  };

  const deleteStudent = async (id) => {
    const response = await privateApi.delete(`admin/guardian/${id}`);
    if (response.status === 200) {
      swal("学校情報が削除されました", "", "success");
      getSchoolData();
    } else {
      swal("学校情報が削除されました", "", "error");
      console.log("Server Error");
    }
  };

  const getSchoolData = async (e) => {
    const response = await privateApi.get("admin/guardians");
    if (response.status === 200) {
      setGuardians(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const setBlankField = () => {
    setGuardianInfo({});
    setErrorList({});
    setFile();
  };
  const toggleShowGuardianDetails = (id) => {
    setShowGuardianDetails(!showGuardianDetails);
    setSelectedGuardian(guardians.filter((guardian) => guardian.userId === id));
  };

  useEffect(() => {
    getSchoolData();
  }, []);

  const formSubmit = async (e, id) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("first_name", guardianInfo.first_name);
    formData.append("last_name", guardianInfo.last_name);
    formData.append("email", guardianInfo.email);
    formData.append("phone", guardianInfo.phone);
    formData.append("address", guardianInfo.address);
    formData.append("photo", imagedata);

    const response = await privateApi.post(`admin/guardian`, formData);

    if (response.status === 200) {
      setShow(false);
      swal("学校情報を追加しました", "", "success");
      getSchoolData();
      setBlankField();
      setLoading(false);
    } else {
      swal("内容に問題があります", response.data.message, "error");
      setErrorList(response.data.errors);
      setLoading(false);
    }
  };

  const updateFormSubmit = async (id) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("first_name", guardianInfo.first_name);
    formData.append("last_name", guardianInfo.last_name);
    formData.append("email", guardianInfo.email);
    formData.append("phone", guardianInfo.phone);
    formData.append("address", guardianInfo.address);
    formData.append("photo", imagedata);

    const response = await privateApi.formUpdate(`admin/guardian/${id}`, formData);

    if (response.status === 200) {
      setShow2(false);
      swal("学校情報を追加しました", "", "success");
      getSchoolData();
      setBlankField();
      setLoading(false);
    } else {
      swal("内容に問題があります", response.data.message, "error");
      setErrorList(response.data.errors);
      setLoading(false);
    }
  };

  const formData = (
    <div>
      <h5 className="mb-4">守護者の基本情報</h5>

      <div className="row">
        <Form.Group
          className="col-md-6 mb-3"
          controlId="first_name"
        >
          <Form.Label>
            苗字 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 苗字"
            value={guardianInfo.first_name}
            name="first_name"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="last_name"
        >
          <Form.Label>
            下の名前 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 下の名前"
            value={guardianInfo.last_name}
            name="last_name"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="phone"
        >
          <Form.Label>
            電話 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 電話"
            value={guardianInfo.phone}
            name="phone"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="email"
        >
          <Form.Label>
            メール <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 メール"
            value={guardianInfo.email}
            name="email"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="address"
        >
          <Form.Label>
            address <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 address"
            value={guardianInfo.address}
            name="address"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>ロゴ</Form.Label>
          <div>
            {file ? (
              <img
                src={file}
                height={150}
                width={150}
                className="avater-img-src"
                alt="logo"
              />
            ) : (
              ""
            )}
          </div>

          <div className="img-box">
            <input
              onChange={(e) => handleChange(e.target.files)}
              multiple={false}
              type="file"
              className="form-control"
            />
          </div>
        </Form.Group>
      </div>
    </div>
  );

  return (
    <>
      <div className="card px-2 py-3">
        <div className="row">
          <div className="col-10">
            <p className="headline">守護者一覧</p>
          </div>
          <div className="col-2 d-flex align-items-center">
            <button
              className="btn btn-sm btn-primary px-3 ml-auto"
              variant="primary"
              onClick={() => setShow(true)}
            >
              {" "}
              <i className="fas fa-plus fa-fw"></i>
              新しく追加する
            </button>
          </div>
        </div>
      </div>

      <div>
        <Table
          striped
          bordered
          hover
        >
          <thead>
            <tr>
              <th
                className="text-center"
                style={{ width: "5%" }}
              >
                金錢藝司
              </th>
              <th className="text-center">苗字</th>
              <th className="text-center">下の名前</th>
              <th className="text-center">電話</th>
              <th className="text-center">メール</th>
              <th className="text-center">住所</th>
              <th className="text-center">画像</th>
              <th className="text-center">操作</th>
            </tr>
          </thead>
          <tbody>
            {guardians.map((guardian, index) => (
              <tr key={index}>
                <td
                  onDoubleClick={() => toggleShowGuardianDetails(guardian?.userId)}
                  className="text-center"
                  style={{ width: "5%", cursor: "pointer" }}
                >
                  {guardian?.id}
                </td>
                <td className="text-center">{guardian?.first_name}</td>
                <td className="text-center">{guardian?.last_name}</td>
                <td className="text-center">{guardian?.phone}</td>
                <td className="text-center">{guardian?.email}</td>
                <td className="text-center">{guardian?.address}</td>
                <td className="d-flex justify-content-center">
                  <img
                    src={guardian?.photo}
                    alt="photo"
                    height="50"
                    width="50"
                    className="d-flex align-items-center"
                  />
                </td>

                <td className="text-center">
                  <button
                    className="btn btn-sm btn-primary mx-1"
                    onClick={() => updateModalShow(guardian.id)}
                  >
                    <FontAwesomeIcon icon="edit" />
                  </button>
                  <button
                    className="btn btn-sm btn-danger mx-1"
                    onClick={() => deleteStudent(guardian.id)}
                  >
                    <FontAwesomeIcon icon="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">新しい守護者を追加</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(errorList).length > 0 ? (
              <div
                className="alert alert-danger"
                role="alert"
              >
                <ul>
                  {Object.entries(errorList).map(([key, val], i) => (
                    <li key={i}>{val}</li>
                  ))}
                </ul>
              </div>
            ) : (
              ""
            )}

            {formData}
            <div>
              <>
                <Button
                  className="float-right mx-1 px-4"
                  variant="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={formSubmit}
                >
                  保存
                </Button>
              </>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show2}
          onHide={() => setShow2(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">守護者情報を更新</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(errorList).length > 0 ? (
              <div
                className="alert alert-danger"
                role="alert"
              >
                <ul>
                  {Object.entries(errorList).map(([key, val], i) => (
                    <li key={i}>{val}</li>
                  ))}
                </ul>
              </div>
            ) : (
              ""
            )}

            {formData}

            <div>
              <Button
                className="float-right mx-1 px-4"
                variant="primary"
                type="submit"
                style={{ float: "right" }}
                onClick={() => updateFormSubmit(setSelectedGuardian)}
              >
                保存
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Guardian;
