import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Modal, Form, Row, Col, Input, Flex, Select } from "antd";
import moment from 'moment';

const getShowNextBtn = (values, currentMember, all) => {
   const arrA = Object.keys(values).length ? [...Object.keys(values), currentMember] : [currentMember];
   const arrB = Array.from(new Set(all.map(c => c.member_code)));
   return arrB.length > arrA.length;
};
const getNextMember = (values, currentMember, all) => {
   const allMember = Array.from(new Set(all.map(c => c.member_code)));
   const arrA = Object.keys(values).length ? [...Object.keys(values), currentMember] : [currentMember];
   return allMember.find(memberCode => !arrA.includes(memberCode));
};

const ScheduleModal = ({ close, isOpen, data, onSubmit, currentSchedule }) => {
   const [currentClass, setCurrentClass] = useState();
   const [showNextBtn, setShowNextBtn] = useState(false);
   const [values, setValues] = useState({});
   const [form] = Form.useForm();
   const classId = Form.useWatch("from", form);
   const onFinish = (vals) => {
      console.log({ ...values, [currentClass.member_code]: vals });

   };
   const handleNext = async (e) => {
      try {
         await form.validateFields();
         const vals = form.getFieldsValue();
         setValues(values => ({ ...values, [currentClass.member_code]: vals }));
         const nextMember = getNextMember(values, currentClass.member_code, data,);
         setCurrentClass(data.find(d => d.member_code === nextMember));
      } catch (error) {
         console.log(error);
      }
   };
   const handleSkip = async (e) => {
      setValues(values => ({ ...values, [currentClass.member_code]: null }));
      const nextMember = getNextMember(values, currentClass.member_code, data,);
      setCurrentClass(data.find(d => d.member_code === nextMember));
   };
   useEffect(() => {
      if (currentSchedule) {
         setCurrentClass(currentSchedule);
         setShowNextBtn(getShowNextBtn(values, currentSchedule.member_code, data));
      }
   }, [currentSchedule]);
   useEffect(() => {
      if (currentClass) {
         setShowNextBtn(getShowNextBtn(values, currentClass.member_code, data));
         form.setFieldsValue({ ...currentClass, from: currentClass.id });
      }
   }, [currentClass]);
   useEffect(() => {
      if (classId) {
         setCurrentClass(data.find(d => d.id == classId));
      }
   }, [classId]);
   return (
      <Modal destroyOnClose onCancel={() => {
         form.resetFields();
         close();
      }} open={isOpen} title={"振替作成 " + currentClass?.member_name + " さん"} footer={null}>
         <Form form={form} layout='vertical' onFinish={onFinish} >
            <Row gutter={10}>
               <Col span={12}>
                  <Form.Item label="授業日" name={"from"}>
                     <Select options={data.filter(d => d.member_code == currentClass?.member_code).map(c => ({ value: c.id, label: `${c.period} (${c.time.day}) - ${c.time.time}` }))} />
                  </Form.Item>
               </Col>
               <Col span={12}>
                  <Form.Item rules={[{ required: "true" }]} label="振替日" name={"furikae_day"}>
                     <Select options={data.filter(d => d.member_code == currentClass?.member_code).map(c => ({ value: c.id, label: `${c.period} (${c.time.day}) - ${c.time.time}` }))} />
                  </Form.Item>
               </Col>
               <Col span={24}>
                  <Form.Item rules={[{ required: "true" }]} label="理由" name={"furikae_reason"}>
                     <Input.TextArea disabled={currentClass?.furikae_reason ? true : false} />
                  </Form.Item>
               </Col>
            </Row>
            <Flex justify='end' gap={5}>
               <Button htmlType='submit'>Submit</Button>
               {showNextBtn ? <>
                  <Button onClick={handleSkip}>Skip</Button>
                  <Button type='primary' onClick={handleNext}>Next</Button>
               </> : <></>}
            </Flex>
         </Form>
      </Modal>
   );
};

export default ScheduleModal;