import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import swal from "sweetalert";
import privateApi from "../../../services/privateApi";
import "./extraPageStyles.css";
import BackButton from "../../common/Button/BackButton";
import ActionButtonGroup from "../../common/Button/ActionButtonGroup";
import { useMediaQuery } from "react-responsive";
import { Resizable } from 'react-resizable';
import { Table } from 'antd';
import "react-resizable/css/styles.css";

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8", // Adjust the value to your desired background color
    },
  },
};

const MasterShortCourse = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1366 });
  const position = { position: "static", };
  const [paginationBtns, setPaginationBtns] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [classes, setClasses] = useState([]);
  const [classesYear, setClassesYear] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedOpening_Year, setselectedOpening_Year] = useState("");
  const [selectedOpeningSeason, setSelectedOpeningSeason] = useState("");
  const [selectedClassDropdown, setselectedClassDropdown] = useState("");
  const [courseCodeCounter, setCourseCodeCounter] = useState(1);
  const [AddBtnEnbl, SetAddBtnEnbl] = useState(true);
  const [UpdateBtnEnbl, SetUpdateBtnEnbl] = useState(false);
  const [DltBtnEnbl, SetDltBtnEnbl] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const getClasses = async () => {
    const response = await privateApi.get(
      `admin/short-term-classes?per_page=1000`);
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("サーバーの不具合");
    }
  };
  const getClassesYear = async (year) => {
    const response = await privateApi.get(
      `admin/short-term-classes?opening_year=${year}&?per_page=1000`);
    if (response.status === 200) {
      setClassesYear(response.data.data);
    } else {
      console.log("サーバーの不具合");
    }
  };
  const getClassesYearSeason = async (year, season) => {
    console.log(season);
    const response = await privateApi.get(
      `admin/short-term-classes?opening_year=${year}&?opening_season=${season}&?per_page=1000`);
    if (response.status === 200) {
      setClassesYear(response.data.data);

    } else {
      console.log("サーバーの不具合");
    }
    console.log(classesYear);
  };

  const getCourses = async (pageNumber) => {
    setLoader(true);
    const response = await privateApi.get(
      `admin/short-term-courses?page=${pageNumber}&per_page=100`
    );
    if (response.status === 200) {
      setLoader(false);
      if (response.data.data.length === 0) {
        setHasMore(false);
      } else {
        setCourses((prevCourse) => [...prevCourse, ...response.data.data]);
        setPageNumber(pageNumber + 1);
      }
    } else {
      console.log("サーバーの不具合");
    }
  };

  const [dataInput, setDataInput] = useState({
    opening_year: "",
    opening_season: "",
    short_term_class_id: "",
    course_code: "",
    course_name: "",
  });

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    setDataInput({
      ...dataInput,
      [name]: value,
    });
  };

  const handleInputCode = (e) => {
    e.persist();
    const { name, value } = e.target;
    setDataInput({
      ...dataInput,
      [name]: value,
    });
  };

  const handleChangeClass = (selectedClassId) => {
    console.log(selectedClassId);
    console.log(classesYear);
    const selectedClass = classesYear.find((c) => c.id === parseInt(selectedClassId));
    if (selectedClass) {
      setSelectedClass(selectedClass);
      setDataInput({
        ...dataInput,
        opening_year: selectedClass.opening_year,
        class_code: selectedClass.class_code,
        class_name: selectedClass.class_name,
        opening_season: selectedClass.opening_season,
      });
      console.log(selectedClass.class_name);
    }
  };

  const handleChangeOpening_Year = (selectedOpening_Year) => {
    setselectedOpening_Year(selectedOpening_Year);
    getClassesYear(selectedOpening_Year);
  };

  const handleChangeOpeningSeason = (selectedOpeningSeason) => {
    setSelectedOpeningSeason(selectedOpeningSeason);
    getClassesYearSeason(selectedOpening_Year, selectedOpeningSeason);
  };

  const handleChangeClassDropdown = (selectedClass) => {
    setselectedClassDropdown(selectedClass);
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    if (selectedClass) {
      const newDataInput = {
        ...dataInput,
        short_term_class_id: selectedClass.id,
        course_code: dataInput.course_code,
      };
      const response = await privateApi.post("admin/short-term-courses", newDataInput);
      if (response.status === 200) {
        swal("新しいクラスが追加されました", "", "success");
        setDataInput({
          opening_year: "",
          opening_season: "",
          class_code: "",
          course_code: "",
          course_name: "",
          short_term_class_id: "",
        });
        setSelectedClass(null);
        getCourses(1, 10);
      } else {
        swal("何かエラーが発生しました", "連絡してください", "error");
      }
    }
  };

  const getCourseInfo = async (id) => {
    const response = await privateApi.get("admin/short-term-courses/" + id);
    if (response.status === 200) {
      setDataInput(response.data.data);
    } else {
      console.log("サーバーの不具合");
    }
  };

  const updateHandler = async () => {
    const updatedData = {
      opening_year: dataInput.opening_year,
      opening_season: dataInput.opening_season,
      class_code: dataInput.class_code,
      course_code: dataInput.course_code,
      course_name: dataInput.course_name,
      short_term_class_id: dataInput?.short_term_class?.id
    };
    const response = await privateApi.update(`admin/short-term-courses/${selectedCourse}`, updatedData);
    if (response.status === 200) {
      swal("コースを更新しました", "", "success");
      getCourses();
      getCourseInfo(selectedCourse);

    } else {
      swal("何か問題が発生しました", "ご連絡ください", "error");
    }
  };

  const handleClick = (id) => {
    const selectedCourse = courses.find((course) => course.id === id);
    if (selectedCourse) {
      setSelectedCourse(id);
      setSelectedClass(selectedCourse.short_term_class?.id || "");
      setDataInput({
        ...dataInput,
        opening_year: selectedCourse.short_term_class?.opening_year || "",
        opening_season: selectedCourse.short_term_class?.opening_season || "",
        class_code: selectedCourse.short_term_class?.class_code || "",
        course_code: selectedCourse.course_code || "",
        course_name: selectedCourse.course_name || "",
        class_name: selectedCourse.short_term_class?.class_name || "",
        short_term_class_id: selectedCourse.short_term_class?.id || "",
      });
    }
    getCourseInfo(id);
    SetAddBtnEnbl(false); // Disable the Add button when selecting for update or delete
    SetUpdateBtnEnbl(true);
    SetDltBtnEnbl(true);
  };


  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const response = await privateApi.delete(`admin/short-term-courses/${selectedCourse}`);
    if (response.status === 200) {
      swal("クラスが削除されました", "", "success");
      getCourses();
    } else {
      swal("何かエラーが発生しました", "お問い合わせください", "error");
    }
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [columns, setColumns] = useState([
    {
      title: '网校年',
      dataIndex: 'short_term_class.opening_year',
      key: 'opening_year',
      width: 40,
      sorter: (a, b) => {
        const aValue = typeof a.short_term_class.opening_year === 'string' ? a.short_term_class.opening_year : String(a.short_term_class.opening_year);
        const bValue = typeof b.short_term_class.opening_year === 'string' ? b.short_term_class.opening_year : String(b.short_term_class.opening_year);
        return aValue.localeCompare(bValue);
      }, render: (text, record) => <span>{record.short_term_class.opening_year}</span>,
    },
    {
      title: '開校時期',
      dataIndex: 'short_term_class.opening_season',
      key: 'opening_season',
      width: 50,
      sorter: (a, b) => {
        const aValue = typeof a.short_term_class.opening_season === 'string' ? a.short_term_class.opening_season : String(a.short_term_class.opening_season);
        const bValue = typeof b.short_term_class.opening_season === 'string' ? b.short_term_class.opening_season : String(b.short_term_class.opening_season);
        return aValue.localeCompare(bValue);
      }, render: (text, record) => (
        <span>
          {record.short_term_class.opening_season === '1' ? '春' : record.short_term_class.opening_season === '2' ? '夏' : record.short_term_class.opening_season === '3' ? '冬' : ''}
        </span>
      ),
    },
    {
      title: 'クラス名',
      dataIndex: 'short_term_class.class_name',
      key: 'class_name',
      width: 80,
      sorter: (a, b) => {
        const aValue = a.short_term_class.class_name !== undefined ? a.short_term_class.class_name : '';
        const bValue = b.short_term_class.class_name !== undefined ? b.short_term_class.class_name : '';
        return aValue.localeCompare(bValue);
      },
      render: (text, record) => <span>{record.short_term_class.class_name}</span>,
    },
    {
      title: 'コード',
      dataIndex: 'course_code',
      key: 'course_code',
      width: 40,
      sorter: (a, b) => a.course_code.localeCompare(b.course_code),
    },
    {
      title: 'コース名',
      dataIndex: 'course_name',
      key: 'course_name',
      width: 120,
      sorter: (a, b) => a.course_name.localeCompare(b.course_name),
    },
  ]);


  const handleScroll = e => {
    const target = e.target;
    const distanceFromBottom =
      target.scrollHeight - target.scrollTop - target.clientHeight;
    if (distanceFromBottom <= 300 && hasMore && !loader) {
      getCourses(pageNumber);
    }
  };

  useEffect(() => {
    const container = document.querySelector(".ant-table-body");

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [pageNumber, hasMore, loader]);


  const handleResize =
    index =>
      (e, { size }) => {
        setColumns(columns => {
          const nextColumns = [...columns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          return nextColumns;
        });
      };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = (record, index) => {
    const key = record.id;
    setSelectedRowKey(prevSelectedRowKey =>
      prevSelectedRowKey === key ? null : key
    );
    handleClick(index, key);
  };


  const rowClassName = record => {
    return selectedRowKey === record.id ? "table-selected-row" : "";
  };

  useEffect(() => {
    const nextCourseCode = String(courses.length + 1);
    setDataInput((prevDataInput) => ({
      ...prevDataInput,
      course_code: nextCourseCode,
    }));
  }, [courses]);

  useEffect(() => {
    getClasses();
    getCourses();
  }, []);

  return (
    <div style={{ width: isMobile ? "100%" : (isTablet || isLaptop) ? "90%" : "40%" }}>
      <div className="card">
        <div className="d-flex align-items-center card-header">
          <p className="headline m-0">短期コースマスタ</p>
          <div className="ml-auto">
            <ActionButtonGroup
              storeHandler={formSubmit}
              deleteClasseHandler={handleDeleteClick}
              updateClasseHandler={updateHandler}
              AddBtnEnbl={AddBtnEnbl}
              DltBtnEnbl={DltBtnEnbl}
              UpdateBtnEnbl={UpdateBtnEnbl}
            />
          </div>
        </div>

        <div className="">
          <div className="container  m-2 col-12">
            <div
              className="row"
            >
              <div className="col-12">
                <div className="row mt-1">
                  <div className="col-md-3">
                    <Form.Label>開校年:</Form.Label>
                  </div>
                  <Form.Group
                    className="col-md-2"
                    controlId="opening_year"
                  >
                    <Form.Select size="sm"
                      name="opening_year"
                      onChange={(e) => handleChangeOpening_Year(e.target.value)}
                      className="shadow-sm"
                      value={dataInput.opening_year || selectedOpening_Year}
                    >
                      <option value="">開校年</option>
                      {Array.from(new Set(classes.map((st_class) => st_class.opening_year))).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}{" "}
                    </Form.Select>
                  </Form.Group>
                  <div className="col-md-2 d-flex justify-content-end">
                    <Form.Label>時期:</Form.Label>
                  </div>
                  <Form.Group
                    className="col-md-2"
                    controlId="family_member_id"
                  >
                    <Form.Select size="sm"
                      name="short_term_class_id"
                      onChange={(e) => handleChangeOpeningSeason(e.target.value)}
                      className="shadow-sm"
                      value={selectedOpeningSeason}
                    >
                      <option value="">時期</option>
                      {Array.from(new Set(classesYear.map((st_class) => st_class?.opening_season))).map((season) => (
                        <option key={season} value={season}>
                          {
                            season === '1'
                              ? '春'
                              : season === '2'
                                ? '夏'
                                : season === '3'
                                  ? '冬'
                                  : season}
                        </option>
                      ))}{" "}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="row mt-1">
                  <div className="col-md-3">
                    <Form.Label>クラス名:</Form.Label>
                  </div>
                  <Form.Group
                    className="col-md-4"
                    controlId="family_member_id"
                  >
                    <Form.Select size="sm"
                      name="short_term_class_id"
                      onChange={(e) => handleChangeClass(e.target.value)}
                      className="shadow-sm"
                      value={dataInput.class_name}
                    // Set the selected value to the state value
                    >
                      <option value="dataInput.class_name">{dataInput.class_name || "クラスの選択"} </option>
                      {classesYear.map((st_class) => (
                        <option
                          key={st_class.id}
                          value={st_class.id}
                        >
                          {st_class.class_name
                          }
                        </option>
                      ))}{" "}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="row mt-1">
                  <div className="col-md-3">
                    <p className="">開校年:</p>
                  </div>
                  <div className="col-md-2">
                    <input
                      className="form-control form-control-sm inputs_bg"
                      name="opening_year"
                      value={dataInput.opening_year}
                      readOnly
                    />
                  </div>
                  <div className="col-md-2">
                    <p className="mb-1">開校時期:</p>
                  </div>
                  <div className="col-md-2">
                    <input
                      className="form-control form-control-sm inputs_bg"
                      name="opening_season"
                      value={
                        dataInput.opening_season === '1'
                          ? '春'
                          : dataInput.opening_season === '2'
                            ? '夏'
                            : dataInput.opening_season === '3'
                              ? '冬'
                              : dataInput.opening_season
                      }
                      readOnly
                    />
                  </div>
                </div>

                <div
                  className="row"
                >
                  <div
                    className="col-md-3"
                  >
                    <p>クラスコード</p>
                  </div>
                  <div className="col-md-2 ltr">
                    <input
                      className="form-control form-control-sm inputs_bg"
                      name="class_code"
                      value={dataInput.class_code}
                      readOnly
                    />
                  </div>

                  <div className="col-md-2">
                    <p className="mb-1">クラス名:</p>
                  </div>
                  <div className="col-5">
                    <input
                      className="form-control form-control-sm inputs_bg"
                      name="class_name"
                      value={dataInput.class_name}
                      readOnly
                    />
                  </div>
                </div>

                <div
                  className="row"
                >
                  <div
                    className="col-md-3"
                  >
                    <p className="mb-1">コースコード:</p>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      name="course_code"
                      value={dataInput.course_code}
                      onChange={handleInputCode}
                    />
                  </div>
                  <div className="col-md-2">
                    <p className="mb-1">コース名:</p>
                  </div>
                  <div className="col-md-5">
                    <input
                      className="form-control form-control-sm"
                      name="course_name"
                      value={dataInput.course_name}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table bordered
          className="table-striped-rows"
          loading={loader}
          dataSource={courses}
          columns={resizableColumns}
          pagination={false}
          components={components}
          rowKey="id"
          scroll={{ x: 400, y: "50vh" }}
          rowClassName={rowClassName}
          onRow={(record) => ({
            onClick: () => handleClick(record.id),
          })}
        />
        <div className="row mt-2 ">
          <div className="col-md-10 d-flex justify-content-center">
            <div className="d-flex mx-auto neomp">
            </div>
          </div>
          <div className="col-md-2 d-flex justify-content-end">
            <BackButton redirectTo="/admin/stc/dashboard" position={position} />
          </div>
        </div>

      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
      >
        {selectedCourse ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>削除の確認</Modal.Title>
            </Modal.Header>
            <Modal.Body>削除してもよろしいですか</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseModal}
              >
                キャンセル
              </Button>
              <Button
                variant="danger"
                onClick={handleConfirmDelete}
              >
                削除
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <Modal.Body>
            <h4 className="text-center text-danger py-3">任意のクラスを選択</h4>
          </Modal.Body>
        )}{" "}
      </Modal>
    </div>
  );
};

export default MasterShortCourse;
