export default function changeDateFormat(inputDate) {
  const dateObject = new Date(inputDate);

  // Checking if the input date is valid
  if (isNaN(dateObject.getTime())) {
    console.error("Invalid date format");
    return null;
  }

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(dateObject.getDate()).padStart(2, "0");
  // formatting
  const newDateFormat = `${year}/${month}/${day}`;
  return newDateFormat;
}
