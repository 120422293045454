import { dispatchEvent } from "../event/eventBus";

const ID_TOKEN_KEY = "api_token";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  }
};

export const getData = () => {
  if (typeof window !== "undefined") {
    const userDataString = window.localStorage.getItem("user_data");
    return JSON.parse(userDataString);
  }
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveCurrentMonth = date => {
  window.localStorage.setItem("current_month", date);
};

export const getCurrentMonth = () => {
  if (typeof window !== "undefined") {
    const storedDate = window.localStorage.getItem("current_month");

    // If the stored date exists
    if (storedDate) {
      const [year, month] = storedDate.split("-"); // Splitting the string into year and month
      const selectedDate = new Date(year, parseInt(month) - 1); // Month is zero-based in JavaScript Date

      return selectedDate;
    }
  }

  // Return null if the stored date doesn't exist or if window is undefined
  return null;
};

export const saveData = data => {
  window.localStorage.setItem("user_data", data);
  dispatchEvent("userDataChanged", data);
};

export const saveSchool = slug => {
  window.localStorage.setItem("school_slug", slug);
  dispatchEvent("schoolSlugChanged", slug);
};

export const getSchool = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem("school_slug");
  }
};

export const deleteSchool = () => {
  window.localStorage.removeItem("school_slug");
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem("user_data");
  window.localStorage.removeItem("otp_time");
};

const localStorageFunctions = {
  getToken,
  saveToken,
  getData,
  saveData,
  destroyToken,
  saveSchool,
  getSchool,
  deleteSchool,
};

export default localStorageFunctions;
