import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Tab, Table, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { differenceInDays, differenceInYears, format, parse } from "date-fns";
import swal from "sweetalert";
import ReactSelect from "react-select";
import privateApi from "../../../services/privateApi";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import "../../../assets/global.css";
import Option2 from "../../extrapage/Page19/Options2";
import Option3 from "../../extrapage/Page19/Options3";
import Nvh from "../../common/Nvh";

import "./Student.css";
import ConvertTo from "../../utilis/ConvertKataka";
import moment from "moment";
import HalfWidthKatakana from "../../common/HalfWidthKatakana/converter";
import { getCurrentMonth } from "../../../services/auth/token";
import { convertToHalfWidthFunction } from "../../common/HalfWidthKatakana/convertToKana";

function CreateStudent() {
  const history = useHistory();
  const addressListRef = useRef();

  const [userId, setUserId] = useState();
  const excludedUserIds = ["tondabayashi", "abiko"];
  const mailHide = ["abiko"];
  const genderHide = ["abiko"];

  const [errorList, setErrorList] = useState({});
  const [loading, setLoading] = useState("");
  const [optionLoading, setOptionLoading] = useState(false);
  const [areaLoading, setAreaLoading] = useState(false);
  const [startDate, setStartDate] = useState("2000-01-01");
  const [joiningDate, setJoiningDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [leaveDate, setLeaveDate] = useState();
  const [guardianOptions, setGuardianOptions] = useState([]);
  const [buses, setBuses] = useState([]);
  const [banks, setBanks] = useState([]);
  const [memberCode, setMemberCode] = useState();
  const [guradianFieldShow, setGuradianFieldShow] = useState(false);
  const [enableTabs, setEnableTabs] = useState(false);
  const [showSugg, setShowSugg] = useState(false);
  const [students, setStudents] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [readOnly, setReadOnly] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [noticeDetails, setNoticeDetails] = useState([]);

  const [selectedGuardian, setSelectedGuardian] = useState([]);
  const [bankId, setBankId] = useState();

  const [key, setKey] = useState("tab1");

  let inputFields = {
    type: "Regular",
    school_id: "",
    name: "",
    furigana: "",
    email: "",
    password: "123456",
    gender: "",
    birthday: startDate,
    district_code: "Default",
    zip_code: "",
    address_1: "",
    address_2: "",
    parent_name: "",
    phone_no_1: "",
    phone_note_1: "",
    phone_no_2: "",
    phone_note_2: "",
    mail_address_1: "",
    mail_category_1: "",
    mail_note_1: "",
    mail_address_2: "",
    mail_category_2: "",
    mail_note_2: "",
    mail_address_3: "",
    mail_category_3: "",
    mail_note_3: "",
    charge_type_code: "通常",
    payment_type_code: "新規",
    deposit_item: "",
    account_no: "",
    account_holder: "",
    note: "",
    notice: "",
    bus_course_number: "",
    outbound_bus: "",
    return_bus: "",
    created_by: "",
    status: "",
    password_confirmation: "123456",
    relation: "",
    joining_date: joiningDate,
    leave_date: "",
    bank_code: "",
    branch_code: "",
    em_first_name: "",
    em_first_name_furigana: "",
    em_last_name: "",
    em_last_name_furigana: "",
    em_phone_no: "",
    guardian_name: "",
    guardian_furigana: "",
  };

  const [deposite, setDeposite] = useState({
    id: "",
    member_id: "",
    account_number: "",
    processed_date: "",
    billing_amount: "",
    transfer_invoice_amount: "",
    transfer_amount: "",
    transfer_deposit_date: "",
    cash_deposit_amount: "",
    cash_deposit_date: "",
    excess_deficiency: "",
    cumulative_excess_deficiency: "",
  });

  const [newStudents, setNewStudents] = useState(inputFields);
  const [savedStudents, setSavedStudents] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const [convertedContent, setConvertedContent] = useState();
  const [addressOptions, setAddressOptions] = useState([]);
  const [age, setAge] = useState(null);
  const [classLevel, setClassLevel] = useState(null);
  const [page, setPage] = useState(1);

  const handleInput = e => {
    e.persist();
    setErrorList({});

    setNewStudents(prevStudents => {
      const updatedStudents = {
        ...prevStudents,
        [e.target.name]: e.target.value,
      };

      if (e.target.name === "bank_code" && updatedStudents.type === "Regular") {
        let pType = updatedStudents.payment_type_code;

        if (pType === "新規" && e.target.value !== "") {
          pType = "振替";
          updatedStudents.payment_type_code = pType;
        }
      }

      return updatedStudents;
    });
  };

  //Image Upload Handler
  const [file, setFile] = useState("");
  const [imagedata, setImagedata] = useState("");
  const handleChange = file => {
    setFile(URL.createObjectURL(file[0]));
    setImagedata(file[0]);
  };

  const setBlankField = () => {
    setFile();
    setImagedata("");
    setNewStudents(inputFields);
    getGenerelRes();
  };

  const calculateAge = birthdate => {
    if (!birthdate) {
      setAge(null);
      return;
    }

    const today = new Date();
    const birthDate = parse(birthdate, "yyyy-MM-dd", new Date());
    const diffInDays =
      differenceInDays(today, birthDate) -
      Math.floor(differenceInDays(today, birthDate) / 365 / 4);

    const years = Math.floor(diffInDays / 365);
    // const months = Math.floor((diffInDays % 365) / 30);
    // const days = diffInDays - years * 365 - months * 30;

    //calculate which class
    var year_now = today.getFullYear();
    var month_now = today.getMonth();
    if (month_now == "0" || month_now == "1" || month_now == "2") {
      year_now = Math.floor(year_now - 1);
    }
    const april_day = new Date(year_now + "-04-01");

    const years_for_class = differenceInYears(april_day, birthDate);

    var class_of_the_student = "";

    if (years_for_class == "6") {
      class_of_the_student = "小1";
    } else if (years_for_class == "7") {
      class_of_the_student = "小2";
    } else if (years_for_class == "8") {
      class_of_the_student = "小3";
    } else if (years_for_class == "9") {
      class_of_the_student = "小4";
    } else if (years_for_class == "10") {
      class_of_the_student = "小5";
    } else if (years_for_class == "11") {
      class_of_the_student = "小6";
    } else if (years_for_class == "12") {
      class_of_the_student = "中1";
    } else if (years_for_class == "13") {
      class_of_the_student = "中2";
    } else if (years_for_class == "14") {
      class_of_the_student = "中3";
    }

    setAge(`${years} 才`);
    setClassLevel(`${class_of_the_student}`);
  };

  const handleDateChange = date => {
    if (date) {
      setStartDate(date);
      setNewStudents({ ...newStudents, birthday: date });
      calculateAge(date);
    }
  };

  //Guradian Input Handler
  const [familyMembers, setFamilyMembers] = useState([]);

  useEffect(() => {
    // Initialize form data when selectedGuardian changes
    const initialFormData = selectedGuardian.map(guard => {
      const existingData = familyMembers.find(
        data => data.value === guard.value
      );
      return existingData || { relation: "", value: guard.value };
    });
    setFamilyMembers(initialFormData);
  }, [selectedGuardian]);

  const handleInputChange = (index, event) => {
    const updatedFormData = [...familyMembers];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [event.target.name]: event.target.value,
    };
    setFamilyMembers(updatedFormData);
    console.log("object1", updatedFormData);
  };

  useEffect(() => {
    console.log("object", familyMembers);
  }, [familyMembers]);

  // Add Student
  const addNewStudent = async status => {
    setLoading(true);

    const formData = new FormData();

    for (const key in newStudents) {
      formData.append(key, newStudents[key]);
    }

    formData.append(
      "mail_address_1",
      newStudents.mail_address_1 ? newStudents.mail_address_1 : ""
    );
    formData.append("member_code", memberCode);
    formData.append("guardian_photo", imagedata);
    formData.append("note", convertedContent ? convertedContent : "");
    formData.append("notice", noticeDetails ? noticeDetails : "");
    formData.append("family_members", JSON.stringify(familyMembers));
    formData.append("bank_code", bankId);

    if (newStudents.type != "Regular") {
      formData.append("charge_type_code", "");
      formData.append("payment_type_code", "");
      formData.append("bank_code", "");
      formData.append("branch_code", "");
      formData.append("deposit_item", "");
      formData.append("account_no", "");
      formData.append("account_holder", "");
    }

    if (newStudents.leave_date != null) {
      formData.append("leave_date", newStudents.leave_date);
    } else {
      formData.append("leave_date", "");
    }

    if (newStudents.joining_date != null) {
      formData.append("joining_date", newStudents.joining_date);
    } else {
      formData.append("joining_date", "");
    }


    const response = await privateApi.post("admin/members", formData);

    if (
      newStudents.password !== newStudents.confirmPassword &&
      response.status === 200
    ) {
      setSavedStudents(response.data.data);
      setLoading(false);
      setBlankField();
      setShowValidation(false);
      if (status === 1) {
        if (newStudents.type === "Regular") {
          history.push("/admin/student");
        } else {
          history.push("/admin/stc/short-term-student-list");
        }
      } else {
        history.push(
          `/admin/student/details/${response.data.data.id}?action=update&key=tab2`
        );
      }

      swal("作成しました！", "新しい会員を登録しました!", "success");
    } else if (response.status === 422) {
      setLoading(false);
      setShowValidation(true);
      setErrorList(response.data.errors);
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const getGuardianlist = async () => {
    const response = await privateApi.get(
      `admin/get-members-list?type=Regular`
    );
    if (response.status === 200) {
      setGuardianOptions(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const guardians = guardianOptions.map(data => ({
    value: data.id,
    label: data.member_code + "-" + data.name,
  }));

  const getGenerelRes = async e => {
    const month = getCurrentMonth();

    const response = await privateApi.get(
      `admin/general-resources?member_type=${newStudents?.type}&type=bus,bank,member_code&processed_date=${moment(month).format("YYYY-MM")}`
    );
    if (response.status === 200) {
      setBuses(response.data.bus);
      setBanks(response.data.bank);
      setMemberCode(response.data.member_code);
    } else {
      console.log("Server Error");
    }
  };

  const bankHandler = (bank_code, branch_code, branch_name) => {
    setNewStudents({
      ...newStudents,
      bank_code: bank_code,
    });
  };

  const getStudents = async () => {
    setOptionLoading(true);
    const response = await privateApi.get(`admin/get-members-list?type=All`);
    if (response.status === 200) {
      setOptionLoading(false);
      setStudents(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const studentOptions3 = students.map(student => ({
    value: student.id,
    label: student.member_code + " - " + student.name + "-" + student.furigana,
  }));

  const getStudentInfo = async id => {
    const response = await privateApi.get("admin/members/" + id);

    if (response.status === 200) {

      console.log('object', response.data.data)

      setNewStudents({
        ...response.data.data,
        member_code: memberCode,
        password: "123456",
        password_confirmation: "123456",
        joining_date: joiningDate,
      });

      setBankId(response.data.data?.bank?.id);
      setStartDate(response.data.data.birthday);
      calculateAge(response?.data?.data.birthday);
      setJoiningDate(moment(new Date()).format("YYYY-MM-DD"));
      setLeaveDate(response.data.data.leave_date);
    } else {
      console.log("Server Error");
    }
  };

  const handleStudentChange = selectedOption => {
    getStudentInfo(selectedOption.value);
  };

  useEffect(() => {
    getGuardianlist();
    getGenerelRes();
    getStudents();
    handleDateChange(startDate);
  }, []);

  useEffect(() => {
    getGenerelRes();
  }, [newStudents.type]);

  const isFetching = useRef(false);

  const fetchAddressOptions = async (input, currentPage) => {
    if (currentPage > 0) {
      setAreaLoading(true);
    }

    if (isFetching.current) return;
    isFetching.current = true;

    try {
      const response = await privateApi.post(
        `admin/city-search?page=${currentPage + 1}`,
        input
      );
      if (response.status === 200) {
        const newOptions = await response.data.data;
        setAddressOptions(prevOptions => [...prevOptions, ...newOptions]);
        setPage(currentPage + 1);
        setAreaLoading(false);
        setShowSugg(true);
      }
    } catch (error) {
      console.error("Error fetching address options:", error);
    } finally {
      isFetching.current = false;
    }

    setAreaLoading(false);
  };

  const zipCodeHandler = value => {
    setPage(1);
    setAddressOptions([]);
    const data = {
      postal_code: value,
      per_page: 15,
    };
    fetchAddressOptions(data, 0);
  };

  const handleScroll = () => {
    const ulElement = document.querySelector(".list-box");
    if (ulElement) {
      const isScrolledToBottom =
        ulElement.scrollHeight - ulElement.clientHeight <=
        ulElement.scrollTop + 1;
      if (isScrolledToBottom) {
        const data = {
          postal_code: newStudents.zip_code,
          per_page: 5,
        };
        fetchAddressOptions(data, page);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [newStudents.zip_code, page]);

  useEffect(() => {
    const ulElement = document.querySelector(".list-box");
    if (ulElement) {
      ulElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ulElement) {
        ulElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [newStudents.zip_code, page]);

  const addressHandler = value => {
    setShowSugg(false);
    setNewStudents({
      ...newStudents,
      address_1: value.city + value.address,
      zip_code: value.postal_code,
    });
  };

  const handleKeyDown = e => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex(prevIndex =>
        prevIndex < addressOptions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      e.preventDefault();
      addressHandler(addressOptions[highlightedIndex]);
    }

    if (addressListRef.current) {
      const itemHeight = 50;
      const scrollTarget =
        highlightedIndex * itemHeight - addressListRef.current.clientHeight / 2;

      const animateScroll = time => {
        const currentTime = time || 0;
        const duration = 500;
        const progress = Math.min(1, currentTime / duration);
        if (addressListRef.current) {
          addressListRef.current.scrollTop =
            addressListRef.current.scrollTop +
            (scrollTarget - addressListRef.current.scrollTop) * progress;

          if (progress < 1) {
            window.requestAnimationFrame(animateScroll);
          }
        }
      };

      window.requestAnimationFrame(animateScroll);
    }
  };

  const convertAndSetFurigana = async (target_field, given_field, type) => {
    try {
      const convertedText = await ConvertTo(newStudents[given_field], type);
      if (convertedText) {
        setNewStudents({
          ...newStudents,
          [`${target_field}`]: convertedText,
        });
      } else {
        setNewStudents({
          ...newStudents,
          [`${target_field}`]: "",
        });
      }
    } catch (error) {
      console.error(`Error converting ${given_field}`, error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.name) {
        convertAndSetFurigana("furigana", "name", "katakana");
      } else {
        setNewStudents({
          ...newStudents,
          furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.name]);

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.em_first_name) {
        convertAndSetFurigana(
          "em_first_name_furigana",
          "em_first_name",
          "katakana"
        );
      } else {
        setNewStudents({
          ...newStudents,
          em_first_name_furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.em_first_name]);

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.em_last_name) {
        convertAndSetFurigana(
          "em_last_name_furigana",
          "em_last_name",
          "katakana"
        );
      } else {
        setNewStudents({
          ...newStudents,
          em_last_name_furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.em_last_name]);

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.guardian_name) {
        convertAndSetFurigana("guardian_furigana", "guardian_name", "katakana");
      } else {
        setNewStudents({
          ...newStudents,
          guardian_furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.guardian_name]);

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [addressOptions]);

  const [menuIsOpen, setMenuIsOpen] = useState();

  const customFilterOption = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const onInputChange = (inputValue, { action, prevInputValue }) => {
    if (action === "input-change") return inputValue;
    if (action === "menu-close") {
      if (prevInputValue) setMenuIsOpen(true);
      else setMenuIsOpen(undefined);
    }
    return prevInputValue;
  };

  useEffect(() => {
    const userData = localStorage.getItem("school_slug");
    if (userData) {
      setUserId(userData);
    }
  }, []);

  const formSection = (
    <span
      className={`${showValidation ? "was-validated" : "needs-validation"}`}
    >
      <div className="row">
        <div className="col-md-6">
          <div className="form-group mb-2 row mb-2">
            <label className="col-sm-3 col-form-label text-sm-end mt-2">
              会員ーを検索: <span className="text-white">-</span>
            </label>
            <div className="col-sm-9">
              <ReactSelect
                className="shadow-sm"
                options={studentOptions3}
                onChange={handleStudentChange}
                isLoading={optionLoading}
              />
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              会員ータイプ: <span className="required">*</span>
            </label>
            <div className="col-sm-3 pt-2">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="validationFormCheck2"
                  name="type"
                  onChange={handleInput}
                  value="Regular"
                  checked={newStudents.type === "Regular"}
                  required
                />
                <label
                  className="form-check-label"
                  htmlFor="validationFormCheck2"
                >
                  通常
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="validationFormCheck2"
                  name="type"
                  onChange={handleInput}
                  value="Short Term"
                  checked={newStudents.type === "Short Term"}
                  required
                />
                <label
                  className="form-check-label"
                  htmlFor="validationFormCheck2"
                >
                  短期
                </label>
              </div>

              <div className="invalid-feedback">
                {errorList?.type ? errorList?.type : ""}
              </div>
            </div>

            <label className="col-sm-3 col-form-label text-sm-end">
              会員番号: <span className="text-white">-</span>
            </label>
            <div className="col-sm-3">
              <input
                type="text"
                placeholder="000"
                name="member_code"
                onChange={e => setMemberCode(e.target.value)}
                value={memberCode}
                className={`form-control ${errorList?.member_code ? "is-invalid" : ""
                  }`}
                required
              />
              <div className="invalid-feedback">
                {errorList?.member_code ? errorList?.member_code : ""}
              </div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              名前: <span className="required">*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="名前"
                name="name"
                onChange={handleInput}
                value={newStudents.name}
                className={`form-control ${errorList?.name ? "is-invalid" : ""
                  }`}
                required
              />
              <div className="invalid-feedback">
                {errorList?.name ? errorList?.name : ""}
              </div>
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              フリガナ: <span className="required">*</span>
            </label>
            <div className="col-sm-3">
              <input
                type="text"
                placeholder="フリガナ"
                name="furigana"
                onChange={handleInput}
                value={newStudents.furigana}
                className={`form-control ${errorList?.furigana ? "is-invalid" : ""
                  }`}
                required
              />
              <div className="invalid-feedback">
                {errorList?.furigana ? errorList?.furigana : ""}
              </div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end mt-2">
              性別: <span className="required">*</span>
            </label>
            <div className="col-sm-4 pt-2">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="invalidCheck"
                  name="gender"
                  onChange={handleInput}
                  value="男"
                  checked={newStudents.gender === "男"}
                  required
                />
                <label
                  className="form-check-label"
                  htmlFor="validationFormCheck2"
                >
                  男
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="validationFormCheck2"
                  name="gender"
                  onChange={handleInput}
                  value="女"
                  checked={newStudents.gender === "女"}
                  required
                />
                <label
                  className="form-check-label"
                  htmlFor="validationFormCheck2"
                >
                  女
                </label>
              </div>

              {!genderHide.includes(userId) && (
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="validationFormCheck2"
                    name="gender"
                    onChange={handleInput}
                    value="未"
                    checked={newStudents.gender === "未"}
                    required
                  />

                  <label
                    className="form-check-label"
                    htmlFor="validationFormCheck2"
                  >
                    未
                  </label>
                </div>
              )}

              <div className="invalid-feedback">
                {errorList?.gender ? errorList?.gender : ""}
              </div>
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              生年月日: <span className="required">*</span>
            </label>
            <div className="col-sm-3 ">
              <div className="">
                <input
                  type="date"
                  className={`form-control w-100 ${errorList?.birthday ? "is-invalid" : ""
                    }`}
                  value={startDate}
                  required
                  onChange={e => {
                    setStartDate(e.target.value);
                    calculateAge(e.target.value);
                    setNewStudents({
                      ...newStudents,
                      birthday: e.target.value,
                    });
                  }}
                  max="3090-12-31"
                />
                <div className="small text-danger">
                  {errorList?.birthday ? errorList?.birthday : ""}
                </div>
              </div>

              <div className="age-view">
                <div className="text-success">
                  {age} &nbsp;&nbsp; {classLevel}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              入会日: <span className="text-white">-</span>
            </label>
            <div className="col-sm-3">
              <input
                type="date"
                className="w-100 form-control pointer"
                value={joiningDate ? joiningDate : ""}
                onChange={e => {
                  setJoiningDate(e.target.value);
                  setNewStudents({
                    ...newStudents,
                    joining_date: e.target.value,
                  });
                }}
                max="3090-12-31"
              />
            </div>

            <label className="col-sm-3 col-form-label text-sm-end">
              退校日: <span className="text-white">-</span>
            </label>
            <div className="col-sm-3">
              <input
                type="date"
                className="w-100 form-control pointer"
                value={leaveDate ? leaveDate : ""}
                onChange={e => {
                  setLeaveDate(e.target.value);
                  setNewStudents({
                    ...newStudents,
                    leave_date: e.target.value,
                  });
                }}
                max="3090-12-31"
              />
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              郵便番号: <span className="required">*</span>
            </label>
            <div className="col-sm-4">
              <div className="select-wrapper">
                <input
                  type="text"
                  placeholder="郵便番号"
                  autoComplete="off"
                  name="zip_code"
                  onChange={e => {
                    setNewStudents({
                      ...newStudents,
                      zip_code: e.target.value,
                      address_1: "",
                    });
                    zipCodeHandler(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                  value={newStudents.zip_code}
                  className={`form-control ${errorList?.zip_code ? "is-invalid" : ""
                    }`}
                  required
                />

                {addressOptions.length > 0 && showSugg ? (
                  <ul
                    ref={addressListRef}
                    className="shadow-sm card list-box"
                    style={{
                      listStyle: "none",
                      maxHeight: "200px",
                      overflowY: "auto",
                      padding: "0px",
                    }}
                  >
                    {addressOptions.map((add, index) => (
                      <li
                        className={`area-list-item ${highlightedIndex === index ? "highlighted" : ""
                          }`}
                        key={index}
                        onClick={() => addressHandler(add)}
                      >
                        {add.postal_code} ({add.city} {add.address})
                      </li>
                    ))}

                    {areaLoading && <li className="text-center">...</li>}
                  </ul>
                ) : (
                  ""
                )}
              </div>

              <div className="invalid-feedback">
                {errorList?.zip_code ? errorList?.zip_code : ""}
              </div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              住所1: <span className="required">*</span>
            </label>
            <div className="col-sm-5">
              <input
                type="text"
                placeholder="住所 1"
                name="address_1"
                onChange={handleInput}
                value={Nvh({ value: newStudents.address_1 })}
                className={`form-control ${errorList?.address_1 ? "is-invalid" : ""
                  }`}
                required
              />
              <div className="invalid-feedback">
                {errorList?.address_1 ? errorList?.address_1 : ""}
              </div>
            </div>

            <label className="col-sm-1 col-form-label text-sm-end">
              住所2:<span className="text-white">-</span>
            </label>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="住所 2"
                name="address_2"
                onChange={handleInput}
                value={Nvh({ value: newStudents.address_2 })}
              />
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              電話1: <span className="required">*</span>
            </label>
            <div className="col-sm-3">
              <input
                type="text"
                placeholder="電話番号1"
                name="phone_no_1"
                onChange={handleInput}
                value={Nvh({ value: newStudents.phone_no_1 })}
                className={`form-control ${errorList?.phone_no_1 ? "is-invalid" : ""
                  }`}
                required
              />
              <div className="invalid-feedback">
                {errorList?.phone_no_1 ? errorList?.phone_no_1 : ""}
              </div>
            </div>

            <label className="col-sm-3 col-form-label text-sm-end">
              備考:<span className="text-white">-</span>
            </label>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="電話備考1"
                name="phone_note_1"
                onChange={handleInput}
                value={Nvh({ value: newStudents.phone_note_1 })}
              />
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              電話2: <span className="text-white">-</span>
            </label>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="電話番号2"
                name="phone_no_2"
                onChange={handleInput}
                value={Nvh({ value: newStudents.phone_no_2 })}
              />
            </div>

            <label className="col-sm-3 col-form-label text-sm-end">
              備考:<span className="text-white">-</span>
            </label>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder="電話備考2"
                name="phone_note_2"
                onChange={handleInput}
                value={Nvh({ value: newStudents.phone_note_2 })}
              />
            </div>
          </div>

          {!mailHide.includes(userId) && (
            <>
              <div className="form-group mb-2 row">
                <label className="col-sm-3 col-form-label text-sm-end">
                  メール1: <span className="text-white">-</span>
                </label>
                <div className="col-sm-4">
                  <input
                    type="email"
                    placeholder="メールアドレス_1"
                    name="mail_address_1"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.mail_address_1 })}
                    className={`form-control ${errorList?.mail_address_1 ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">
                    {errorList?.mail_address_1 ? errorList?.mail_address_1 : ""}
                  </div>
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  備考:<span className="text-white">-</span>
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="メール備考1"
                    name="mail_note_1"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.mail_note_1 })}
                  />
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-3 col-form-label text-sm-end">
                  メール2: <span className="text-white">-</span>
                </label>
                <div className="col-sm-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="メールアドレス_2"
                    name="mail_address_2"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.mail_address_2 })}
                  />
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  備考:<span className="text-white">-</span>
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="メール備考2"
                    name="mail_note_2"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.mail_note_2 })}
                  />
                </div>
              </div>
            </>
          )}

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              バスコース番号: <span className="text-white">-</span>
            </label>

            <div className="col-sm-4">
              <select
                className="form-control"
                placeholder="バスコース番号"
                name="bus_course_number"
                onChange={handleInput}
                value={newStudents.bus_course_number}
              >
                <option value="">バスの選択</option>
                {buses.map((bus, i) => (
                  <option key={i} value={bus.id}>
                    {bus.bus_course_number} ({bus.stop_name})
                  </option>
                ))}
              </select>
            </div>

            <div className="col-5 row p-0">
              <label className="col-2 col-form-label text-sm-end">
                往路:<span className="text-white">-</span>
              </label>
              <div className="col-2 p-0">
                <input
                  type="number"
                  className="form-control"
                  name="outbound_bus"
                  onChange={handleInput}
                  value={Nvh({ value: newStudents.outbound_bus })}
                />
              </div>
              <div className="col-2 sz-15 p-0">&nbsp;人</div>
              <label className="col-sm-2 col-form-label text-sm-end">
                復路:<span className="text-white">-</span>
              </label>
              <div className="col-sm-2 p-0">
                <input
                  type="number"
                  className="form-control"
                  name="return_bus"
                  onChange={handleInput}
                  value={Nvh({ value: newStudents.return_bus })}
                />
              </div>
              <div className="col-2 sz-15 p-0">&nbsp;人</div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              家族会員: <span className="text-white">-</span>
            </label>
            <div className="col-sm-9">
              <ReactSelect
                className="shadow-sm"
                value={selectedGuardian}
                placeholder="選択"
                options={guardians}
                onChange={setSelectedGuardian}
                closeMenuOnSelect={false}
                isMulti
                hideSelectedOptions={false}
                onInputChange={onInputChange}
                menuIsOpen={menuIsOpen}
                filterOption={customFilterOption}
              />
            </div>
          </div>

          {selectedGuardian && (
            <div className="form-group mb-2 row">
              <label className="col-sm-3 col-form-label text-sm-end"></label>
              <div className="table-responsive col-sm-9">
                <Table striped bordered hover>
                  <tbody>
                    {selectedGuardian.map((guard, i) => (
                      <tr key={i}>
                        <td>{guard.label}</td>
                        <td>
                          <input
                            type="text"
                            placeholder="関係"
                            className="form-control"
                            name="relation"
                            value={familyMembers[i]?.relation || ""}
                            onChange={event =>
                              handleInputChange(i, event, guard.value)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}

          {guradianFieldShow ? (
            <div className="">
              <div className="row bg-light py-2">
                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_first_name"
                >
                  <Form.Label>保護者の名前</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="保護者の名前"
                    name="guardian_first_name"
                    onChange={handleInput}
                    required
                    value={newStudents.guardian_first_name}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_last_name"
                >
                  <Form.Label>保護者の姓</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="保護者の姓"
                    name="guardian_last_name"
                    onChange={handleInput}
                    required
                    value={newStudents.guardian_last_name}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_phone"
                >
                  <Form.Label>保護者の電話</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="保護者の電話"
                    name="guardian_phone"
                    required
                    onChange={handleInput}
                    value={newStudents.guardian_phone}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_address"
                >
                  <Form.Label>保護者の住所</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="保護者の住所"
                    name="guardian_address"
                    required
                    onChange={handleInput}
                    value={newStudents.guardian_address}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_email"
                >
                  <Form.Label>保護者のメール</Form.Label>
                  <Form.Control
                    type="mail"
                    placeholder="保護者のメール"
                    name="guardian_email"
                    onChange={handleInput}
                    required
                    value={newStudents.guardian_email}
                  />
                </Form.Group>

                <Form.Group
                  className="col-md-3 mb-3"
                  controlId="formBasicPassword"
                >
                  <Form.Label>保護者の写真</Form.Label>

                  <div className="img-box">
                    <input
                      onChange={e => handleChange(e.target.files)}
                      multiple={false}
                      type="file"
                      className="form-control"
                    />
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_photo"
                >
                  <div className="mb-1">
                    {file ? (
                      <img
                        src={file}
                        height={150}
                        width={150}
                        className="avater-img-src"
                        alt="logo"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="col-md-6">
          <div className="row">
            <Form.Label>クラス:</Form.Label>
            <span className="mb-0 border btn shadow-md bg-light">
              割り当てられたクラスがここに表示されます
            </span>
          </div>
          <div className="form-group mt-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              申し送り事項 :
            </label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                maxLength="100"
                rows={2}
                onChange={e => setNoticeDetails(e.target.value)}
                value={Nvh({ value: noticeDetails })}
                placeholder={
                  !readOnly
                    ? "申し送り事項を記入してください（100文字以内）"
                    : ""
                }
                readOnly={readOnly}
              ></textarea>
              <div className="invalid-feedback">
                {errorList?.notice ? errorList?.notice : ""}
              </div>
            </div>
          </div>
          <div className="form-group mt-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              備考: <span className="text-white">-</span>
            </label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                maxLength="100"
                rows={2}
                onChange={e => setConvertedContent(e.target.value)}
                value={Nvh({ value: convertedContent })}
                placeholder="備考を記入してください（100文字以内）"
              ></textarea>
              <div className="invalid-feedback">
                {errorList?.note ? errorList?.note : ""}
              </div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end"></label>
            <div className="col-sm-4">
              <h4 className="my-1">保護者</h4>
            </div>
            <label className="col-sm-6"></label>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              氏名: <span className="text-white">-</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="保護者の氏名"
                className="form-control"
                name="guardian_name"
                onChange={handleInput}
                value={Nvh({ value: newStudents.guardian_name })}
              />
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              フリガナ:
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="保護者の氏名：フリガナ"
                className="form-control"
                name="guardian_furigana"
                onChange={handleInput}
                value={Nvh({ value: newStudents.guardian_furigana })}
              />
            </div>
          </div>

          {!excludedUserIds.includes(userId) && (
            <>
              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end"></label>
                <div className="col-sm-4">
                  <h4 className="my-1">緊急連絡</h4>
                </div>
                <label className="col-sm-6"></label>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  名: <span className="text-white">-</span>
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    placeholder="緊急時の姓"
                    className="form-control"
                    name="em_last_name"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.em_last_name })}
                  />
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  フリガナ:
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    placeholder="名：フリガナ"
                    className="form-control"
                    name="em_last_name_furigana"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.em_last_name_furigana })}
                  />
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  電話: <span className="text-white">-</span>
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    placeholder="緊急電話番号"
                    className="form-control"
                    name="em_phone_no"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.em_phone_no })}
                  />
                </div>

                <div className="col-sm-6"></div>
              </div>
            </>
          )}

          {newStudents.type === "Regular" && (
            <>
              <hr></hr>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  料金種別: <span className="text-danger">*</span>
                </label>
                <div className="col-sm-10">
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="validationFormCheck2"
                      name="charge_type_code"
                      onChange={handleInput}
                      value="通常"
                      checked={newStudents.charge_type_code === "通常"}
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="validationFormCheck2"
                    >
                      通常
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="validationFormCheck2"
                      name="charge_type_code"
                      onChange={handleInput}
                      value="特別１"
                      checked={newStudents.charge_type_code === "特別１"}
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="validationFormCheck2"
                    >
                      特別１
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="validationFormCheck2"
                      name="charge_type_code"
                      onChange={handleInput}
                      value="特別２"
                      checked={newStudents.charge_type_code === "特別２"}
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="validationFormCheck2"
                    >
                      特別2
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="validationFormCheck2"
                      name="charge_type_code"
                      onChange={handleInput}
                      value="無料"
                      checked={newStudents.charge_type_code === "無料"}
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="validationFormCheck2"
                    >
                      無料
                    </label>
                  </div>

                  <div className="invalid-feedback">
                    {errorList?.charge_type_code
                      ? errorList?.charge_type_code
                      : ""}
                  </div>
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  納付区分: <span className="text-white">-</span> 
                </label>
                <div className="col-sm-10">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_type_code"
                      checked={newStudents.payment_type_code === "振替"}
                      onChange={handleInput}
                      value="振替"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      振替
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_type_code"
                      checked={newStudents.payment_type_code === "新規"}
                      onChange={handleInput}
                      value="新規"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      新規
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_type_code"
                      checked={newStudents.payment_type_code === "現金"}
                      onChange={handleInput}
                      value="現金"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      現金
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="payment_type_code"
                      checked={newStudents.payment_type_code === "振込"}
                      onChange={handleInput}
                      value="振込"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      振込
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}

          {newStudents.type === "Regular" && (
            <>
              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end"></label>
                <div className="col-sm-4">
                  <h4 className="my-1">銀行情報</h4>
                </div>
                <label className="col-sm-6"></label>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  銀行コード: <span className="text-white">-</span>
                </label>
                <div className="col-sm-4">
                  <select
                    name="bank_code"
                    className="form-control"
                    onChange={(e) => setBankId(e.target.value)}
                    value={
                      newStudents.type === "Regular" ? bankId : ""
                    }
                    disabled={newStudents.type === "Short Term"}
                  >
                    <option value="">銀行を選択</option>
                    {banks.map((bank, i) => (
                      <option key={i} value={bank.id}>
                        {bank.bank_name}
                      </option>
                    ))}
                  </select>
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  支店コード:
                </label>
                <div className="col-sm-4">
                  <input
                    type="tel"
                    name="branch_code"
                    className="form-control"
                    placeholder="支店コード"
                    pattern="[0-9]+"
                    value={
                      newStudents.type === "Regular"
                        ? newStudents?.branch_code
                        : ""
                    }
                    onChange={handleInput}
                    disabled={newStudents.type === "Short Term"}
                  />
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  預金種別: <span className="text-white">-</span>
                </label>
                <div className="col-sm-10">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deposit_item"
                      checked={newStudents.deposit_item === "1"}
                      onChange={handleInput}
                      value="1"
                      disabled={newStudents.type === "Short Term"}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      普通
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deposit_item"
                      checked={
                        newStudents.type === "Regular"
                          ? newStudents.deposit_item === "2"
                          : false
                      }
                      onChange={handleInput}
                      value="2"
                      disabled={newStudents.type === "Short Term"}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      当座
                    </label>
                  </div>

                  {!excludedUserIds.includes(userId) && (
                    <>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="deposit_item"
                          value="納税基準"
                          onChange={handleInput}
                          checked={
                            newStudents.type === "Regular"
                              ? newStudents.deposit_item === "納税基準"
                              : false
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          納税基準
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  口座番号: <span className="text-white">-</span>
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="口座番号"
                    name="account_no"
                    onChange={handleInput}
                    value={
                      newStudents.type === "Regular"
                        ? Nvh({ value: newStudents.account_no })
                        : ""
                    }
                    disabled={newStudents.type === "Short Term"}
                  />
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  口座名義人:
                </label>
                <div className="col-sm-4">
                  <input
                    className="form-control"
                    onChange={e =>
                      setNewStudents(p => ({
                        ...p,
                        account_holder: e.target.value,
                      }))
                    }
                    value={newStudents.account_holder}
                    onBlur={() => {
                      setNewStudents(p => ({
                        ...p,
                        account_holder: convertToHalfWidthFunction(
                          newStudents.account_holder
                        ),
                      }));
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        e.target.blur(); // Blur the input field
                        setNewStudents(p => ({
                          ...p,
                          account_holder: convertToHalfWidthFunction(
                            newStudents.account_holder
                          ),
                        }));
                      }
                    }}
                  />
                  {/* <HalfWidthKatakana
                    className="form-control"
                    placeholder="口座名義人"
                    name="account_holder"
                    value={
                      newStudents.type === "Regular"
                        ? Nvh({ value: newStudents.account_holder })
                        : ""
                    }
                    onChange={value =>
                      setNewStudents({
                        ...newStudents,
                        account_holder: value,
                      })
                    }
                    disabled={newStudents.type === "Short Term"}
                  /> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="d-flex">
        <div className="ml-auto">
          {loading ? (
            <>
              <ButtonLoader className="mr-2" />
              <ButtonLoader />
            </>
          ) : (
            <>
              {newStudents.type === "Regular" && (
                <Button
                  variant="primary"
                  className="btn-block mt-4 mr-5 px-4"
                  type="submit"
                  onClick={() => addNewStudent(2)}
                >
                  次へ
                </Button>
              )}

              <Button
                variant="primary"
                className="btn-block mt-4 px-4"
                type="submit"
                onClick={() => addNewStudent(1)}
              >
                登録
              </Button>
            </>
          )}
        </div>
      </div>
    </span>
  );

  return (
    <>
      <div className="custom-scaling">
        <div className="card">
          <div className="card-header">
            <div className="d-flex">
              <h5>新しい会員情報を追加する:</h5>
              <div className="ml-auto">
                <Link
                  className="btn btn-sm btn-primary px-3 ml-auto"
                  variant="primary"
                  to="/admin/student"
                >
                  {" "}
                  <i className="fas fa-angle-left fa-fw"></i> 戻る
                </Link>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={k => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="tab1" title="個人情報">
                  {formSection}
                </Tab>
                <Tab eventKey="tab2" title="クラス" disabled={!enableTabs}>
                  <Option2
                    studentInfo={savedStudents}
                    deposite={deposite}
                    setDeposite={setDeposite}
                    readOnlyValue={readOnly}
                    trigger={trigger}
                    setTrigger={setTrigger}
                    setKey={setKey}
                  />
                </Tab>
                <Tab eventKey="tab3" title="請求／入金" disabled={!enableTabs}>
                  {enableTabs ? (
                    <Option3
                      studentInfo={savedStudents}
                      pdeposite={deposite}
                      setpDeposite={setDeposite}
                      readOnlyValue={readOnly}
                      trigger={trigger}
                      setTrigger={setTrigger}
                    />
                  ) : (
                    ""
                  )}
                </Tab>
                <li>
                  <div className="ml-auto">
                    <Link
                      className="btn btn-sm btn-primary px-3 ml-auto"
                      variant="primary"
                      to="/admin/student"
                    >
                      {" "}
                      <i className="fas fa-angle-left fa-fw"></i> 戻る
                    </Link>
                  </div>
                </li>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateStudent;
