import React from "react";
import jsPDF from "jspdf";

const PDFG = () => {
  const students = [
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Alice Johnson" },
    { id: 4, name: "Bob Brown" },
    { id: 5, name: "Eve Williams" },
    { id: 6, name: "Michael Davis" },
    { id: 7, name: "Emily Wilson" },
    // Add more students as needed
  ];

  const generatePdf = () => {
    const doc = new jsPDF("p", "pt", "a4");
    const itemsPerPage = 21;
    const totalPages = Math.ceil(students.length / itemsPerPage);

    for (let page = 0; page < totalPages; page++) {
      const startIndex = page * itemsPerPage;
      const endIndex = Math.min(startIndex + itemsPerPage, students.length);

      let htmlContent = '';

      for (let i = startIndex; i < endIndex; i++) {
        const student = students[i];

        htmlContent += `
          <div>
            
          </div>
        `;
      }

      doc.fromHTML(htmlContent, 10, 10, {
        width: 570 // Adjust width as needed
      });

      if (page < totalPages - 1) {
        doc.addPage();
      }
    }

    doc.save("students.pdf");
  };

  return (
    <div>
      <button onClick={generatePdf}>Generate Pdf</button>
    </div>
  );
};

export default PDFG;
