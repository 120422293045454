import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import privateApi from "../../../services/privateApi";
import { Card, Table } from "antd";
import '../../student/PaymentHistory.css';


const TransferAttendance = () => {
  const [attendance, setAttendance] = useState([]);

  const getAttendance = async () => {
    try {
      const response = await privateApi.get("member/showAttendance");
      if (response.status === 200) {
        setAttendance(response.data.data.data);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.log("Error fetching attendance:", error);
    }
  };

  useEffect(() => {
    getAttendance();
  }, []);

  const columns = [
    {
      title: "日付",
      dataIndex: "attendance_date",
      key: "attendance_date"
    },
    {
      title: "Class Name",
      dataIndex: "class_name",
      key: "class_name"
    },
    {
      title: "出席状況",
      dataIndex: "present",
      key: "present",
      render: (present) => (present === 1 ? "⭕" : "❌")
    }
  ];

  return (
    <div className="p-3">
      <Card>
        <p className="headline">出席状況</p>
        <div className="payment-history-table">
          <Table
            // locale={{ emptyText: 'データーがありません。',  }}
            dataSource={attendance}
            columns={columns}
            bordered
            pagination={false}
            scroll={{ x: true }}
          />
        </div>
      </Card>
    </div>
  );
};

export default TransferAttendance;
