import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  //assigning location variable
  const location = useLocation();
  const { pathname } = location;
  const url = pathname.split("/student/");

  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <div className="sb-sidenav-menu-heading"></div>
          <Link
            className={url[1] === "dashboard" ? "nav-link active2" : "nav-link"}
            to="/student/dashboard"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-tachometer-alt"></i>
            </div>
            プロフィール
          </Link>

          <Link
            className={
              url[1] === "re-schedule" ? "nav-link active2" : "nav-link"
            }
            to="/student/re-schedule"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-random "></i>
            </div>
            出席狀況
          </Link>

          <Link
            className={url[1] === "payment" ? "nav-link active2" : "nav-link"}
            to="/student/payment"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-yen-sign"></i>
            </div>
            支払状況
          </Link>
        </div>
      </div>
      <div className="sb-sidenav-footer">
        <div className="small">としてログイン:</div>
        デモ生
      </div>
    </nav>
  );
};

export default Sidebar;
