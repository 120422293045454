import React, { useEffect, useState } from 'react';
import privateApi from '../../../services/privateApi';
import useLoading from '../../../hooks/useLoading';
import { Divider, Flex, Modal, Row, Spin, Table, Typography } from 'antd';
import "./payments.css";
import moment from 'moment';
import useModal from '../../../hooks/useModal';
const { Title } = Typography;

const Payments = () => {
   const [invoices, setInvoices] = useState([]);
   const { isLoading, startLoading, stopLoading } = useLoading();
   const [currentInvoice, setCurrentInvoice] = useState();
   const { open, close, isOpen } = useModal();
   const columns = [
      {
         title: "処理年月",
         dataIndex: "processed_date",
         sorter: (a, b) => new Date(a.processed_date) - new Date(b.processed_date),
         render: (value, record) => <span style={{ color: "#3e79f7" }}>
            {moment(value).format("YYYY/MM")}
         </span>
      },
      {
         title: "請求額",
         dataIndex: "billing_amount",
         render: (value) => <div style={{ textAlign: "right" }}>{"￥" + Number(value).toLocaleString()}</div>
      },
      {
         title: "入金",
         dataIndex: "billing_check",
         render: (value, record) => <div style={{ textAlign: "right" }}>{"￥" + getTotalPayment(record).toLocaleString()}</div>
      },
      {
         title: "過不足額累計",
         dataIndex: "cumulative_excess_deficiency",
         render: (value, record) => <div style={{ color: value > 0 ? "green" : "red", textAlign: "right" }}>{"￥" + Number(value).toLocaleString()}</div>
      },
   ];
   const getTotalPayment = (record) => record ? (Number(record.cash_deposit_amount) + Number(record.cash_deposit_amount2) + Number(record.transfer_amount)) : 0;

   const getInvoices = async e => {
      startLoading();
      const response = await privateApi.get("member/invoices");
      if (response.status === 200) {
         setInvoices(response.data.data);
      } else {
         console.log("Server Error");
      }
      stopLoading();
   };

   useEffect(() => {
      getInvoices();
   }, []);
   if (isLoading) {
      return <Spin />;
   }
   return (
      <div className='payments'>
         <Title style={
            { marginTop: "50px" }} level={2}>お支払い履歴</Title>
         <div style={{ maxWidth: "100%", overflowX: "auto" }}>
            <Table
               columns={columns}
               dataSource={invoices}
               pagination={{
                  hideOnSinglePage: true
               }}
               onRow={(record) => {
                  return {
                     onClick: event => {
                        setCurrentInvoice(record);
                        open();
                     }, // click row
                  };
               }}
            />
         </div>
         <Modal
            footer={null}
            open={isOpen}
            title={moment(currentInvoice?.processed_date).format("YYYY年 MM月")}
            onCancel={() => {
               setCurrentInvoice();
               close();
            }}
            className='payments payments-modal'
         >
            <Row style={{ marginTop: "15px" }}>
               <Flex justify='space-between' align='center' style={{ width: "100%" }}>
                  <span style={{ fontWeight: 700, color: "black", fontSize: "20px", textAlign: "center" }}>請求額</span>
                  <span style={{ fontWeight: 700, color: "#173658", fontSize: "20px" }}>￥{currentInvoice?.billing_amount}</span>
               </Flex>
            </Row>
            {currentInvoice?.transfer_amount ? <><Row style={{ marginTop: "10px" }}>
               <Flex justify='space-between' align='center' style={{ width: "100%" }}>
                  <Flex vertical>
                     <span style={{ fontWeight: 700, color: "#8080806e", fontSize: "14px" }}>{moment(currentInvoice.transfer_deposit_date).format("YYYY.MM.DD")}</span>
                     <span style={{ fontWeight: 700, color: "black", fontSize: "16px", textAlign: "center" }}>{currentInvoice?.transfer_amount}</span>
                  </Flex>
                  <span style={{ color: "#173658", fontSize: "18px", fontStyle: "italic" }}>￥{currentInvoice?.transfer_amount || 0}</span>
               </Flex>
            </Row>
               <Divider style={{ margin: "10px 0" }} />
            </>
               : <></>}

            {currentInvoice?.cash_deposit_amount ? <><Row style={{ marginTop: !currentInvoice?.transfer_amount ? "15px" : "" }}>
               <Flex justify='space-between' align='center' style={{ width: "100%" }}>
                  <Flex vertical>
                     <span style={{ fontWeight: 700, color: "#8080806e", fontSize: "14px" }}>{moment(currentInvoice.cash_deposit_date).format("YYYY.MM.DD")}</span>
                     <span style={{ fontWeight: 700, color: "black", fontSize: "16px" }}>現金</span>
                  </Flex>
                  <span style={{ color: "#173658", fontSize: "18px", fontStyle: "italic" }}>￥{currentInvoice?.cash_deposit_amount || 0}</span>
               </Flex>
            </Row>
               <Divider style={{ margin: "10px 0" }} />
            </>
               : <></>}
            {currentInvoice?.cash_deposit_amount2 ? <><Row>
               <Flex justify='space-between' align='center' style={{ width: "100%" }}>
                  <Flex vertical>
                     <span style={{ fontWeight: 700, color: "#8080806e", fontSize: "14px" }}>{moment(currentInvoice.cash_deposit_date2).format("YYYY.MM.DD")}</span>
                     <span style={{ fontWeight: 700, color: "black", fontSize: "16px" }}>現金</span>
                  </Flex>
                  <span style={{ color: "#173658", fontSize: "18px", fontStyle: "italic" }}>￥{currentInvoice?.cash_deposit_amount2 || 0}</span>
               </Flex>
            </Row>
            </>
               : <></>}
            {getTotalPayment(currentInvoice) === 0 ?
               <div style={{ padding: "20px", textAlign: "center", width: "100%", color: "#8080806e", fontSize: "20px" }}>この月の支払いはありません</div>
               : <></>}


            <Row style={{ marginTop: "30px" }}>
               <Flex justify='space-between' align='center' style={{ width: "100%" }}>
                  <span style={{ fontWeight: 700, color: "black", fontSize: "20px", textAlign: "center" }}>過不足額累計</span>
                  <span style={{ fontWeight: 700, color: currentInvoice?.cumulative_excess_deficiency > 0 ? "green" : "red", fontSize: "20px" }}>￥{currentInvoice?.cumulative_excess_deficiency}</span>
               </Flex>
            </Row>
         </Modal>
      </div>
   );
};

export default Payments;