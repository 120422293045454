import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/common/css/dashboarButton.css";
import { checkModulePermissionBySlug } from "../../utils/CheckModulePermission";

function Dashboard() {
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");

  const getDateAndMonth = () => {
    const today = new Date();
    const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
    // Retrieve the date components
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-based, so we add 1
    const day = today.getDate();
    const weekday = weekdays[today.getDay()];

    // Format the date in Japanese
    const japaneseFormatDate = `${year}年${month}月${day}日（${weekday}）`;

    const japaneseFormatMonth = `${today.getFullYear()}年${
      today.getMonth() + 1
    }月度`;

    setDate(japaneseFormatDate);
    setMonth(japaneseFormatMonth);
  };

  const adminDashboardButtons = [
    {
      name: "会員管理",
      path: "/admin/student",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "システム管理",
      path: "/admin/master-maintenance/dashboard",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "バス管理",
      path: "/admin/bus/dashboard",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "短期生管理",
      path: "/admin/stc/dashboard",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "銀行管理",
      path: "/admin/bank/dashboard",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "ユーザ管理",
      path: "/admin/user/dashboard",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "進級者認定証",
      path: "/admin/advanced-certificate",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "出席狀況",
      path: "/admin/transfer-attendance",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "コーチ機能",
      path: "/admin/user/coach-management",
      icon: "fas fa-tachometer-alt",
    },

    // {
    //   name: "請求処理",
    //   path: "/admin/billing-process",
    //   icon: "fas fa-tachometer-alt",
    // },

    // {
    //   name: "バックアップ",
    //   path: "/admin/dashboard",
    //   icon: "fas fa-tachometer-alt",
    // },
    // {
    //   name: "入金処理",
    //   path: "/admin/payment-processing",
    //   icon: "fas fa-tachometer-alt",
    // },

    // {
    //   name: "収納者／未納者リスト",
    //   path: "/admin/payer-delinquent-list",
    //   icon: "fas fa-tachometer-alt",
    // },
    // {
    //   name: "短期生管理",
    //   path: "/admin/short-term-student-management",
    //   icon: "fas fa-tachometer-alt",
    // },
    // {
    //   name: "生徒の履歴確認",
    //   path: "/admin/check-student-history",
    //   icon: "fas fa-tachometer-alt",
    // },
    // {
    //   name: "守護者",
    //   path: "/admin/guardian",
    //   icon: "fas fa-solid fa-person-alt",
    // },
  ];

  useEffect(() => {
    if (checkModulePermissionBySlug("bus-dashboard-menu", "read") === 1) {
      getDateAndMonth();
    }
  }, []);

  return (
    <div className="p-3">
      {/* <p className="headline">管理機能一覧です</p> */}
      <div
        className="col-4 row justify-content-start p-2 dashboard-section-header"
        style={{ backgroundColor: "#edf7f3", marginLeft: "1px" }}
      >
        <div key={date} className="month-btn w-100">
          {date}
        </div>
      </div>
      {checkModulePermissionBySlug("bus-dashboard-menu", "read") === 1 && (
        <div className="btn-container col-md-4 col-sm-12">
          {adminDashboardButtons.map(button => (
            <Link to={button.path}>
              <button className="dash-btn">{button.name}</button>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default Dashboard;
