import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import privateApi from "../../../services/privateApi";
import { Button, Form } from "react-bootstrap";
import { getCurrentMonth } from "../../../services/auth/token";
import moment from "moment";

const AdvanceSearch = ({
  advanceSearchData,
  setAdvanceSearchData,
  advanceSearchStart,
  advanceSearchHasValue,
  advanceSearchValueChecker,
}) => {
  const [buses, setBuses] = useState([]);
  const [banks, setBanks] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classeID, setClasseID] = useState([]);
  const [classesTime, setClassesTime] = useState([]);

  const getGenerelRes = async () => {
    const month = getCurrentMonth();
    const response = await privateApi.get(
      `admin/general-resources?type=bus,bank,member_code&processed_date=${moment(month).format("YYYY-MM")}`
    );
    if (response.status === 200) {
      setBuses(response.data.bus);
      setBanks(response.data.bank);
    } else {
      console.log("Server Error");
    }
  };

  const getClasses = async () => {
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const getClassesTime = async (classId = null) => {
    const endpoint = classId
      ? `admin/get-class-times/${classId}`
      : `admin/get-class-times`;
    const response = await privateApi.get(endpoint);
    if (response.status === 200) {
      setClassesTime(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const [advanceSearch, setAdvanceSearch] = useState(advanceSearchData);

  useEffect(() => {
    getGenerelRes();
    getClasses();
    getGuardians();
    getClassesTime(); // Fetch all class times initially
  }, []);

  useEffect(() => {
    setAdvanceSearchData(advanceSearch);
    advanceSearchHasValue = Object.values(advanceSearch).some(
      value => value !== null && value !== undefined && value !== ""
    );
    advanceSearchValueChecker(advanceSearchHasValue);
  }, [advanceSearch]);

  const newGuardiansArray = [{ label: "家族を追加する", value: "" }];
  const createCustomGuardiansArray = oldArray => {
    oldArray.forEach(item => {
      newGuardiansArray.push({
        label: item.first_name + " " + item.last_name,
        value: item.id,
      });
    });
  };

  const getGuardians = async () => {
    const response = await privateApi.get("admin/guardians");
    if (response.status === 200) {
      createCustomGuardiansArray(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      advanceSearchStart();
    }
  };

  const handleInput = async e => {
    e.persist();
    const { name, value } = e.target;
    setAdvanceSearch(prevState => ({ ...prevState, [name]: value }));

    if (name === "affiliation") {
      if (value) {
        await getClassesTime(value);
      } else {
        await getClassesTime();
      }
    }
  };

  const handleCheckBox = e => {
    setAdvanceSearch({ ...advanceSearch, [e.target.name]: e.target.checked });
  };

  const handleInputArray = e => {
    const { value, checked } = e.target;
    if (checked) {
      setAdvanceSearch(prevState => ({
        ...prevState,
        day: [...prevState.day, value],
      }));
    } else {
      setAdvanceSearch(prevState => ({
        ...prevState,
        day: prevState.day.filter(day => day !== value),
      }));
    }
  };

  const AdvanceSearchData = () => {
    setAdvanceSearch({
      name: "",
      furigana_name: "",
      member_code_start: "",
      member_code_end: "",
      gender: "",
      birthday_start: "",
      birthday_end: "",
      joining_date_start: "",
      joining_date_end: "",
      member_drop_out_start: "",
      member_drop_out_end: "",
      district_code: "",
      telePhone_1: "",
      telePhone_2: "",
      charge_type: "",
      remarks: "",
      payment_category: "",
      bank_code: "",
      member: "1",
      affiliation: "",
      times: "",
      class_from: "",
      class_to: "",
      day: "",
      time: "",
      bus_user: "",
      bus_course: "",
      start_time: "",
      specialStudy: false,
    });
  };

  return (
    <div className="row" onKeyDown={handleKeyDown}>
      <div className="form-group mb-2 row">
        <label className="col-md-2 col-form-label text-sm-end">会員番号:</label>
        <div className="col-md-10">
          <div className="row">
            <div className="col-sm-2">
              <input
                className="form-control form-control-sm"
                type="text"
                value={advanceSearch?.member_code_start}
                onChange={handleInput}
                name="member_code_start"
              />
            </div>
            ~
            <div className="col-sm-2">
              <input
                className="form-control form-control-sm"
                type="text"
                value={advanceSearch?.member_code_end}
                onChange={handleInput}
                name="member_code_end"
              />
            </div>
            <div className="col-sm-5">
              <Button
                className="btn btn-sm"
                onClick={AdvanceSearchData}
                variant="warning"
              >
                検索条件クリア
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group mb-2 row">
        <label className="col-md-2 col-form-label text-sm-end">名前:</label>
        <div className="col-md-6 d-flex">
          <div style={{ width: "135px" }}>
            <input
              type="text"
              className="form-control form-control-sm"
              name="name"
              value={advanceSearch?.name}
              onChange={handleInput}
            />
          </div>

          <label className=" col-form-label text-sm-end pl-5 mx-1">
            {" "}
            フリガナ:
          </label>
          <div style={{ width: "135px" }}>
            <input
              type="text"
              className="form-control form-control-sm"
              name="furigana_name"
              value={advanceSearch?.furigana_name}
              onChange={handleInput}
            />
          </div>
        </div>
      </div>

      <div className="form-group mb-2 row">
        <label className="col-sm-2 col-form-label text-sm-end">生年月日:</label>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-6">
              <input
                type="date"
                className="form-control form-control-sm"
                placeholderText="___/__/__"
                value={
                  advanceSearch.birthday_start
                    ? new Date(advanceSearch.birthday_start)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                onChange={e => {
                  const selectedDate = e.target.value;

                  setAdvanceSearch({
                    ...advanceSearch,
                    birthday_start: selectedDate,
                  });
                }}
                name="birthday_start"
                max="3090-12-31"
              />
            </div>

            <div className="col-md-6">
              <input
                type="date"
                className="form-control form-control-sm"
                placeholderText="___/__/__"
                value={
                  advanceSearch.birthday_end
                    ? new Date(advanceSearch.birthday_end)
                        .toISOString()
                        .split("T")[0]
                    : null
                }
                onChange={e => {
                  const selectedDate = e.target.value;

                  setAdvanceSearch({
                    ...advanceSearch,
                    birthday_end: selectedDate,
                  });
                }}
                name="birthday_end"
                max="3090-12-31"
              />
            </div>
          </div>
        </div>

        <label className="col-sm-2 col-form-label text-sm-end">入会日:</label>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-6">
              <input
                type="date"
                className="form-control form-control-sm"
                placeholderText="___/__/__"
                value={
                  advanceSearch.joining_date_start
                    ? new Date(advanceSearch.joining_date_start)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                onChange={e => {
                  const selectedDate = e.target.value;

                  setAdvanceSearch({
                    ...advanceSearch,
                    joining_date_start: selectedDate,
                  });
                }}
                name="joining_date_start"
                max="3090-12-31"
              />
            </div>

            <div className="col-md-6">
              <input
                type="date"
                className="form-control form-control-sm"
                placeholderText="___/__/__"
                value={
                  advanceSearch.joining_date_end
                    ? new Date(advanceSearch.joining_date_end)
                        .toISOString()
                        .split("T")[0]
                    : null
                }
                onChange={e => {
                  const selectedDate = e.target.value;

                  setAdvanceSearch({
                    ...advanceSearch,
                    joining_date_end: selectedDate ? selectedDate : "", // Set to empty string if null
                  });
                }}
                name="joining_date_end"
                max="3090-12-31"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="form-group mb-2 row">
        {/* <label className="col-md-2 col-form-label text-sm-end">
          地区コード:
        </label>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control form-control-sm"
            name="district_code"
            value={advanceSearch?.district_code}
            onChange={handleInput}
          />
        </div> */}

        <label className="col-md-2 col-form-label text-sm-end">性別:</label>
        <div className="col-md-4">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              value="all"
              onChange={handleInput}
              checked={advanceSearch?.gender === "all"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              すべて
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              value="男"
              onChange={handleInput}
              checked={advanceSearch?.gender === "男"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio2">
              男
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              value="女"
              onChange={handleInput}
              checked={advanceSearch?.gender === "女"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              女
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              value="未"
              onChange={handleInput}
              checked={advanceSearch?.gender === "未"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              未
            </label>
          </div>
        </div>

        <label className="col-md-2 col-form-label text-sm-end">
          住所1:
        </label>
        <div className="col-md-2">
          <input
            type="text"
            className="form-control form-control-sm"
            name="address_1"
            value={advanceSearch?.address_1}
            onChange={handleInput}
          />
        </div>
      </div>

      <div className="form-group mb-2 row">
      <label className="col-md-2 col-form-label text-sm-end">
          電話番号1:
        </label>
        <div className="col-md-2">
          <input
            type="text"
            className="form-control form-control-sm"
            name="telePhone_1"
            value={advanceSearch?.telePhone_1}
            onChange={handleInput}
          />
        </div>
        <label className="col-md-1 col-form-label text-sm-end">
          電話番号2
        </label>
        <div className="col-md-2">
          <input
            type="text"
            className="form-control form-control-sm"
            name="telePhone_2"
            value={advanceSearch?.telePhone_2}
            onChange={handleInput}
          />
        </div>

        <label className="col-md-1 col-form-label text-sm-end">備考:</label>
        <div className="col-md-3">
          <input
            type="text"
            className="form-control form-control-sm"
            name="remarks"
            value={advanceSearch?.remarks}
            onChange={handleInput}
          />
        </div>
        <div className="col-md-1">を含む</div>
      </div>

      <div className="form-group mb-2 row">
        <label className="col-md-2 col-form-label text-sm-end">料金種別:</label>
        <div className="col-md-4 pt-2">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="charge_type_code"
              value="all"
              onChange={handleInput}
              checked={advanceSearch?.charge_type_code === "all"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              すべて
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="charge_type_code"
              value="通常"
              onChange={handleInput}
              checked={advanceSearch?.charge_type_code === "通常"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio2">
              通常
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="charge_type_code"
              value="特別１"
              onChange={handleInput}
              checked={advanceSearch?.charge_type_code === "特別１"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              特別１
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="charge_type_code"
              value="特別２"
              onChange={handleInput}
              checked={advanceSearch?.charge_type_code === "特別２"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              特別２
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="charge_type_code"
              value="無料"
              onChange={handleInput}
              checked={advanceSearch?.charge_type_code === "無料"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              無料
            </label>
          </div>
        </div>

        <label className="col-md-2 col-form-label text-sm-end">納付区分:</label>
        <div className="col-md-4 pt-2">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="payment_type_code"
              value="all"
              onChange={handleInput}
              checked={advanceSearch?.payment_type_code === "all"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              すべて
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="payment_type_code"
              value="振替"
              onChange={handleInput}
              checked={advanceSearch?.payment_type_code === "振替"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio2">
              振替
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="payment_type_code"
              value="新規"
              onChange={handleInput}
              checked={advanceSearch?.payment_type_code === "新規"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              新規
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="payment_type_code"
              value="現金"
              onChange={handleInput}
              checked={advanceSearch?.payment_type_code === "現金"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              現金
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="payment_type_code"
              value="振込"
              onChange={handleInput}
              checked={advanceSearch?.payment_type_code === "振込"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              振込
            </label>
          </div>
        </div>
      </div>

      <div className="form-group mb-2 row">
        <label className="col-md-2 col-form-label text-sm-end">
          銀行コード:
        </label>
        <div className="col-md-4">
          <select
            className="form-control form-control-sm"
            name="bank_code"
            onChange={handleInput}
            value={advanceSearch?.bank_code}
          >
            <option value="">すべて</option>
            {banks?.map((bank, i) => (
              <option
                key={i}
                value={bank.id}
                data-branch-code={bank.branch_code}
                data-branch-name={bank.branch_name}
              >
                {bank.bank_name}
              </option>
            ))}
          </select>
        </div>

        {/* <label className="col-md-2 col-form-label text-sm-end">会員:</label>
        <div className="col-md-4 pt-2">
          <div className="form-check me-4 form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="member"
              value="all"
              onChange={handleInput}
              checked={advanceSearch?.member === "all"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              すべて
            </label>
          </div>
          <div className="form-check mx-4 form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="member"
              value="現会員"
              onChange={handleInput}
              checked={advanceSearch?.member === "現会員"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio2">
              現会員
            </label>
          </div>
          <div className="form-check ms-4 form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="member"
              value="退校者"
              onChange={handleInput}
              checked={advanceSearch?.member === "退校者"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              退校者
            </label>
          </div>
        </div> */}
      </div>

      {/* <div className="form-group mb-2 row">
        <label className="col-md-2 col-form-label text-sm-end">退校日:</label>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-6">
              <input
                type="date"
                className="form-control form-control-sm"
                value={advanceSearch.member_drop_out_start}
                onChange={e => {
                  const selectedDate = e.target.value;

                  setAdvanceSearch({
                    ...advanceSearch,
                    member_drop_out_start: selectedDate,
                  });
                }}
                name="member_drop_out_start"
                max="3090-12-31"
              />
            </div>

            <div className="col-md-6">
              <input
                type="date"
                className="form-control form-control-sm"
                value={
                  advanceSearch.member_drop_out_end
                    ? advanceSearch.member_drop_out_end.toISOString().split("T")[0]
                    : ""
                }
                onChange={e => {
                  const selectedDate = e.target.value;

                  setAdvanceSearch({
                    ...advanceSearch,
                    member_drop_out_end: selectedDate,
                  });
                }}
                name="member_drop_out_end"
                max="3090-12-31"
              />
            </div>
          </div>
        </div>
        <label className="col-md-2 col-form-label text-sm-end">開始時刻:</label>
        <div className="col-md-4">
          <input
            type="time"
            name="start_time"
            className="form-control form-control-sm"
            onChange={handleInput}
            value={advanceSearch.start_time}
            style={{ width: "150px" }}
          />
        </div>
      </div> */}

      <div className="row form-group mb-2 mb-2">
        <label className="col-md-2 col-form-label text-sm-end">会員:</label>
        <div className="col-md-3">
          <div className="form-check  form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="member"
              value="0"
              onChange={handleInput}
              checked={advanceSearch?.member == "0"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              すべて
            </label>
          </div>
          <div className="form-check  form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="member"
              value="1"
              onChange={handleInput}
              checked={advanceSearch?.member == "1"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio2">
              現会員
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="member"
              value="2"
              onChange={handleInput}
              checked={advanceSearch?.member == "2"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              退校者
            </label>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-end">
          <div className="col-md-6">
            <input
              type="date"
              className="form-control form-control-sm"
              placeholderText="___/__/__"
              value={
                advanceSearch.member_drop_out_start
                  ? new Date(advanceSearch.member_drop_out_start)
                      .toISOString()
                      .split("T")[0]
                  : null
              }
              onChange={e => {
                const selectedDate = e.target.value;
                setAdvanceSearch({
                  ...advanceSearch,
                  member_drop_out_start: selectedDate,
                });
              }}
              name="member_drop_out_start"
              max="3090-12-31"
            />
          </div>
          <div className="col-md-6">
            <input
              type="date"
              className="form-control form-control-sm"
              placeholderText="___/__/__"
              value={
                advanceSearch.member_drop_out_end
                  ? new Date(advanceSearch.member_drop_out_end)
                      .toISOString()
                      .split("T")[0]
                  : null
              }
              onChange={e => {
                const selectedDate = e.target.value;
                setAdvanceSearch({
                  ...advanceSearch,
                  member_drop_out_end: selectedDate,
                });
              }}
              name="member_drop_out_end"
              max="3090-12-31"
            />
          </div>
        </div>
      </div>

      <div className="form-group mb-2 row">
        <strong className="col-sm-3 col-form-label text-sm-center">
          クラス
        </strong>
      </div>
      <div className="form-group mb-2 row">
        <label className="col-md-2 col-form-label text-sm-end">所属:</label>
        <div className="col-md-2">
          <select
            className="form-control form-control-sm"
            name="affiliation"
            onChange={handleInput}
            value={advanceSearch?.affiliation}
          >
            <option value="">すべて</option>
            {classes.map((st_class, i) => (
              <option key={i} value={st_class.id}>
                {st_class.affiliation_name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6 d-flex flex-wrap justify-content-between">
          <div className="d-flex">
            <label className="col-form-label">回数: &nbsp;</label>
            <input
              className="form-control form-control-sm"
              type="text"
              name="times"
              style={{ width: "100px" }}
              onChange={handleInput}
              value={advanceSearch?.times}
            />
          </div>

          <div className="d-flex">
            <label className="text-end col-form-label">級: &nbsp;</label>
            <input
              className="form-control form-control-sm"
              type="text"
              name="class_from"
              style={{ width: "50px", height: "18px" }}
              onChange={handleInput}
              value={advanceSearch?.class_from}
            />
            <label className="col-form-label">&nbsp; から</label>
          </div>

          <div className="d-flex">
            <input
              className="form-control form-control-sm"
              type="text"
              name="class_to"
              style={{ width: "50px", height: "18px" }}
              onChange={handleInput}
              value={advanceSearch?.class_to}
            />
            <label className="col-form-label">&nbsp; まで</label>
          </div>
          {/*
          <div className=" col-form-label ">
            <input
              type="checkbox"
              name="specialStudy"
              onChange={() =>
                setAdvanceSearch({
                  ...advanceSearch,
                  specialStudy: !advanceSearch?.specialStudy,
                })
              }
              checked={advanceSearch?.specialStudy}
            />
            &nbsp;特習
          </div>
            */}
        </div>
      </div>

      <div className="form-group row">
        <div className="form-group col-md-8 mb-2 row">
          <label className="col-md-2 col-form-label text-sm-end">曜日:</label>
          <div className="col-md-10">
            <div className="form-check mt-2 form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="day"
                value="all"
                onChange={handleInputArray}
                checked={advanceSearch?.day?.includes("all")}
              />
              <label className="form-check-label mt-1" htmlFor="inlineCheck1">
                すべて
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="day"
                value="月"
                onChange={handleInputArray}
                checked={advanceSearch?.day?.includes("月")}
              />
              <label className="form-check-label mt-1" htmlFor="inlineCheck2">
                月
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="day"
                value="火"
                onChange={handleInputArray}
                checked={advanceSearch?.day?.includes("火")}
              />
              <label className="form-check-label mt-1" htmlFor="inlineCheck3">
                火
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="day"
                value="水"
                onChange={handleInputArray}
                checked={advanceSearch?.day?.includes("水")}
              />
              <label className="form-check-label mt-1" htmlFor="inlineCheck4">
                水
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="day"
                value="木"
                onChange={handleInputArray}
                checked={advanceSearch?.day?.includes("木")}
              />
              <label className="form-check-label mt-1" htmlFor="inlineCheck5">
                木
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="day"
                value="金"
                onChange={handleInputArray}
                checked={advanceSearch?.day?.includes("金")}
              />
              <label className="form-check-label mt-1" htmlFor="inlineCheck6">
                金
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="day"
                value="土"
                onChange={handleInputArray}
                checked={advanceSearch?.day?.includes("土")}
              />
              <label className="form-check-label mt-1" htmlFor="inlineCheck7">
                土
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="day"
                value="日"
                onChange={handleInputArray}
                checked={advanceSearch?.day?.includes("日")}
              />
              <label className="form-check-label mt-1" htmlFor="inlineCheck8">
                日
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-form-label">
          <div className="form-group mb-2 row">
            <label className="col-md-4 col-form-label text-end text-sm-start">
              開始時刻:
            </label>
            <div className="col-md-8 justify-content-sm-end">
              <Form.Group controlId="family_member_id">
                <Form.Select
                  type="time"
                  size="sm"
                  name="start_time"
                  className="form-control-sm"
                  onChange={handleInput}
                  value={advanceSearch.start_time}
                  style={{ width: "150px" }}
                >
                  <option value="">すべて</option>
                  {classesTime.map((st_class, i) => (
                    <option key={i} value={st_class.start_time}>
                      {st_class.start_time}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="form-group mb-2 row">
        <label className="col-md-2 col-form-label text-sm-end">皆勤</label>
        <input
          className="form-control form-control-sm"
          type="text"
          name="class_to"
          style={{ width: "50px", height: "18px" }}
          onChange={handleInput}
          value={advanceSearch?.class_to}
        />
        <label className="col-md-2 col-form-label text-sm-start"> 年</label>
        <div className="col-sm-2"></div>
        <div className="col-md-4">
          <input
            type="checkbox"
            name="bus_user"
            onChange={handleCheckBox}
            checked={advanceSearch?.bus_user}
          />
          &nbsp;休校を含まない
        </div>
      </div>

      <div className="form-group mb-2 row">
        <strong className="col-sm-3 col-form-label text-sm-center">
          送迎バス
        </strong>
      </div>
      <div className="form-group mb-2 row">
        <div className="col-md-2"></div>
        <div className="col-md-4">
          <input
            type="checkbox"
            name="bus_user"
            onChange={handleCheckBox}
            checked={advanceSearch?.bus_user}
          />
          &nbsp;バス利用者
        </div>

        <label className="col-md-2 col-form-label text-sm-end">
          バスコース:
        </label>
        <div className="col-md-4 justify-content-sm-start">
          <select
            className="form-control form-control-sm"
            name="bus_course_number"
            onChange={handleInput}
            value={advanceSearch?.bus_course_number}
          >
            <option value="">すべて</option>
            {buses.map((bus, i) => (
              <option key={i} value={bus.id}>
                {bus.bus_course_number} ({bus.stop_name})
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default AdvanceSearch;
