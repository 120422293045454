import React from "react";
import { useParams } from "react-router-dom";
import useSchoolData from "../../hooks/useSchool";

function Home() {

  const { schoolSlug } = useParams();
  useSchoolData(schoolSlug);

  return (
    <div>
      <h1>ホームページです</h1>
    </div>
  );
}

export default Home;
