import React, { useState, useEffect, useMemo } from "react";
import { Button, Badge, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Modal as AntModal,
  Button as AntButton,
  Checkbox,
  Row,
  Spin,
} from "antd";
import { Table } from "antd";
import { Resizable } from "react-resizable";
import { ConfigProvider } from "antd";
import { checkboxes } from "./checkboxes";
import { Table as BootstrapTable } from "react-bootstrap";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import AdvanceSearch from "./AdvanceSearch";
import Nvh from "../../common/Nvh";
import privateApi from "../../../services/privateApi";
import DateForm from "../../common/DateForm";
import moment from "moment";
import _debounce from "lodash/debounce";
import useModal from "../../../hooks/useModal";
import loadergif from "../../../assets/icons/loader.gif";
import jsPDF from "jspdf";
import "react-resizable/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Student.css";
import "../../../assets/common/css/modal.css";
import { HiChevronUpDown, HiChevronDown, HiChevronUp } from "react-icons/hi2";
import { getCurrentMonth } from "../../../services/auth/token";

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8", // Adjust the value to your desired background color
    },
  },
};

let isFetching = false;

const Student = () => {
  const history = useHistory();
  const [students, setStudents] = useState([]);
  const [selectedStudentIDForDelete, setselectedStudentIDForDelete] =
    useState();
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [advancedSearchPage, setAdvancedSearchPage] = useState(false);
  const [selectedKatakana, setSelectedKatakana] = useState([]);
  const [advanceSearchHasValue, setAdvanceSearchHasValue] = useState(false);
  const [totalStudents, setTotalStudents] = useState(0);
  const [isActiveMembers, setIsActiveMembers] = useState(1);
  const [loader, setLoader] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [orderType, setOrderType] = useState(false);
  const [orderColumn, setOrderColumn] = useState("");
  const [tableKey, setTableKey] = useState(true);
  const location = useLocation();
  const { open, close, isOpen } = useModal();
  const {
    isOpen: isLoading,
    close: stopLoading,
    open: startLoading,
  } = useModal();
  const [checkedColumnList, setCheckedColumnList] = useState([]);
  const [stickerDwnldModal, setStickerDwnldModal] = useState(false);
  const [stickers, setStickers] = useState();
  const [stickerLoader, setStickerLoader] = useState(false);
  const [stickerPattern, setStickerPattern] = useState(1);
  const [selectedStickers, setSelectedStickers] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [buses, setBuses] = useState([]);

  const memberPerPage = 30;

  const searchStudentByID = value => {
    setLoader(true);
    localStorage.setItem("member_search", value);
    setStudents([]);
    setPageNumber(1);
    setHasMore(true);
    setTotalStudents(0);
    getStudents(1, isActiveMembers, selectedKatakana, value);
  };

  const debouncedSearch = _debounce(searchStudentByID, 600);

  const searchStudentByKatakana_name = async katakanasearch => {
    setAdvanceSearch({});
    if (selectedKatakana === katakanasearch) {
      setSelectedKatakana([]);
      setStudents([]);
      setPageNumber(1);
      setHasMore(true);
      setTotalStudents(0);
      getStudents(
        1,
        isActiveMembers,
        "",
        search,
        false,
        orderColumn,
        orderType
      );
    } else {
      setLoader(true);
      setSelectedKatakana(katakanasearch);
      setStudents([]);
      setPageNumber(1);
      setHasMore(true);
      setTotalStudents(0);
      getStudents(
        1,
        isActiveMembers,
        katakanasearch,
        search,
        false,
        orderColumn,
        orderType
      );
    }
  };

  const getstudents_csv_export = async type => {
    try {
      let queryString;
      const searchParams = {
        search,
        furigana: selectedKatakana,
        page: pageNumber,
        per_page: memberPerPage,
        active_member: isActiveMembers,
        order_column: orderColumn,
        order_type: orderType ? "asc" : "desc",
      };
      if (type === "all") {
        queryString = Object.entries({ ...advanceSearch, ...searchParams })
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");
      } else {
        queryString =
          Object.entries({ ...advanceSearch, ...searchParams })
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&") +
          `&columns=${checkedColumnList.join(",")}` +
          `&headerColumns=${checkedColumnList.map(c => checkboxes.find(b => b.value === c).label).join(",")}`;
      }
      const response = await privateApi.get("admin/members-csv?" + queryString);
      if (response.status === 200) {
        return response.data; // Return the data
      } else {
        console.log("Server Error");
        return []; // Return an empty array in case of an error
      }
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      return []; // Return an empty array in case of an error
    }
  };

  async function download_table_as_csv(
    table_id,
    students_csv_export,
    separator = ","
  ) {
    var filename = table_id + "_" + moment().format("YYYYMMDD") + ".csv";

    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(students_csv_export)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async (table_id, type) => {
    startLoading();
    try {
      const students_csv_export = await getstudents_csv_export(type); // Fetch the data
      await download_table_as_csv(table_id, students_csv_export); // Pass the data to the download function
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
    stopLoading();
  };

  const handleActiveBtn = async () => {
    const activeStatus = isActiveMembers === 1 ? 2 : 1;

    setIsActiveMembers(activeStatus);
    setHasMore(true);
    setStudents([]);
    setTotalStudents(0);
    getStudents(1, activeStatus, selectedKatakana, search);
  };

  const handleDeleteClick = id => {
    setselectedStudentIDForDelete(id);
    setShowModal(true);
  };

  const deleteStudent = async id => {
    const response = await privateApi.delete(`admin/members/${id}`);

    if (response.status === 200) {
      swal("削除しました！", response.data.message, "success");
      setShowModal(false);
      getStudents(1, isActiveMembers, selectedKatakana, search);
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const handleCloseModal = () => {
    setAdvanceSearchModal(false);
  };
  const handleCloseDeleteModal = () => {
    setShowModal(false);
  };

  const updatePageHandler = id => {
    history.push(`/admin/student/details/${id}?action=view&key=tab1`);
  };

  const [advanceSearchModal, setAdvanceSearchModal] = useState(false);
  const [advanceSearch, setAdvanceSearch] = useState({
    member_code_start: "",
    member_code_end: "",
    gender: "",
    birthday_start: "",
    birthday_end: "",
    joining_date_start: "",
    joining_date_end: "",
    member_drop_out_start: "",
    member_drop_out_end: "",
    district_code: "",
    telePhone_1: "",
    telePhone_2: "",
    family_member_id: "",
    charge_type: "",
    remarks: "",
    payment_category: "",
    bank_code: "",
    member: 1,
    affiliation: "",
    times: "",
    class_from: "",
    class_to: "",
    day: "",
    time: "",
    bus_user: "",
    bus_course: "",
    start_time: "",
    specialStudy: false,
  });

  const handleAdvanceSearchButton = () => {
    setAdvanceSearchModal(true);
  };

  const handleAdvanceSearch = async () => {
    setAdvanceSearchModal(false);
    setSelectedKatakana([]);
    setLoader(true);
    setStudents([]);
    setIsActiveMembers(advanceSearch.member);
    getStudents(
      1,
      advanceSearch?.member,
      "",
      "",
      false,
      orderColumn,
      orderType
    );
  };

  const handleSort = value => {
    setOrderType(prevOrderType => !prevOrderType);
    setOrderColumn(value);
  };

  const [columns, setColumns] = useState([]);

  const updatedColumns = useMemo(
    () => [
      {
        title: (
          <div
            onClick={() => handleSort("member_code")}
            style={{ cursor: "pointer", display: "flex" }}
          >
            <div>会員番号</div>&nbsp;&nbsp;
            <div className="ml-auto">
              {orderColumn == "member_code" ? (
                orderType ? (
                  <HiChevronUp style={{ fontSize: "22px" }} />
                ) : (
                  <HiChevronDown style={{ fontSize: "22px" }} />
                )
              ) : (
                <HiChevronUpDown style={{ fontSize: "22px" }} />
              )}
            </div>
          </div>
        ),

        dataIndex: "member_code",
        key: "member_code",
        width: 110,
        fixed: "left",
        render: (text, record) => (
          <Link to={`/admin/student/details/${record.id}?action=view&key=tab1`}>
            {text}
          </Link>
        ),
        sorter: false,
      },
      {
        title: (
          <div
            onClick={() => handleSort("name")}
            style={{ cursor: "pointer", display: "flex" }}
          >
            <div>氏名</div>&nbsp;&nbsp;
            <div className="ml-auto">
              {orderColumn == "name" ? (
                orderType ? (
                  <HiChevronUp style={{ fontSize: "22px" }} />
                ) : (
                  <HiChevronDown style={{ fontSize: "22px" }} />
                )
              ) : (
                <HiChevronUpDown style={{ fontSize: "22px" }} />
              )}
            </div>
          </div>
        ),
        dataIndex: "name",
        key: "name",
        width: 150,
        fixed: "left",
        sorter: false,
        render: (text, record) => <span>{Nvh({ value: text })}</span>,
      },
      {
        title: (
          <div
            onClick={() => handleSort("furigana")}
            style={{ cursor: "pointer", display: "flex" }}
          >
            <div>フリガナ</div>&nbsp;&nbsp;
            <div className="ml-auto">
              {orderColumn == "furigana" ? (
                orderType ? (
                  <HiChevronUp style={{ fontSize: "22px" }} />
                ) : (
                  <HiChevronDown style={{ fontSize: "22px" }} />
                )
              ) : (
                <HiChevronUpDown style={{ fontSize: "22px" }} />
              )}
            </div>
          </div>
        ),
        dataIndex: "furigana",
        key: "furigana",
        width: 150,
        fixed: "left",
        sorter: false,
        render: (text, record) => (
          <span>{Nvh({ value: record.furigana })}</span>
        ),
      },
      {
        title: "性別",
        dataIndex: "gender",
        key: "gender",
        width: 60,
        render: (text, record) => <span>{Nvh({ value: record.gender })}</span>,
        sorter: (a, b) => a.gender.localeCompare(b.gender),
      },
      /*
      {
        title: (
          <div
            onClick={() => handleSort("birthday")}
            style={{ cursor: "pointer", display: "flex" }}
          >
            <div>生年月日</div> &nbsp;&nbsp;
            <div className="ml-auto">
              {orderColumn == "birthday" ? (
                orderType ? (
                  <HiChevronUp style={{ fontSize: "22px" }} />
                ) : (
                  <HiChevronDown style={{ fontSize: "22px" }} />
                )
              ) : (
                <HiChevronUpDown style={{ fontSize: "22px" }} />
              )}
            </div>
          </div>
        ),
        dataIndex: "birthday",
        key: "birthday",
        width: 120,
        render: (text, record) => (
          <span>{DateForm({ value: record.birthday })}</span>
        ),
        sorter: false,
      },
      */
      {
        title: "1_所属名",
        dataIndex: "class_name_1",
        key: "class_name_1",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record.class_name_1 })}</span>
        ),
        sorter: (a, b) => a.class_name_1.localeCompare(b.class_name_1),
      },
      {
        title: "1_回数",
        dataIndex: "class_times_1",
        key: "class_times_1",
        width: 80,
        render: (text, record) => (
          <span>{Nvh({ value: record.class_times_1 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.class_times_1);
          const bTimes = String(b.class_times_1);
          return aTimes.localeCompare(bTimes);
        },
      },

      {
        title: "1_級",
        dataIndex: "class_grade_1",
        key: "class_grade_1",
        width: 60,
        render: (text, record) => (
          <span>
            {Nvh({
              value:
                record.class_grade_1 === "0" ? "特習" : record.class_grade_1,
            })}
          </span>
        ),
        sorter: (a, b) => a.class_grade_1.localeCompare(b.class_grade_1),
      },

      {
        title: "1_曜日時間",
        dataIndex: "class_schedule_1",
        key: "class_schedule_1",
        width: 200,
        render: (text, record) => (
          <span>{Nvh({ value: record?.class_schedule_1 })}</span>
        ),
        sorter: (a, b) => a.class_schedule_1.localeCompare(b.class_schedule_1),
      },
      /*
      {
        title: "2_所属名",
        dataIndex: "class_name_2",
        key: "class_name_2",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.class_name_2 })}</span>
        ),
        sorter: (a, b) => a.class_name_2.localeCompare(b.class_name_2),
      },
      {
        title: "2_回数",
        dataIndex: "class_times_2",
        key: "class_times_2",
        width: 80,
        render: (text, record) => (
          <span>{Nvh({ value: record?.class_times_2 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.class_times_2);
          const bTimes = String(b.class_times_2);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "2_級",
        dataIndex: "class_grade_2",
        key: "class_grade_2",
        width: 60,
        render: (text, record) => (
          <span>{Nvh({ value: record?.class_grade_2 })}</span>
        ),
        sorter: (a, b) => a.class_grade_2.localeCompare(b.class_grade_2),
      },
      {
        title: "2_曜日時間",
        dataIndex: "class_schedule_2",
        key: "class_schedule_2",
        width: 200,
        render: (text, record) => (
          <span>{Nvh({ value: record?.class_schedule_2 })}</span>
        ),
        sorter: (a, b) => a.class_schedule_2.localeCompare(b.class_schedule_2),
      },
      {
        title: "3_所属名",
        dataIndex: "class_name_3",
        key: "class_name_3",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.class_name_3 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.class_name_3);
          const bTimes = String(b.class_name_3);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "3_回数",
        dataIndex: "class_times_3",
        key: "class_times_3",
        width: 80,
        render: (text, record) => (
          <span>{Nvh({ value: record?.class_times_3 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.class_times_3);
          const bTimes = String(b.class_times_3);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "3_級",
        dataIndex: "class_grade_3",
        key: "class_grade_3",
        width: 80,
        render: (text, record) => (
          <span>{Nvh({ value: record?.class_grade_3 })}</span>
        ),
        sorter: (a, b) => a.class_grade_3.localeCompare(b.class_grade_3),
      },
      {
        title: "3_曜日時間",
        dataIndex: "class_schedule_3",
        key: "class_schedule_3",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.class_schedule_3 })}</span>
        ),
        sorter: (a, b) => a.class_schedule_3.localeCompare(b.class_schedule_3),
      },
      {
        title: (
          <div
            onClick={() => handleSort("zip_code")}
            style={{ cursor: "pointer", display: "flex" }}
          >
            <div>郵便番号</div> &nbsp;&nbsp;
            <div className="ml-auto">
              {orderColumn == "zip_code" ? (
                orderType ? (
                  <HiChevronUp style={{ fontSize: "22px" }} />
                ) : (
                  <HiChevronDown style={{ fontSize: "22px" }} />
                )
              ) : (
                <HiChevronUpDown style={{ fontSize: "22px" }} />
              )}
            </div>
          </div>
        ),
        dataIndex: "zip_code",
        key: "zip_code",
        width: 120,
        render: (text, record) => (
          <span>{Nvh({ value: record?.zip_code })}</span>
        ),
        sorter: false,
      },
      {
        title: (
          <div
            onClick={() => handleSort("address_1")}
            style={{ cursor: "pointer", display: "flex" }}
          >
            <div>住所1</div> &nbsp; &nbsp;
            <div className="ml-auto">
              {orderColumn == "address_1" ? (
                orderType ? (
                  <HiChevronUp style={{ fontSize: "22px" }} />
                ) : (
                  <HiChevronDown style={{ fontSize: "22px" }} />
                )
              ) : (
                <HiChevronUpDown style={{ fontSize: "22px" }} />
              )}
            </div>
          </div>
        ),
        dataIndex: "address_1",
        key: "address_1",
        width: 200,
        render: (text, record) => (
          <span>{Nvh({ value: record?.address_1 })}</span>
        ),
        sorter: false,
      },
      {
        title: "住所2",
        dataIndex: "address_2",
        key: "address_2",
        width: 200,
        render: (text, record) => (
          <span>{Nvh({ value: record?.address_2 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.address_2);
          const bTimes = String(b.address_2);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "電話番号1",
        dataIndex: "phone_no_1",
        key: "phone_no_1",
        width: 140,
        render: (text, record) => (
          <span>{Nvh({ value: record?.phone_no_1 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.phone_no_1);
          const bTimes = String(b.phone_no_1);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "電話備考1",
        dataIndex: "phone_note_1",
        key: "phone_note_1",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.phone_note_1 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.phone_note_1);
          const bTimes = String(b.phone_note_1);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "電話番号2",
        dataIndex: "phone_no_2",
        key: "phone_no_2",
        width: 140,
        render: (text, record) => (
          <span>{Nvh({ value: record?.phone_no_2 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.phone_no_2);
          const bTimes = String(b.phone_no_2);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "電話備考2",
        dataIndex: "phone_note_2",
        key: "phone_note_2",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.phone_note_2 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.phone_note_2);
          const bTimes = String(b.phone_note_2);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "メールアドレス1",
        dataIndex: "mail_address_1",
        key: "mail_address_1",
        width: 350,
        render: (text, record) => (
          <span>{Nvh({ value: record?.mail_address_1 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.mail_address_1);
          const bTimes = String(b.mail_address_1);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "メール備考1",
        dataIndex: "mail_note_1",
        key: "mail_note_1",
        width: 200,
        render: (text, record) => (
          <span>{Nvh({ value: record?.mail_note_1 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.mail_note_1);
          const bTimes = String(b.mail_note_1);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "メールアドレス2",
        dataIndex: "mail_address_2",
        key: "mail_address_2",
        width: 150,
        render: (text, record) => (
          <span>{Nvh({ value: record?.mail_address_2 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.mail_address_2);
          const bTimes = String(b.mail_address_2);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "メール備考2",
        dataIndex: "mail_note_2",
        key: "mail_note_2",
        width: 150,
        render: (text, record) => (
          <span>{Nvh({ value: record?.mail_note_2 })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.mail_note_2);
          const bTimes = String(b.mail_note_2);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "家族会員番号",
        dataIndex: "family_member_id",
        key: "family_member_id",
        width: 140,
        render: (text, record) => (
          <span>{Nvh({ value: record?.family_member_id })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.family_member_id);
          const bTimes = String(b.family_member_id);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "緊急連絡氏名",
        dataIndex: "em_last_name",
        key: "em_last_name",
        width: 150,
        render: (text, record) => (
          <span>{Nvh({ value: record?.em_last_name })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.em_last_name);
          const bTimes = String(b.em_last_name);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "緊急連絡：フリガナ",
        dataIndex: "em_last_name_furigana",
        key: "em_last_name_furigana",
        width: 160,
        render: (text, record) => (
          <span>{Nvh({ value: record?.em_last_name_furigana })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.em_last_name_furigana);
          const bTimes = String(b.em_last_name_furigana);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "電話番号（緊急連絡）",
        dataIndex: "em_phone_no",
        key: "em_phone_no",
        width: 200,
        render: (text, record) => (
          <span>{Nvh({ value: record?.em_phone_no })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.em_phone_no);
          const bTimes = String(b.em_phone_no);
          return aTimes.localeCompare(bTimes);
        },
      },
      */
      {
        title: "バスコース番号",
        dataIndex: "bus.bus_course_number",
        key: "bus_course_number",
        width: 180,
        render: (text, record) => (
          <span>{Nvh({ value: record?.bus?.bus_course_number })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.bus_course_number);
          const bTimes = String(b.bus_course_number);
          return aTimes.localeCompare(bTimes);
        },
      },
      /*
      {
        title: "バス往路",
        dataIndex: "outbound_bus",
        key: "outbound_bus",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.outbound_bus })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.outbound_bus);
          const bTimes = String(b.outbound_bus);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "バス復路",
        dataIndex: "return_bus",
        key: "return_bus",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.return_bus })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.return_bus);
          const bTimes = String(b.return_bus);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "料金種別コード",
        dataIndex: "charge_type_code",
        key: "charge_type_code",
        width: 150,
        render: (text, record) => (
          <span>{Nvh({ value: record?.charge_type_code })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.charge_type_code);
          const bTimes = String(b.charge_type_code);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "納付区分コード",
        dataIndex: "payment_type_code",
        key: "payment_type_code",
        width: 150,
        render: (text, record) => (
          <span>{Nvh({ value: record?.payment_type_code })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.payment_type_code);
          const bTimes = String(b.payment_type_code);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: (
          <div
            onClick={() => handleSort("bank_code")}
            style={{ cursor: "pointer", display: "flex" }}
          >
            <div>銀行コード</div> &nbsp; &nbsp;
            <div className="ml-auto">
              {orderColumn == "bank_code" ? (
                orderType ? (
                  <HiChevronUp style={{ fontSize: "22px" }} />
                ) : (
                  <HiChevronDown style={{ fontSize: "22px" }} />
                )
              ) : (
                <HiChevronUpDown style={{ fontSize: "22px" }} />
              )}
            </div>
          </div>
        ),
        dataIndex: "bank_code",
        key: "bank_code",
        width: 120,
        render: (text, record) => (
          <span>{Nvh({ value: record?.bank_code })}</span>
        ),
        sorter: false,
      },
      {
        title: "銀行コード",
        dataIndex: "bank_code",
        key: "bank_code",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.bank_code })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.bank_code);
          const bTimes = String(b.bank_code);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "支店コード",
        dataIndex: "branch_code",
        key: "branch_code",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.branch_code })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.branch_code);
          const bTimes = String(b.branch_code);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "預金種目",
        dataIndex: "deposit_item",
        key: "deposit_item",
        width: 90,
        render: (text, record) => (
          <span>{Nvh({ value: record?.deposit_item })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.deposit_item);
          const bTimes = String(b.deposit_item);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "口座番号",
        dataIndex: "account_no",
        key: "account_no",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.account_no })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.account_no);
          const bTimes = String(b.account_no);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "口座名義人",
        dataIndex: "account_holder",
        key: "account_holder",
        width: 100,
        render: (text, record) => (
          <span>{Nvh({ value: record?.account_holder })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.account_holder);
          const bTimes = String(b.account_holder);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "入会日",
        dataIndex: "joining_date",
        key: "joining_date",
        width: 100,
        render: (text, record) => (
          <span>{DateForm({ value: record?.joining_date })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.joining_date);
          const bTimes = String(b.joining_date);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "退校日",
        dataIndex: "leave_date",
        key: "leave_date",
        width: 100,
        render: (text, record) => (
          <span>{DateForm({ value: record?.leave_date })}</span>
        ),
        sorter: (a, b) => {
          const aTimes = String(a.leave_date);
          const bTimes = String(b.leave_date);
          return aTimes.localeCompare(bTimes);
        },
      },
      {
        title: "備考",
        dataIndex: "note",
        key: "note",
        width: 400,
        render: (text, record) => <span>{Nvh({ value: record?.note })}</span>,
        sorter: (a, b) => {
          const aTimes = String(a.note);
          const bTimes = String(b.note);
          return aTimes.localeCompare(bTimes);
        },
      },
      */
      {
        title: "操作",
        key: "action",
        align: "center",
        fixed: "right",
        width: 100,
        render: (text, record) => (
          <div className="text-center">
            <Link
              className="btn btn-sm btn-sm-custom btn-info text-light mx-1"
              to={`/admin/student/details/${record.id}?action=view&key=tab1`}
            >
              <FontAwesomeIcon icon="eye" />
            </Link>
            <Link
              className="btn btn-sm btn-sm-custom btn-primary mx-1"
              to={`/admin/student/details/${record.id}?action=update&key=tab1`}
            >
              <FontAwesomeIcon icon="edit" />
            </Link>
            <button
              className="btn btn-sm btn-sm-custom btn-danger mx-1"
              onClick={() => handleDeleteClick(record.id)}
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          </div>
        ),
      },
    ],
    [orderColumn, orderType]
  );

  useEffect(() => {
    setColumns(updatedColumns);
  }, [updatedColumns]);

  useEffect(() => {
    const getGenerelRes = async e => {
      const month = getCurrentMonth();
  
      const response = await privateApi.get(
        `admin/general-resources?type=bus,bank,member_code&processed_date=${moment(month).format("YYYY-MM")}`
      );
      if (response.status === 200) {
        setBuses(response.data.bus);
      } else {
        console.log("Server Error");
      }
    };

    getGenerelRes();
  }, []);

  const handleResize =
    index =>
    (e, { size }) => {
      setColumns(columns => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = record => {
    setSelectedStudentId(record?.id);
    const key = record.id;
    setSelectedRowKey(prevSelectedRowKey =>
      prevSelectedRowKey === key ? null : key
    );
  };

  const rowClassName = record => {
    return selectedRowKey === record.id ? "table-selected-row" : "";
  };

  const busCourseUpdatedStudents = () => {
    const newStudents = JSON.parse(JSON.stringify(students));

    newStudents.map(student => {
      if (student?.bus?.id) {
        const latestBusId = buses.find(bus => {
          return bus?.id === student?.bus?.id;
        })?.id;

        student.bus.id = latestBusId;
      }

      return student;
    })

    return newStudents;
  };

  const dataTable = (
    <div>
      <ConfigProvider theme={customTheme}>
        <Table
          key={tableKey}
          className="table-striped-rows"
          bordered
          dataSource={busCourseUpdatedStudents()}
          columns={resizableColumns}
          scroll={{ x: 1500, y: "65vh" }}
          components={components}
          loading={loader}
          rowKey="id"
          pagination={false}
          rowClassName={rowClassName}
          onRow={record => ({
            onClick: () => handleRowClick(record),
            onDoubleClick: () => updatePageHandler(record.id),
          })}
        />
      </ConfigProvider>
    </div>
  );

  const getStudents = async (
    pageNumber,
    active,
    katakana,
    searcher,
    scroll = false,
    order_column = "",
    order_type = ""
  ) => {
    setLoader(true);
    const searchParams = {
      search: searcher,
      furigana: katakana,
      page: pageNumber,
      per_page: memberPerPage,
      active_member: active,
      order_column: order_column,
      order_type: order_type ? "asc" : "desc",
    };

    const queryString = Object.entries({ ...advanceSearch, ...searchParams })
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    try {
      const response = await privateApi.get(`admin/members?${queryString}`);
      if (response.status === 200) {
        isFetching = false;
        setLoader(false);
        if (scroll) {
          if (response.data.data.length === 0) {
            setHasMore(false);
          } else {
            setStudents(prevStudents => [
              ...prevStudents,
              ...response.data.data,
            ]);
          }
        } else {
          setTableKey(!tableKey);
          setHasMore(true);
          setStudents(response.data.data);
        }

        setTotalStudents(response?.data?.total_members);
        setPageNumber(pageNumber + 1);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleScroll = e => {
    const target = e.target;
    const distanceFromBottom =
      target.scrollHeight - target.scrollTop - target.clientHeight;
    if (distanceFromBottom <= 300 && hasMore && !isFetching && !loader) {
      isFetching = true;
      getStudents(
        pageNumber,
        isActiveMembers,
        selectedKatakana,
        search,
        true,
        orderColumn,
        orderType
      );
    }
  };

  useEffect(() => {
    const container = document.querySelector(".ant-table-body");
    container && container.addEventListener("scroll", handleScroll);
    return () => {
      container && container.removeEventListener("scroll", handleScroll);
    };
  }, [
    pageNumber,
    hasMore,
    loader,
    isActiveMembers,
    selectedKatakana,
    search,
    orderColumn,
    isFetching,
  ]);

  useEffect(() => {
    if (stickers) {
      if (selectAll) {
        setSelectedStickers([...stickers]);
      } else {
        setSelectedStickers([]);
      }
    }
  }, [selectAll, stickers]);

  useEffect(() => {
    if (location.pathname === "/admin/std-advanced-search") {
      setAdvancedSearchPage(true);
    }
  }, [location]);

  // useEffect(() => {
  //   getStudents(1, false, "", "");
  // }, []);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === "F9") {
        history.push("/admin/student/create");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [history]);

  useEffect(() => {
    const memberSearch = localStorage.getItem("member_search") || "";
    setSearch(memberSearch);

    getStudents(
      1,
      isActiveMembers,
      "",
      memberSearch,
      false,
      orderColumn,
      orderType
    );
  }, [orderType, isActiveMembers, orderColumn]);

  const handleCheckboxChange = sticker => {
    const selectedIndex = selectedStickers.findIndex(
      selectedSticker => selectedSticker.member_code === sticker.member_code
    );
    if (selectedIndex > -1) {
      const updatedSelectedStickers = [...selectedStickers];
      updatedSelectedStickers.splice(selectedIndex, 1);
      setSelectedStickers(updatedSelectedStickers);
    } else {
      setSelectedStickers([...selectedStickers, sticker]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  const StickerDwnldHandler = () => {
    setStickerDwnldModal(true);
    getStudents2(
      1,
      isActiveMembers,
      selectedKatakana,
      search,
      false,
      orderColumn,
      orderType
    );
  };

  const getStudents2 = async (
    pageNumber,
    active,
    katakana,
    searcher,
    scroll = false,
    order_column = "",
    order_type = ""
  ) => {
    setStickerLoader(true);

    const searchParams = {
      search: searcher,
      furigana: katakana,
      page: pageNumber,
      per_page: 100000,
      active_member: active,
      order_column: order_column,
      order_type: order_type ? "asc" : "desc",
    };

    const queryString = Object.entries({ ...advanceSearch, ...searchParams })
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    try {
      const response = await privateApi.get(`admin/members?${queryString}`);

      if (response.status === 200) {
        setStickerLoader(false);
        console.log("sticker list", response.data.data);
        setStickers(response.data.data);
        // printReport(response.data.data);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  function splitTextIntoLines(text, maxLength) {
    let lines = [];
    while (text.length > maxLength) {
      let lastSpaceIndex = text.lastIndexOf(' ', maxLength);
      if (lastSpaceIndex === -1) lastSpaceIndex = maxLength;
      lines.push(text.slice(0, lastSpaceIndex));
      text = text.slice(lastSpaceIndex + 1);
    }
    lines.push(text);
    return lines;
  }



  const printSticker = studentList => {
    const marginTop = 14.8;
    const marginBottom = 17;
    const pageWidth = 210;
    const pageHeight = 295;
    const columnWidth = 70;
    const rowHeight = 38.1;

    const pdf = new jsPDF("p", "mm", "a4");
    let positionY = marginTop; // Start from the top margin
    let remainingHeight = pageHeight - marginTop - marginBottom;

    // Load Japanese font
    pdf.addFont("/fonts/mplus-1c-regular.ttf", "japanese", "bold");
    pdf.setFont("japanese", "bold"); // set font
    pdf.setFontSize(10);

    const totalCells = 7 * 3;
    const numStudents = studentList.length;

    // Loop through each student
    for (let studentIndex = 0; studentIndex < numStudents; studentIndex++) {
      const student = studentList[studentIndex];

      const rowIndex = Math.floor(studentIndex / 3) % 7;
      const colIndex = studentIndex % 3;
      const xPos = colIndex * columnWidth;
      const yPos = positionY + rowIndex * rowHeight;
      pdf.rect(xPos, yPos, columnWidth, rowHeight, "S");

      const class_name =
        student.class_name_1.toString() +
        (student.class_name_2 ? "、" + student.class_name_2.toString() : "") +
        (student.class_name_3 ? "、" + student.class_name_3.toString() : "");

      const class_rank =
        (student.class_grade_1 ? student.class_grade_1.toString() : "-") +
        (student.class_name_2 && student.class_grade_2
          ? "、" + student.class_grade_2.toString()
          : student.class_name_2
            ? "、-"
            : "") +
        (student.class_name_3 && student.class_grade_3
          ? "、-" + student.class_grade_3.toString()
          : student.class_name_3
            ? "、-"
            : "");

      const originalFontSize = pdf.internal.getFontSize();

      if (student) {
        if (stickerPattern === 1) {
          pdf.text("〒 " + student.zip_code.toString(), xPos + 3, yPos + 5);
      
          let address1Lines = splitTextIntoLines(student.address_1.toString(), 20); // Change 30 to your max length
          address1Lines.forEach((line, index) => {
            pdf.text(line, xPos + 3, yPos + 12 + index * 4);
          });
      
          let address2StartY = yPos + 12 + address1Lines.length * 4;
      
          if (student.address_2 && student.address_2 != "null") {
            let address2Lines = splitTextIntoLines(student.address_2.toString(), 30); // Change 30 to your max length
            address2Lines.forEach((line, index) => {
              pdf.text(line, xPos + 3, address2StartY + index * 4);
            });
            address2StartY += address2Lines.length * 4;
          }
      
          pdf.setFontSize(13);
          let nameYPos = address2StartY + 3;
          pdf.text(student.name.toString() + " 様", xPos + 5, nameYPos);
          pdf.setFontSize(originalFontSize);
      
          let memberCodeYPos = nameYPos + 5;
          pdf.text(
            "(" + student.member_code.toString() + " " + class_name + "         " + class_rank + " 級)",
            xPos + 3,
            memberCodeYPos
          );
          pdf.text(student.class_day_1.toString(), xPos + 5, memberCodeYPos + 7);
        } else if (stickerPattern === 2) {
          pdf.text("〒 " + student.zip_code.toString(), xPos + 5, yPos + 10);
      
          let address1Lines = splitTextIntoLines(student.address_1.toString(), 30); // Change 30 to your max length
          address1Lines.forEach((line, index) => {
            pdf.text(line, xPos + 5, yPos + 17 + index * 4);
          });
      
          let address2StartY = yPos + 17 + address1Lines.length * 4;
      
          if (student.address_2 && student.address_2 != "null") {
            let address2Lines = splitTextIntoLines(student.address_2.toString(), 30); // Change 30 to your max length
            address2Lines.forEach((line, index) => {
              pdf.text(line, xPos + 5, address2StartY + index * 4);
            });
            address2StartY += address2Lines.length * 4;
          }
      
          pdf.setFontSize(13);
          let nameYPos = address2StartY + 5;
          pdf.text(student.name.toString() + " 様", xPos + 9, nameYPos);
          pdf.setFontSize(originalFontSize);
        } else if (stickerPattern === 3) {
          pdf.text("〒 " + student.zip_code.toString(), xPos + 4, yPos + 5);
      
          let address1Lines = splitTextIntoLines(student.address_1.toString(), 30); // Change 30 to your max length
          address1Lines.forEach((line, index) => {
            pdf.text(line, xPos + 4, yPos + 12 + index * 4);
          });
      
          let address2StartY = yPos + 12 + address1Lines.length * 4;
      
          if (student.address_2 && student.address_2 != "null") {
            let address2Lines = splitTextIntoLines(student.address_2.toString(), 30); // Change 30 to your max length
            address2Lines.forEach((line, index) => {
              pdf.text(line, xPos + 4, address2StartY + index * 4);
            });
            address2StartY += address2Lines.length * 4;
          }
      
          pdf.setFontSize(13);
          let nameYPos = address2StartY + 5;
          pdf.text(student.name.toString() + " 様", xPos + 8, nameYPos);
          pdf.setFontSize(originalFontSize);
      
          let memberCodeYPos = nameYPos + 5;
          pdf.text(
            "(" + student.member_code.toString() + " " + class_name + "       " + class_rank + " 級)",
            xPos + 4,
            memberCodeYPos
          );
        } else {
          swal("パターンを 1 つ選択してください", "", "warning");
        }
      }
      

      if ((studentIndex + 1) % totalCells === 0 && studentIndex !== 0) {
        pdf.addPage();
        positionY = marginTop;
        remainingHeight = pageHeight - marginTop - marginBottom;
      } else {
        remainingHeight -= rowHeight;
      }
    }

    // Generate filename
    const nowDate = moment(new Date()).format("YYYY-MM-DD");
    const pdfName = "member_sticker" + nowDate + ".pdf";

    pdf.save(pdfName);
  };

  const listContent = (
    <div style={{ position: "relative" }}>
      <div className="d-flex justify-content-end align-items-center upper-container">
        <div className="d-flex flex-row">
          {advancedSearchPage && (
            <div
              className="d-flex align-items-center"
              style={{ marginRight: "12px" }}
            >
              <button
                className="btn btn-sm btn-primary px-3 ml-auto"
                variant="primary"
              >
                <i className="fas fa-search fa-fw"></i> 詳細検索
              </button>
            </div>
          )}
          <div className="d-flex align-items-center"></div>
        </div>
      </div>

      <div className="py-1">
        <div className="border px-2">
          <div className="row member-options">
            <div className="py-2 part-1 col-md-12 pr-0">
              <input
                className="form-control"
                type="text"
                placeholder="会員番号／氏名／フリガナで検索"
                value={search}
                onChange={e => {
                  setSearch(e.target.value);
                  debouncedSearch(e.target.value);
                }}
                style={{ padding: "3px" }}
              />
            </div>

            <div
              className="py-2 part-2 col-md-12"
              style={{ paddingLeft: "5px" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="custom-btn-container">
                  <button
                    type="submit"
                    onClick={() => searchStudentByID(search)}
                    className="btn btn-sm btn-primary"
                  >
                    検索
                  </button>
                </div>

                <div className="custom-btn-container">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() =>
                      history.push("/admin/stc/short-term-student-list")
                    }
                  >
                    短期
                  </button>
                </div>

                <div className="custom-btn-container">
                  <button
                    onClick={handleAdvanceSearchButton}
                    type="button"
                    className="btn btn-sm btn-primary"
                  >
                    検索条件
                  </button>
                </div>

                <div className="custom-btn-container">
                  <Link
                    className="btn btn-sm btn-primary"
                    to="/admin/student/create"
                  >
                    新規 [F9]
                  </Link>
                </div>

                <div className="custom-btn-container">
                  <Link
                    className="btn btn-sm btn-primary"
                    to={`/admin/rank/rank-management/${selectedStudentId}`}
                  >
                    進級
                  </Link>
                </div>

                <AntModal
                  width={"800px"}
                  open={isOpen}
                  onCancel={close}
                  footer={null}
                  title={"CSVでダウンロード"}
                >
                  <Row>
                    <Checkbox.Group
                      value={checkedColumnList}
                      onChange={list => {
                        setCheckedColumnList(list);
                      }}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr ",
                      }}
                      options={
                        busCourseUpdatedStudents()[0]
                          ? checkboxes.map(v => ({
                              value: v.value,
                              label: v.label,
                            }))
                          : []
                      }
                    />
                  </Row>
                  <AntButton
                    onClick={() => handleDownloadCSV("会員一覧", "all")}
                    className="mt-2"
                    type="primary"
                  >
                    全てダウンロード
                  </AntButton>
                  <AntButton
                    onClick={() => handleDownloadCSV("会員一覧", "columns")}
                    className="ml-5"
                  >
                    選択した項目をダウンロード
                  </AntButton>
                </AntModal>

                <div className="custom-btn-container">
                  <button
                    type="button"
                    onClick={() => {
                      open();
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    <i className="fas fa-download fa-fw"></i>
                    CSV
                  </button>
                </div>
                <div className="custom-btn-container">
                  <button
                    type="button"
                    onClick={() => StickerDwnldHandler()}
                    className="btn btn-sm btn-primary"
                  >
                    <i className="fas fa-print fa-fw"></i>
                    宛名印刷
                  </button>
                </div>
              </div>
            </div>

            <div className="row part-3 col-md-12 py-2">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="custom-btn-container">
                  <button type="button" className="btn btn-sm btn-primary">
                    {" "}
                    合計
                    <Badge bg="secondary">{totalStudents}</Badge>
                  </button>
                </div>

                <div className="custom-btn-container">
                  <Form className="mem-checkbox">
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="退校者"
                      checked={isActiveMembers == 2} // Check if isActiveMembers is equal to 2
                      onClick={handleActiveBtn}
                    />
                  </Form>
                </div>

                <div
                  className="custom-btn-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <button
                    onClick={() =>
                      searchStudentByKatakana_name("ア,イ,ウ,エ,オ")
                    }
                    className={`btn btn-sm p-1 char-btn ${
                      selectedKatakana.includes("ア")
                        ? "btn-selected"
                        : "btn-primary"
                    }`}
                  >
                    ア
                  </button>
                  <button
                    className={`btn btn-sm p-1 char-btn ${
                      selectedKatakana.includes("カ")
                        ? "btn-selected"
                        : "btn-primary"
                    }`}
                    onClick={() =>
                      searchStudentByKatakana_name("カ,キ,ク,ケ,コ")
                    }
                  >
                    カ
                  </button>
                  <button
                    className={`btn btn-sm p-1 char-btn ${
                      selectedKatakana.includes("サ")
                        ? "btn-selected"
                        : "btn-primary"
                    }`}
                    onClick={() =>
                      searchStudentByKatakana_name("サ,シ,ス,セ,ソ")
                    }
                  >
                    サ
                  </button>
                  <button
                    className={`btn btn-sm p-1 char-btn ${
                      selectedKatakana.includes("タ")
                        ? "btn-selected"
                        : "btn-primary"
                    }`}
                    onClick={() =>
                      searchStudentByKatakana_name("タ,チ,ツ,テ,ト")
                    }
                  >
                    タ
                  </button>
                  <button
                    className={`btn btn-sm p-1 char-btn ${
                      selectedKatakana.includes("ナ")
                        ? "btn-selected"
                        : "btn-primary"
                    }`}
                    onClick={() =>
                      searchStudentByKatakana_name("ナ,ニ,ヌ,ネ,ノ")
                    }
                  >
                    ナ
                  </button>
                  <button
                    className={`btn btn-sm p-1 char-btn ${
                      selectedKatakana.includes("ハ")
                        ? "btn-selected"
                        : "btn-primary"
                    }`}
                    onClick={() =>
                      searchStudentByKatakana_name("ハ,ヒ,フ,ヘ,ホ")
                    }
                  >
                    ハ
                  </button>
                  <button
                    className={`btn btn-sm p-1 char-btn ${
                      selectedKatakana.includes("マ")
                        ? "btn-selected"
                        : "btn-primary"
                    }`}
                    onClick={() =>
                      searchStudentByKatakana_name("マ,ミ,ム,メ,モ")
                    }
                  >
                    マ
                  </button>
                  <button
                    className={`btn btn-sm p-1 char-btn ${
                      selectedKatakana.includes("ヤ")
                        ? "btn-selected"
                        : "btn-primary"
                    }`}
                    onClick={() => searchStudentByKatakana_name("ヤ,ユ,ヨ")}
                  >
                    ヤ
                  </button>
                  <button
                    className={`btn btn-sm p-1 char-btn ${
                      selectedKatakana.includes("ラ")
                        ? "btn-selected"
                        : "btn-primary"
                    }`}
                    onClick={() =>
                      searchStudentByKatakana_name("ラ,リ,ル,レ,ロ")
                    }
                  >
                    ラ
                  </button>
                  <button
                    className={`btn btn-sm p-1 char-btn ${
                      selectedKatakana.includes("ワ")
                        ? "btn-selected"
                        : "btn-primary"
                    }`}
                    onClick={() => searchStudentByKatakana_name("ワ")}
                  >
                    ワ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="card-body">{dataTable}</div>
      </div>

      <div className="row">
        <div className="text-end">
          <button
            className="btn btn-sm btn-primary ms-2"
            variant="primary"
            onClick={() => {
              localStorage.setItem("member_search", "");
              history.push("/admin/dashboard");
            }}
          >
            戻る
          </button>
        </div>
      </div>

      {busCourseUpdatedStudents() && (
        <div className="paginations d-flex mt-3">
          <div className="d-flex mx-auto neomp"></div>
        </div>
      )}
    </div>
  );

  return (
    <>
      {listContent}

      <Modal show={showModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>削除の確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>消去してもよろしいですか？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            キャンセル
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteStudent(selectedStudentIDForDelete)}
          >
            消去
          </Button>
        </Modal.Footer>
      </Modal>

      {/* advance search modal with advance search button */}
      <Modal size="xl" show={advanceSearchModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>検索条件</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdvanceSearch
            advanceSearchData={advanceSearch}
            setAdvanceSearchData={setAdvanceSearch}
            advanceSearchStart={handleAdvanceSearch}
            advanceSearchValueChecker={setAdvanceSearchHasValue}
            advanceSearchHasValue={advanceSearchHasValue}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            キャンセル
          </Button>
          <Button onClick={handleAdvanceSearch} variant="success">
            検索
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={stickerDwnldModal}
        onHide={() => setStickerDwnldModal(!stickerDwnldModal)}
      >
        <Modal.Header closeButton>
          <>
            <div className="d-flex">
              <div className="mt-1 mx-2">様式:</div>
              <div className="form-check mx-2">
                <input
                  type="radio"
                  className="form-check-input"
                  id="invalidCheck"
                  name="pattern"
                  onChange={() => setStickerPattern(1)}
                  value={1}
                  checked={stickerPattern === 1}
                  required
                />
                <label
                  className="form-check-label mt-1"
                  for="flexRadioDefault1"
                >
                  様式 １
                </label>
              </div>
              <div className="form-check mx-2">
                <input
                  type="radio"
                  className="form-check-input"
                  id="invalidCheck"
                  name="pattern"
                  onChange={() => setStickerPattern(2)}
                  value={2}
                  checked={stickerPattern === 2}
                  required
                />
                <label
                  className="form-check-label mt-1"
                  for="flexRadioDefault2"
                >
                  様式 2
                </label>
              </div>
              <div className="form-check mx-2">
                <input
                  type="radio"
                  className="form-check-input"
                  id="invalidCheck"
                  name="pattern"
                  onChange={() => setStickerPattern(3)}
                  value={3}
                  checked={stickerPattern === 3}
                  required
                />
                <label
                  className="form-check-label mt-1"
                  for="flexRadioDefault3"
                >
                  様式 3
                </label>
              </div>
              <div className="mt-1 mx-2">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => printSticker(selectedStickers)}
                >
                  Download
                </button>
              </div>
            </div>
          </>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          {stickerLoader ? (
            <div className="text-center">
              <img src={loadergif} alt="loader" />
            </div>
          ) : (
            <div className="table-responsive">
              <BootstrapTable
                striped
                bordered
                hover
                className="table w-100"
                responsive="lg"
              >
                <thead>
                  <tr>
                    <th scope="col" className="text-center">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th scope="col">会員番号</th>
                    <th scope="col">氏名</th>
                    <th scope="col">住所</th>
                    <th scope="col">1_所属名</th>
                  </tr>
                </thead>
                <tbody>
                  {stickers &&
                    stickers.map((sticker, i) => (
                      <tr key={i}>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            checked={selectedStickers.some(
                              selectedSticker =>
                                selectedSticker.member_code ===
                                sticker.member_code
                            )}
                            onChange={() => handleCheckboxChange(sticker)}
                          />
                        </td>
                        <td>{sticker.member_code}</td>
                        <td>{sticker.name}</td>
                        <td>{sticker.address_1}</td>
                        <td>{sticker.class_name_1}</td>
                      </tr>
                    ))}
                </tbody>
              </BootstrapTable>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Student;
