import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import publicApi from '../services/publicApi';
import { deleteSchool, saveSchool } from '../services/auth/token';

const useSchoolData = (schoolSlug) => {
    const [school, setSchool] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const fetchSchoolData = async() => {
            try {
                const response = await publicApi.get(`school/${schoolSlug}`);
                if (response.status === 200) {
                    saveSchool(response.data.data.slug);
                    setSchool(response.data.data);
                } else {
                    deleteSchool();
                    history.push("/404");
                }
            } catch (error) {
                deleteSchool();
                history.push("/404");
            }
        };

        fetchSchoolData();
    }, [schoolSlug, history]);

    return school;
};

export default useSchoolData;