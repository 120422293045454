import React, { useEffect, useState } from "react";
import { Button, Form, Tabs, Tab } from "react-bootstrap";
import swal from "sweetalert";
import privateApi from "../../../services/privateApi";
import ReactDatePicker from "react-datepicker";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import "../../../assets/global.css";
import Option2 from "../../extrapage/Page19/Options2";
import Option3 from "../../extrapage/Page19/Options3";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import Nvh from "../../common/Nvh";
import ja from "date-fns/locale/ja";
import InputMask from "react-input-mask";
import ConvertTo from "../../utilis/ConvertKataka";
import Page48UpdatePage from "../../extrapage/Page48UpdatePage";
import { getCurrentMonth } from "../../../services/auth/token";
import moment from "moment";

function CreateShortTermStudent() {
  const history = useHistory();

  const { id } = useParams();
  const [busId, setBusId] = useState("");

  const [errorList, setErrorList] = useState({});
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [joiningDate, setJoiningDate] = useState(new Date());
  const [leaveDate, setLeaveDate] = useState("");
  const [guradianId, setGuardianId] = useState("");
  const [guardianOptions, setGuardianOptions] = useState([]);
  const [classes, setClasses] = useState([]);
  const [courses, seCourses] = useState([]);
  const [buses, setBuses] = useState([]);
  const [banks, setBanks] = useState([]);
  const [branchName, setBranchName] = useState("");
  const [showValidation, setShowValidation] = useState(false);

  const [readOnly, setReadOnly] = useState(true);
  const [key, setKey] = useState("tab1");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action");
  const [currentURL, setCurrentURL] = useState(action);
  const tabKey = searchParams.get("key") ? searchParams.get("key") : "tab1";

  const [newStudents, setNewStudents] = useState({
    member_code: "",
    type: "Short Term",
    school_id: "",
    name: "",
    furigana: "",
    email: "",
    password: "",
    gender: "",
    birthday: startDate.toISOString().split("T")[0],
    district_code: "Default",
    zip_code: "",
    address_1: "",
    address_2: "",
    parent_name: "",
    phone_no_1: "",
    phone_note_1: "",
    phone_no_2: "",
    phone_note_2: "",
    fax_number: "",
    mail_address_1: "",
    mail_category_1: "",
    mail_note_1: "",
    mail_address_2: "",
    mail_category_2: "",
    mail_note_2: "",
    mail_note_3: "",
    family_member_id: "",
    charge_type_code: "",
    payment_type_code: "",
    deposit_item: "",
    account_no: "",
    account_holder: "",
    note: "",
    joining_date: "",
    leave_date: "",
    bus_course_number: "",
    bus: {
      bus_course_number: "",
      outbound_bus: "",
      return_bus: "",
      stop_name: "",
    },
    outbound_bus: "",
    return_bus: "",
    created_by: "",
    status: "",
    password_confirmation: "",
    relation: "",
    bank_code: "",
    branch_code: "",
    em_first_name: "",
    em_last_name_furigana: "",
    em_first_name: "",
    em_last_name_furigana: "",
    em_phone_no: "",
  });

  const handleInput = e => {
    e.persist();
    setErrorList({});
    setNewStudents({ ...newStudents, [e.target.name]: e.target.value });
    //console.log("newStudents", newStudents);
  };

  //Image Upload Handler
  const [file, setFile] = useState("");
  const [imagedata, setImagedata] = useState("");
  const handleChange = file => {
    setFile(URL.createObjectURL(file[0]));
    setImagedata(file[0]);
  };

  const getGuardians = async e => {
    const response = await privateApi.get("admin/guardians");
    if (response.status === 200) {
      setGuardianOptions(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const getStudentInfo = async id => {
    const response = await privateApi.get("/admin/short-terms-members/" + id);

    if (response.status === 200) {
      setNewStudents(response.data.data);
      setBusId(response.data.data?.bus?.id);
      console.log("Student info", response.data.data);
      setStartDate(new Date(response.data.data.birthday));
      setJoiningDate(new Date(response.data.data.joining_date));
      setLeaveDate(
        response.data.data.leave_date
          ? new Date(response.data.data.leave_date)
          : ""
      );
      setShowValidation(true);
    } else {
      console.log("Server Error");
    }
  };

  const getGenerelRes = async e => {
    const month = getCurrentMonth();

    const response = await privateApi.get(
      `admin/general-resources?type=bus,bank,member_code&processed_date=${moment(month).format("YYYY-MM")}`
    );
    if (response.status === 200) {
      setBuses(response.data.bus);
      setBanks(response.data.bank);
    } else {
      console.log("Server Error");
    }
  };

  let initialBank = banks.find(bank => bank.id == newStudents.bank_code);
  let initialBranchName = initialBank ? initialBank.branch_name : "";

  const bankHandler = (bank_code, branch_code, branch_name) => {
    setNewStudents({
      ...newStudents,
      bank_code: bank_code,
      branch_code: branch_code,
    });
    setBranchName(branch_name);
  };

  useEffect(() => {
    getStudentInfo(id);
    getGuardians();
    getGenerelRes();
    setReadOnly(action === "view");
    setKey(tabKey);
  }, [id, action, tabKey]);

  const updateFormSubmit = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("type", "Short Term");
    formData.append("member_code", newStudents.member_code);
    formData.append("name", newStudents.name);
    formData.append("furigana", newStudents.furigana);
    formData.append("email", newStudents.email);
    formData.append("gender", newStudents.gender);
    formData.append("birthday", newStudents.birthday);
    formData.append("district_code", newStudents.district_code);
    formData.append("zip_code", newStudents.zip_code);
    formData.append("address_1", newStudents.address_1);
    formData.append("address_2", newStudents.address_2);
    formData.append("phone_no_1", newStudents.phone_no_1);
    formData.append("phone_note_1", newStudents.phone_note_1);
    formData.append("phone_no_2", newStudents.phone_no_2);
    formData.append("phone_note_2", newStudents.phone_note_2);
    formData.append("fax_number", newStudents.fax_number);
    formData.append("mail_address_1", newStudents.mail_address_1);
    formData.append("mail_category_1", newStudents.mail_category_1);
    formData.append("mail_note_1", newStudents.mail_note_1);
    formData.append("mail_address_2", newStudents.mail_address_2);
    formData.append("mail_category_2", newStudents.mail_category_2);
    formData.append("mail_note_2", newStudents.mail_note_2);
    formData.append("outbound_bus", newStudents.outbound_bus);
    formData.append("return_bus", newStudents.return_bus);
    formData.append("bus_course_number", busId);
    formData.append("em_first_name", newStudents.em_first_name);
    formData.append(
      "em_first_name_furigana",
      newStudents.em_first_name_furigana
    );
    formData.append("em_last_name", newStudents.em_last_name);
    formData.append("em_last_name_furigana", newStudents.em_last_name_furigana);
    formData.append("em_phone_no", newStudents.em_phone_no);

    const response = await privateApi.formUpdate(
      `admin/members/${id}`,
      formData
    );

    if (response.status === 200) {
      // short term student info updated successfully
      swal("短期性更新に成功 ", response.data.message, "success");
      setLoading(false);
      // push to student list page
      history.push("/admin/stc/short-term-student-list");
    } else {
      swal("内容に問題があります", response.data.message, "error");
      setErrorList(response.data.errors);
      setLoading(false);
    }
  };

  // Kanji to Katakana

  const convertAndSetFurigana = async (target_field, given_field, type) => {
    try {
      const convertedText = await ConvertTo(newStudents[given_field], type);
      if (convertedText) {
        setNewStudents({
          ...newStudents,
          [`${target_field}`]: convertedText,
        });
      } else {
        setNewStudents({
          ...newStudents,
          [`${target_field}`]: "",
        });
      }
    } catch (error) {
      console.error(`Error converting ${given_field}`, error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.name) {
        convertAndSetFurigana("furigana", "name", "katakana");
      } else {
        setNewStudents({
          ...newStudents,
          furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.name]);

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.em_first_name) {
        convertAndSetFurigana(
          "em_first_name_furigana",
          "em_first_name",
          "katakana"
        );
      } else {
        setNewStudents({
          ...newStudents,
          em_first_name_furigana: "",
        });
      }
    };
    const timer = setTimeout(fetchData, 500);
    return () => clearTimeout(timer);
  }, [newStudents.em_first_name]);

  const handleUrlChange = url => {
    setCurrentURL(url);
    const newUrl = `/admin/stc/student/details/${id}?action=${url}&key=${key}`;
    history.replace(newUrl);
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === "F2") {
        currentURL === "view"
          ? handleUrlChange("update")
          : handleUrlChange("view");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [id, history, currentURL]);

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === "F10") {
        switch (key) {
          case "tab1":
            setKey("tab2");
            break;
          case "tab2":
            setKey("tab3");
            break;
          case "tab3":
            setKey("tab1");
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [key]);

  const formSection = (
    <span
      className={`${showValidation ? "was-validated" : "needs-validation"}`}
    >
      <div>
        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">短期ID:</label>
          <div className="col-sm-4">
            <input
              className="form-control"
              type="text"
              name="member_code"
              onChange={handleInput}
              value={Nvh({ value: newStudents.member_code })}
              readOnly
            />
          </div>

          <label className="col-sm-2 col-form-label text-sm-end">
            受付番号:
          </label>
          <div className="col-sm-3">
            <input
              className="form-control"
              type="text"
              placeholder="000"
              name="receipt_code"
              onChange={handleInput}
              value={Nvh({ value: newStudents.receipt_code })}
              readOnly
            />
          </div>
        </div>
        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">
            氏名: <span className="required ">*</span>
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              name="name"
              onChange={handleInput}
              value={Nvh({ value: newStudents.name })}
              className={`form-control ${errorList.name ? "is-invalid" : ""}`}
              placeholder={!readOnly ? "名前" : ""}
              readOnly={readOnly}
              required
            />
            <div className="invalid-feedback">
              {errorList.name ? errorList.name : ""}
            </div>
          </div>

          <label className="col-sm-2 col-form-label text-sm-end">
            フリガナ: <span className="required ">*</span>
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              name="furigana"
              onChange={handleInput}
              value={Nvh({ value: newStudents.furigana })}
              className={`form-control ${errorList.furigana ? "is-invalid" : ""
                }`}
              placeholder={!readOnly ? "フリガナ" : ""}
              readOnly={readOnly}
              required
            />
            <div className="invalid-feedback">
              {errorList.furigana ? errorList.furigana : ""}
            </div>
          </div>
        </div>
        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">
            性別: <span className="required ">*</span>
          </label>
          <div className="col-sm-9 pt-2">
            {readOnly ? (
              <>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="validationFormCheck2"
                    name="gender"
                    value="男"
                    checked={newStudents.gender === "男"}
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="validationFormCheck2"
                  >
                    男
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="validationFormCheck2"
                    name="gender"
                    value="女"
                    checked={newStudents.gender === "女"}
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="validationFormCheck2"
                  >
                    女
                  </label>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="validationFormCheck2"
                      name="gender"
                      value="未"
                      checked={newStudents.gender === "未"}
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="validationFormCheck2"
                    >
                      未
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="validationFormCheck2"
                    name="gender"
                    onChange={handleInput}
                    value="男"
                    checked={newStudents.gender === "男"}
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="validationFormCheck2"
                  >
                    男
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="validationFormCheck2"
                    name="gender"
                    onChange={handleInput}
                    value="女"
                    checked={newStudents.gender === "女"}
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="validationFormCheck2"
                  >
                    女
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="validationFormCheck2"
                    name="gender"
                    onChange={handleInput}
                    value="未"
                    checked={newStudents.gender === "未"}
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="validationFormCheck2"
                  >
                    未
                  </label>
                </div>
              </>
            )}
            <div className="invalid-feedback">
              {errorList?.gender ? errorList?.gender : ""}
            </div>
          </div>
        </div>
        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">
            生年月日:
          </label>
          <div className="col-sm-3">
            <ReactDatePicker
              className={`form-control ${errorList.birthday ? "is-invalid" : ""
                }`}
              dateFormat="yyyy/MM/dd"
              selected={startDate}
              required
              readOnly={readOnly}
              locale={ja}
              onChange={date => {
                if (date) {
                  setStartDate(date);
                  const formattedDate = date.toISOString().split("T")[0];
                  setNewStudents({ ...newStudents, birthday: formattedDate });
                }
              }}
            />
            <div className="small text-danger">
              {errorList.birthday ? errorList.birthday : ""}
            </div>
          </div>
        </div>
        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">
            郵便番号: <span className="required ">*</span>
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              name="zip_code"
              onChange={handleInput}
              value={Nvh({ value: newStudents.zip_code })}
              className={`form-control ${errorList.zip_code ? "is-invalid" : ""
                }`}
              placeholder={!readOnly ? "郵便番号" : ""}
              readOnly={readOnly}
            />
            <div className="invalid-feedback">
              {errorList.zip_code ? errorList.zip_code : ""}
            </div>
          </div>
        </div>
        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">
            住所 1: <span className="required">*</span>
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              name="address_1"
              onChange={handleInput}
              value={Nvh({ value: newStudents.address_1 })}
              className={`form-control ${errorList.address_1 ? "is-invalid" : ""
                }`}
              placeholder={!readOnly ? "住所 1" : ""}
              readOnly={readOnly}
            />
            <div className="invalid-feedback">
              {errorList.address_1 ? errorList.address_1 : ""}
            </div>
          </div>

          <label className="col-sm-2 col-form-label text-sm-end">住所 2:</label>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              name="address_2"
              onChange={handleInput}
              value={Nvh({ value: newStudents.address_2 })}
              placeholder={!readOnly ? "住所 2" : ""}
              readOnly={readOnly}
            />
          </div>
        </div>
        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">
            電話番号1:{" "}
            <span
              className="required
"
            >
              *
            </span>
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              name="phone_no_1"
              onChange={handleInput}
              value={Nvh({ value: newStudents.phone_no_1 })}
              className={`form-control ${errorList.phone_no_1 ? "is-invalid" : ""
                }`}
              required
              placeholder={!readOnly ? "電話番号1" : ""}
              readOnly={readOnly}
            />
            <div className="invalid-feedback">
              {errorList.phone_no_1 ? errorList.phone_no_1 : ""}
            </div>
          </div>

          <label className="col-sm-2 col-form-label text-sm-end">
            電話備考1:
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              name="phone_note_1"
              onChange={handleInput}
              value={Nvh({ value: newStudents.phone_note_1 })}
              placeholder={!readOnly ? "電話備考1" : ""}
              readOnly={readOnly}
            />
          </div>
        </div>
        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">
            電話番号2:
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              name="phone_no_2"
              onChange={handleInput}
              value={Nvh({ value: newStudents.phone_no_2 })}
              placeholder={!readOnly ? "電話番号2" : ""}
              readOnly={readOnly}
            />
          </div>

          <label className="col-sm-2 col-form-label text-sm-end">
            電話備考2:
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              name="phone_note_2"
              onChange={handleInput}
              value={Nvh({ value: newStudents.phone_note_2 })}
              placeholder={!readOnly ? "電話備考2" : ""}
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">
            バスコース番号: <span className="text-white">-</span>
          </label>

          <div className="col-sm-4">
            <select
              className="form-control"
              placeholder={!readOnly ? "バスコース番号" : ""}
              name="bus_course_number"
              onChange={e => setBusId(e.target.value)}
              value={busId}
              disabled={readOnly}
            >
              <option value="">バスの選択</option>
              {buses.map((bus, i) => (
                <option key={i} value={bus.id}>
                  {bus.bus_course_number} ({bus.stop_name})
                </option>
              ))}
            </select>
          </div>

          <div className="col-5 row bus-seat">
            <label className="col-2 col-form-label text-sm-end">
              往路:<span className="text-white">-</span>
            </label>
            <div className="col-2 pr-0">
              <input
                type="number"
                className="form-control"
                name="outbound_bus"
                onChange={handleInput}
                value={Nvh({ value: newStudents.outbound_bus })}
                readOnly={readOnly}
              />
            </div>
            <div className="col-2 sz-15">人</div>
            <label className="col-sm-2 col-form-label text-sm-end">復路:</label>
            <div className="col-sm-2 pr-0">
              <input
                type="number"
                className="form-control"
                name="return_bus"
                onChange={handleInput}
                value={Nvh({ value: newStudents.return_bus })}
                readOnly={readOnly}
              />
            </div>
            <div className="col-2 sz-15">人</div>
          </div>
        </div>

        <div className="form-group mb-2 row">
          <div className="col-sm-3 "></div>
          <div className="col-sm-3">
            <h4 className="p-2">緊急連絡</h4>
          </div>
          <div className="col-sm-6 col-form-label text-sm-end"></div>
        </div>

        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">姓:</label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              name="em_first_name"
              onChange={handleInput}
              value={Nvh({ value: newStudents.em_first_name })}
              placeholder={!readOnly ? "緊急時の名" : ""}
              readOnly={readOnly}
            />
          </div>
          <label className="col-sm-2 col-form-label text-sm-end">
            フリガナ
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              name="em_first_name_furigana"
              onChange={handleInput}
              value={Nvh({ value: newStudents.em_first_name_furigana })}
              placeholder={!readOnly ? "フリガナ" : ""}
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end"> 名:</label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              name="em_last_name"
              onChange={handleInput}
              value={Nvh({ value: newStudents.em_last_name })}
              placeholder={!readOnly ? "緊急時の姓" : ""}
              readOnly={readOnly}
            />
          </div>
          <label className="col-sm-2 col-form-label text-sm-end">
            フリガナ:
          </label>
          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              name="em_last_name_furigana"
              onChange={handleInput}
              value={Nvh({ value: newStudents.em_last_name_furigana })}
              placeholder={!readOnly ? "フリガナ" : ""}
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="form-group mb-2 row">
          <label className="col-sm-3 col-form-label text-sm-end">
            電話番号:
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              name="em_phone_no"
              onChange={handleInput}
              value={Nvh({ value: newStudents.em_phone_no })}
              placeholder={!readOnly ? "緊急電話番号" : ""}
              readOnly={readOnly}
            />
          </div>
        </div>
      </div>

      <div className="d-flex">
        <div className="ml-auto">
          {loading ? (
            <ButtonLoader />
          ) : (
            <Button
              variant="primary"
              className="mx-auto btn-block mt-4"
              type="submit"
              onClick={() => updateFormSubmit()}
            >
              保存する
            </Button>
          )}
        </div>
      </div>
    </span>
  );

  return (
    <>
      <div className="custom-scaling row">
        <div className="col-xl-7">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="">
                  <span>
                    <button
                      onClick={() => {
                        setReadOnly(true);
                        handleUrlChange("view");
                      }}
                      className={`btn px-3 mr-4 ${readOnly ? "btn-primary" : "btn-outline-primary"
                        }`}
                    >
                      参照モード [F2]
                    </button>
                  </span>
                  <span>
                    <button
                      onClick={() => {
                        setReadOnly(true);
                        handleUrlChange("update");
                      }}
                      className={`btn px-3 mr-4 ${!readOnly ? "btn-primary" : "btn-outline-primary"
                        }`}
                    >
                      編集モード [F2]
                    </button>
                  </span>
                </div>
                &nbsp;<h5>会員情報を更新します:</h5>
                <div className="ml-auto">
                  <Link
                    className="btn btn-sm btn-primary px-3 ml-auto"
                    variant="primary"
                    to="/admin/stc/short-term-student-list"
                  >
                    {" "}
                    <i className="fas fa-angle-left fa-fw"></i> 戻る
                  </Link>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={k => setKey(k)}
                  className="mb-3"
                >
                  <Tab eventKey="tab1" title="個人情報 [F10]">
                    {formSection}
                  </Tab>
                  <Tab eventKey="tab2" title="クラス [F10]">
                    <Page48UpdatePage
                      getStudentInfo={(studentId) => getStudentInfo(studentId)}
                      studentInfo={newStudents}
                      readOnlyValue={readOnly}
                      editable={true}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateShortTermStudent;
