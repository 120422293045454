import { Button, Col, Flex, Form, Input, Modal, Row, message } from 'antd';
import React, { useEffect } from 'react';
import useLoading from '../../../hooks/useLoading';

const ChangePassword = ({ close, isOpen }) => {
   const [form] = Form.useForm();
   const { startLoading, stopLoading, isLoading } = useLoading();
   const onFinish = async () => {
      startLoading();
      await new Promise((resolve) => {
         setTimeout(() => {
            resolve();
         }, 2000);
      });
      stopLoading();
      close();
      message.success("パスワードが正常に変更されました。");
   };
   // useEffect(() => {
   //    form.setFieldsValue({
   //       current_password: "ewwewew"
   //    });
   // }, []);
   return (
      <Modal
         open={isOpen}
         onCancel={() => {
            form.resetFields();
            close();
         }}
         footer={null}
         destroyOnClose
      >
         <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            autoComplete="off"
         >
            <Row>
               <Col span={24}>
                  <Form.Item name="current_password" label="現在のパスワード" rules={[{ required: true, message: "現在のパスワードが必要です" }]}>
                     <Input autoComplete='off' />
                  </Form.Item>
               </Col>
               <Col span={24}>
                  <Form.Item name="new_password" label="新しいパスワード" rules={[{ required: true, message: "新しいパスワードが必要です" }]}>
                     <Input autoComplete='off' />
                  </Form.Item>
               </Col>
               <Col span={24}>
                  <Form.Item name="new_password2" label="新しいパスワードを確認" rules={[{ required: true, message: "新しいパスワードを確認してください", }, ({ getFieldValue }) => ({
                     validator(_, value) {
                        if (!value || getFieldValue('new_password') === value) {
                           return Promise.resolve();
                        }
                        return Promise.reject(new Error('入力した 2 つのパスワードが一致しません。'));
                     },
                  }),]}>
                     <Input autoComplete='off' />
                  </Form.Item>
               </Col>
               <Flex style={{ width: "100%" }} justify='space-between'>
                  <Button onClick={() => {
                     form.resetFields();
                     close();
                  }}>キャンセル</Button>
                  <Button loading={isLoading} htmlType='submit' type='primary'>提出する</Button>
               </Flex>
            </Row>
         </Form>
      </Modal>
   );
};

export default ChangePassword;