import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import privateApi from "../../../services/privateApi";
import ReactSelect from "react-select";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function GradeLabels() {
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [levels, setLevels] = useState([]);
  const [stoppagelevel, setStoppagelevel] = useState([
    {
      class: "",
      grades: [],
      data: [
      ],
    },
  ]);

  const handleLevelChange = (index, type, value) => {
    const updatedData = [...stoppagelevel];
    updatedData[0].data[index][type] = value;
    setStoppagelevel(updatedData);
  };

  const addLevelColumn = (count = 1) => {
    const newLevels = Array.from(
      { length: count },
      (_, index) => ``
    );
    setLevels(prevLevels => [...prevLevels, ...newLevels]);
  
    const updatedData = [...stoppagelevel];
    updatedData[0].data.forEach(item => {
      newLevels.forEach(level => {
        item.level[level] = ""; // Set the new levels to ""
      });
    });
    setStoppagelevel(updatedData);
  };
  
  

  const removeLevelColumn = removedLevel => {
    const updatedLevels = levels.filter(level => level !== removedLevel);
    const updatedData = [...stoppagelevel];
    updatedData[0].data.forEach(item => {
      delete item.level[removedLevel];
    });
    setLevels(updatedLevels);
    setStoppagelevel(updatedData);
  };

  const saveUpdatedArray = async () => {
    setLoading(true);


    const data = [
      {
        class: selectedClass.value,
        grades: levels,
        data: stoppagelevel[0].data
      },
    ]

    try {
      const response = await privateApi.post(
        "/admin/promotions",
        data
      );
      if (response.status === 200) {
        setLoading(false);
        swal(
          "正常に保存されました。",
          "",
          "success"
        );
        getPromotionsData(selectedClass.value);
      } else {
        setLoading(false);
        swal("Error!", "There was an error saving the rank level", "error");
      }
    } catch (error) {
      setLoading(false);
      swal("Error!", "There was an error saving the rank level", "error");
    }
  };

  const getAllClass = async () => {
    try {
      const response = await privateApi.get("admin/classes");
      if (response.status === 200) {
        const classData = response.data.data.map(data => ({
          value: data.id,
          label: data.affiliation_name,
        }));
        setClasses(classData);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getAllClass();
  }, []);

  useEffect(() => {
    if (stoppagelevel[0].data.length < 25) {
      const newData = Array.from(
        { length: 25 - stoppagelevel[0].data.length },
        (_, index) => ({
          rank: stoppagelevel[0].data.length + index + 1,
          guideline: "",
          level: {},
        })
      );
      const updatedData = [...stoppagelevel];
      updatedData[0].data.push(...newData);
      setStoppagelevel(updatedData);
    }
  }, [stoppagelevel]);


  const getPromotionsData = async (id) => {
    try {
      const response = await privateApi.get(`admin/promotions?class=${id}`);
      if (response.status === 200) {
        const promData = response.data;

        setLevels(promData.grades)
        setStoppagelevel(promData.data)

      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    setLevels([]);
    setStoppagelevel([
      {
        class: "",
        grades: [],
        data: [
        ],
      },
    ]);
    getPromotionsData(selectedClass.value);
  }, [selectedClass]);

  return (
    <div>
      <div className="card">
        <div className="card-header d-flex">
          <strong className="pr-5 pt-2">クラス:</strong>
          <ReactSelect
            className=""
            placeholder="選択する"
            options={classes}
            onChange={setSelectedClass}
            value={selectedClass}
            styles={{ control: provided => ({ ...provided, width: 200 }) }}
          />
          <Button
            className="ml-auto"
            variant="success"
            onClick={() => addLevelColumn(1)}
          >
            新しい列を追加
          </Button>

          <Button className="ml-3" variant="primary" onClick={saveUpdatedArray}>
            保存
          </Button>
        </div>
        <div className="card-body" style={{ height: "80vh", overflow: "auto" }}>
          <div style={{ width: "max-content" }}>
            <Table
              responsive
              striped
              bordered
              hover
              className="mt-3 align-middle text-center"
            >
              <thead>
                <tr>
                  <th style={{ minWidth: "60px", maxWidth: "150px!important" }}>
                    Rank
                  </th>
                  <th
                    style={{ minWidth: "200px", maxWidth: "300px!important" }}
                  >
                    Guideline
                  </th>
                  {levels.map((level, index) => (
                    <th key={index} style={{ minWidth: "60px" }}>
                      <div className="d-flex">
                        {index < levels.length - 1 ? (
                          <input
                            style={{ width: "85%", textAlign: "center" }}
                            type="text"
                            value={level}
                            disabled
                          />
                        ) : (
                          <input
                            style={{ width: "85%", textAlign: "center" }}
                            type="text"
                            value={level}
                            onChange={e => {
                              const updatedLevels = [...levels];
                              updatedLevels[index] = e.target.value;
                              setLevels(updatedLevels);
                            }}
                          />
                        )}

                        <Button
                          variant="danger"
                          size="sm"
                          className="ml-1"
                          onClick={() => removeLevelColumn(level)}
                        >
                          <FontAwesomeIcon icon="times" />
                        </Button>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {stoppagelevel[0].data.map((data, dataIndex) => (
                  <tr key={data.rank}>
                    <td>{data.rank}</td>
                    <td>
                      <input
                        type="text"
                        value={data.guideline}
                        onChange={e =>
                          handleLevelChange(
                            dataIndex,
                            "guideline",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    {levels.map((level, index) => (
                      <td key={index}>
                        <input
                          type="text"
                          value={data.level[level]}
                          onChange={e =>
                            handleLevelChange(dataIndex, "level", {
                              ...data.level,
                              [level]: e.target.value,
                            })
                          }
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GradeLabels;
