import React, { useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import AuthService from "../../../../services/auth/AuthService";
import ButtonLoader from "../../../common/ButtonLoader/ButtonLoader";

function Login() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorList, setErrorList] = useState({});

  const [loginInput, setLogin] = useState({
    email: "",
    password: "",
  });
  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  const inputBlank = () => {
    setLogin({ ...loginInput, password: "" });
    setErrorList({});
    setLoading(false);
  };

  const loginSubmit = async (e) => {
    e.preventDefault();

    if (loginInput.email !== "" && loginInput.password !== "") {
      setLoading(true);
    }

    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };

    const response = await AuthService.login("superadmin", data);

    if (response.status === 200) {
      history.push("/super-admin/dashboard");
    } else if (response.status === 422) {
      setErrorList(response.data.errors);
      setLoading(false);
    } else if (response.status === 401) {
      inputBlank();
      swal(response.data.message, "", "warning");
    }
  };

  return (
    <div>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow rounded">
              <div className="card-header">
                <h4>スーパー管理者ログイン</h4>
              </div>
              <div className="card-body">
                <form onSubmit={loginSubmit} className="needs-validation">
                  <div className="form-group mb-3 mt-3">
                    <label>ユーザーID</label>
                    <input
                      type="text"
                      name="email"
                      onChange={handleInput}
                      value={loginInput.email}
                      className={`form-control ${errorList.email ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errorList.email ? errorList.email : ""}</div>
                  </div>
                  <div className="form-group mb-3">
                    <label>パスワード</label>
                    <input
                      type="password"
                      name="password"
                      onChange={handleInput}
                      value={loginInput.password}
                      className={`form-control ${errorList.password ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">
                      {errorList.password ? errorList.password : ""}
                    </div>
                  </div>
                  <div className={`form-group mb-3 text-center`}>
                    <button type="submit" className="btn w-100 btn-theme">
                      {loading ? <ButtonLoader /> : <span>ログインする</span>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
