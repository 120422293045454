import React, { useState } from 'react';
import { Card, Space, Tabs, DatePicker, } from "antd";
import styled from "styled-components";
import useModal from "../../../hooks/useModal";
import ClassList from './ClassList';
import ScheduleModal from './ScheduleModal';
import { classes, responsibleMembers } from './constants';

const Container = styled.div`
   padding:10px;
	width: 100%;
	justify-content: center;
   background: #f4f5f6;
	min-height: 100vh;
   height: 100%;
	@media screen and (max-width: 896px) {
		padding: 0 10px;
	}
`;

const Furikae = () => {
   const [data, setData] = useState(classes);
   const [currentSchedule, setCurrentSchedule] = useState();
   const [tab, setTab] = useState("all");
   const { open, close, isOpen } = useModal();
   const { open: startLoading, close: stopLoading, isOpen: isLoading } = useModal(); // as loading

   const openModal = (schedule) => {
      setCurrentSchedule(schedule);
      open();
   };

   const itemsTab = [
      {
         key: "all",
         label: "すべて",
         children: (
            <ClassList
               open={openModal}
               isLoading={isLoading}
               data={data}
            />
         ),
      },
      {
         key: "shusseki",
         label: "出席",
         children: (
            <ClassList
               open={openModal}
               isLoading={isLoading}
               data={data}
            />
         ),
      },
      {
         key: "kesseki",
         label: "欠席",
         children: (
            <ClassList
               open={openModal}
               isLoading={isLoading}
               data={data}
            />
         ),
      },
      {
         key: "furikae",
         label: "振替",
         children: (
            <ClassList
               open={openModal}
               isLoading={isLoading}
               data={data}
            />
         ),
      },

   ];

   return (
      <Container>
         <Card
            title="クラススケジュール"
            extra={<Space>
               <DatePicker.RangePicker placeholder={["日付から", "現在まで"]} />
            </Space>}
         >
            <Tabs
               activeKey={tab}
               defaultActiveKey="1"
               items={itemsTab}
               onChange={(key) => setTab(key)}
            />
            {isOpen && <ScheduleModal isOpen={isOpen} close={close} currentSchedule={currentSchedule} data={[...classes.filter(c => (c.status === null || c.status == "furikae-required")), ...responsibleMembers.filter(c => (c.status === null || c.status == "furikae-required"))]} currentClassId={currentSchedule.id} />}
         </Card>
      </Container>
   );
};

export default Furikae;
