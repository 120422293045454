import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import { getData } from "../../services/auth/token";
import { checkModulePermission } from "../../utils/CheckModulePermission";
import {
  addEventListener,
  removeEventListener,
} from "../../services/event/eventBus";

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  const [modules, setModules] = useState(getData()?.modules);

  const gradientStyle = {
    background: "linear-gradient(to right, #2193b0, #007bff80)",
  };

  const currentUser = getData()?.username;

  const handleDropdown = (slug, childSlug = null) => {
    setModules(prevModules =>
      prevModules.map(module => {
        if (module.slug === slug && !childSlug) {
          return { ...module, isOpen: !module.isOpen };
        }
        if (
          childSlug &&
          module.children.find(child => child.slug === childSlug)
        ) {
          return { ...module, isOpen: true };
        }
        return { ...module, isOpen: false };
      })
    );
  };

  const handleDropDownOnLoad = () => {
    if (modules && pathname) {
      const module = modules.find(module =>
        module.children.find(child => child.route === pathname)
      );
      if (module && module.children.length > 0) {
        const child = module.children.find(child => child.route === pathname);
        if (child) {
          handleDropdown(module.slug, child.slug);
        }
      }
    }
  };

  useEffect(() => {
    const updateModules = newModules => {
      setModules(newModules);
      handleDropDownOnLoad();
    };

    const filteredModules = data => {
      return data?.modules
        .map(module => {
          if (
            module.checked &&
            module.status === "1" &&
            checkModulePermission(module)
          ) {
            if (module.children.length > 0) {
              const children = module.children
                .map(child => {
                  if (
                    child.checked &&
                    child.status === "1" &&
                    checkModulePermission(child)
                  ) {
                    return child;
                  }
                  return null;
                })
                .filter(Boolean);
              return { ...module, children: children };
            }
            return module;
          }
          return null;
        })
        .filter(Boolean);
    };

    const initialModules = filteredModules(getData());

    const handleUserDataChanged = newData => {
      const updatedModules = filteredModules(JSON.parse(newData));
      updateModules(updatedModules);
    };

    addEventListener("userDataChanged", handleUserDataChanged);

    setModules(initialModules);
    handleDropDownOnLoad();

    return () => {
      removeEventListener("userDataChanged", handleUserDataChanged);
    };
  }, [pathname]);

  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <div className="sb-sidenav-menu-heading"></div>

          {modules &&
            modules.map(module =>
              module.children.length > 0 ? (
                <React.Fragment key={module.id}>
                  <span
                    key={module.id}
                    onClick={() => handleDropdown(module.slug)}
                    className={`sb-nav-link-icon nav-link hover-pointer dropdown-toggle ${module.isOpen ? "active2" : ""}`}
                  >
                    <i className={module.icon}></i>
                    <span className="ps-2">{module.name}</span>
                  </span>

                  <span
                    className={`content dropdown ${module.isOpen ? "active" : ""}`}
                    style={gradientStyle}
                  >
                    {module.children
                      // Sort children based on order
                      .sort((a, b) => a.order - b.order)
                      .map(child => (
                        <Link
                          key={child.id}
                          className={`nav-link ${pathname === child.route ? "active2" : ""}`}
                          to={child.route}
                          onClick={() => {
                            if (child.slug === "member_list") {
                              localStorage.setItem("member_search", "");
                              window.location.href = "/admin/student";
                            } else {
                              handleDropdown(module.slug, child.slug);
                            }
                          }}
                        >
                          <div className="ms-3 ps-2 sb-nav-link-icon">
                            <i className={child.icon}></i>
                          </div>
                          {child.name}
                        </Link>
                      ))}
                  </span>
                </React.Fragment>
              ) : (
                <Link
                  key={module.id}
                  className={`nav-link ${pathname === module.route ? "active2" : ""}`}
                  to={module.route}
                >
                  <div className="sb-nav-link-icon">
                    <i className={module.icon}></i>
                  </div>
                  {module.name}
                </Link>
              )
            )}
        </div>
      </div>
      <div className="sb-sidenav-footer">
        <div className="small">としてログイン:</div>
        {currentUser && <div className="small">{currentUser}</div>}
      </div>
    </nav>
  );
};

export default Sidebar;
