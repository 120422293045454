import React from "react";
import { Link } from "react-router-dom";

import "../../assets/common/css/dashboarButton.css";

function Dashboard() {
  const coachDashboardButtons = [
    {
      name: "出席入力",
      path: "/coach/attendance",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "進級",
      path: "/coach/rank/rank-management",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "進級管理",
      path: "/coach/rank/report",
      icon: "fas fa-tachometer-alt",
    },
    /*  {
      name: "振替処理",
      path: "/coach/re-schedule/class",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "履歴確認",
      path: "/coach/profile",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "進級",
      path: "/coach/rank/report",
      icon: "fas fa-start",
    },
    */
  ];

  return (
    <div className="p-3">
      <p className="headline">これはコーチの機能一覧です</p>
      <div className="btn-container col-md-4 col-sm-12">
        {coachDashboardButtons.map(button => (
          <Link to={button.path}>
            <button className="dash-btn">{button.name}</button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
