import React from "react";
import { Link } from "react-router-dom";

function Dashboard() {
  const superAdminDashboardButtons = [
    {
      name: "学校",
      path: "/super-admin/school",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "スーパー管理者を追加",
      path: "/super-admin/super-admin-list",
      icon: "fas fa-tachometer-alt",
    },
  ];
  return (
    <div className="p-3">
      <p className="headline">これは会員機能一覧です</p>
      <div className="btn-container">
        {superAdminDashboardButtons.map(button => (
          <Link to={button.path}>
            <button className="dash-btn">{button.name}</button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
